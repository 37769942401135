import { useEffect } from 'react'
import Loading from './Loading'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function LoadingSection(){
    const navigate = useNavigate()
    const {secciones} = useSelector(state => state.auth)

    useEffect(() => {
        if (secciones.caja) {
            navigate('/caja')
        } else if (secciones.viaticos) {
            navigate('/viaticos')
        } else if (secciones.administracion) {
            navigate('/administracion')
        } else if (secciones.daf) {
            navigate('/daf')
        } else if (secciones.solicitante) {
            navigate('/solicitante')
        } else if (secciones.beneficiario) {
            navigate('/beneficiario')
        } else if (secciones.autoridad) {
            navigate('/autoridad')
        } else if (secciones.presupuestos) {
            navigate('/presupuestos')
        }
    }, [secciones])

    return <Loading />
}

export default LoadingSection