import { propFromName } from './propFromName'
/** Ordena un array de objetos. */
export function sortObjects(objects, sort) {
    // Normaliza el valor de la prop de ordenación.
    const normalize = (value, key) => {
        const _value = propFromName(value, key)
        return _value === undefined || _value === null
            ? ''
            : typeof _value === 'string'
              ? _value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
              : _value
    }
    return objects.slice().sort((a, b) => {
        for (let { key, order } of sort) {
            const valueA = normalize(a, key)
            const valueB = normalize(b, key)
            // Comparar por la clave especificada
            const compare = valueA > valueB ? 1 : valueA < valueB ? -1 : 0
            // Ajustar la dirección de comparación
            if (compare !== 0) {
                return order === 'desc' ? -compare : compare
            }
        }
        return 0
    })
}
