import { useState } from 'react'

import { Card } from 'react-bootstrap'

import Button from 'components/Button'
import DataTable from 'components/DataTable'
import InputCalendar from 'components/InputCalendar'
import InputLayout from 'components/layouts/InputLayout'

import { ReembolsoService } from 'services/reembolso.service'
import { formatDate } from 'utils/utils'
import { buildFilters } from 'utils/utils'
import ReembolsoPDF from 'pdf/reembolso'
import TitlePage from 'components/TitlePage'
import { initialPagination } from 'data/const'
import Dialog from 'components/Dialog'

/** @module Pages/Reembolso/ReembolsoEmitidos */

const date = new Date()
const currentDate = formatDate(date, 'd/m/Y')

/**
 * Página, Reporte de reembolsos emitidos.
 * @returns {JSX.Element} Retorna el componente ReembolsoEmitidos.
 */
function ReembolsoEmitidos() {
    const [reembolsos, setReembolsos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [filters, setFilters] = useState({fechaInicio: new Date(), fechaFin: ''})
    const [show, setShow] = useState({info: false})
    const pdfR = new ReembolsoPDF()

    const openInfoDialog = () => setShow({...show, info: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const handleFilters = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
        if (reembolsos.length > 0) {
            setReembolsos([])
            setPagination(initialPagination)
        }
    }
    const handleSearch = async () => {
        const filter = getFilters()
        const {page} = filters
        const response = await ReembolsoService.searchReembolsos({filter}, page?{page}:{})
        if (response.status === 200) {
            setReembolsos(response.data.data)
            setPagination(response.data.pagination)
        }
    }
    const getFilters = () => {
        const fechaInicio = formatDate(filters.fechaInicio||date, 'Y-m-d')
        const fechaFin = formatDate(filters.fechaFin||date, 'Y-m-d')
        const _filters = {
            fecha_anulacion: true,
            cheque: 'NULL',
            fecha_creacion: [
                `${fechaInicio} 00:00:00`,
                `${fechaFin} 23:59:59`,
            ]
        }
        const filter = buildFilters(_filters, {
            between: ['fecha_creacion'],
            is: ['cheque'],
            null: ['fecha_anulacion']
        })
        return filter
    }
    const handleGenerate = async () => {
        let _reembolsos = []
        if (reembolsos.length > 0 && pagination.totalRecords <= pagination.pageSize) {
            _reembolsos = reembolsos
        } else {
            const filter = getFilters()
            const response = await ReembolsoService.searchReembolsos({filter}, {all: true})
            if (response.status === 200) {
                _reembolsos = response.data.data
            }
        }
        if (_reembolsos.length > 0) {
            pdfR.listaEmitidos(_reembolsos, filters)
        } else {
            openInfoDialog()
        }
    }
    const handlePage = (page) => setFilters({ ...filters, page })

    const columns = [
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            body: rowData => formatDate(rowData.fecha_creacion, 'd-M-Y')
        },
        {
            field: 'numero_reembolso',
            header: 'Detalle',
            body: rowData => rowData.numero_reembolso + ' Reembolso de viáticos'
        },
        {
            field: 'unidad',
            header: 'Unidad',
        },
        {
            field: 'monto',
            header: 'Monto',
            bodyClassName: 'text-end',
            style: {width: '10rem'}
        },
    ]
    const infoFooterDialog = <Button startIcon='bi-x-lg' onClick={closeInfoDialog}>Cerrar</Button>
    
    return <>
        <TitlePage title='Reembolsos Emitidos' />
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='flex-1 w-xl-75 p-5'>
                <div className='d-flex justify-content-center gap-3 pb-3 mb-3'>
                    <InputLayout label={<div style={{width: '5.5rem'}}>Fecha inicio:</div>} inline>
                        <InputCalendar 
                            name='fechaInicio' 
                            value={filters.fechaInicio} 
                            onChange={handleFilters} 
                            placeholder={currentDate} 
                        />
                    </InputLayout>
                    <InputLayout label={<div style={{width: '5.5rem'}}>Fecha fin:</div>} inline>
                        <InputCalendar 
                            name='fechaFin' 
                            value={filters.fechaFin} 
                            onChange={handleFilters} 
                            placeholder='dd/mm/yyyy'
                        />
                    </InputLayout>
                    <div>
                        <Button 
                            startIcon='pi pi-search' 
                            onClick={handleSearch} 
                            variant='outline-blue' 
                        >Buscar</Button>
                    </div>
                    <div>
                        <Button 
                            startIcon='pi pi-file-pdf' 
                            onClick={handleGenerate}
                        >Generar PDF</Button>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <DataTable 
                        values={reembolsos}
                        columns={columns}
                        pagination
                        rowsPerPage={pagination.pageSize}
                        totalRecords={pagination.totalRecords}
                        page={pagination.currentPage}
                        onPage={handlePage}
                    />
                </div>
                <Dialog
                    show={show.info}
                    footer={infoFooterDialog}
                >
                    <div className='d-flex align-items-center gap-3'>
                        <div><i className='bi-info-circle fs-3'></i></div>
                        <div>No se encontraron registros</div>
                    </div>
                </Dialog>
            </Card>
        </div>
    </>
}
export default ReembolsoEmitidos