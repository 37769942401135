import { GenericRequest, SignalRequest } from './request'

const PapeletaService = {

    indexPapeletas: (params) => SignalRequest.get('papeleta', params),
    indexPapeletasPost: (data, params) => SignalRequest.post('papeleta', data, params),
    viewPapeleta: (id) => SignalRequest.get('papeleta/view', {id}),
    searchPapeletas: (data, params) => GenericRequest.post('papeleta/search', data, params),
    getImpresionOriginal: (id) => GenericRequest.get('papeleta/get-impresion-original', {id}),
    getNextNumPapeleta: (params={}) => GenericRequest.get('papeleta/get-next-num-papeleta', params),
    getMontosPapeletas: (id) => GenericRequest.get('papeleta/get-montos-papeleta', {id}),

    createPapeleta: (data) => GenericRequest.post('papeleta/create', data),
    cancelPapeleta: (id) => GenericRequest.get('papeleta/anular', {id}),
    /**
     * @param {object} data 
     * @param {array} data.ids ID's de las papeletas a pagar
     */
    payPapeletas: (data) => GenericRequest.post('papeleta/pagar', data),
    /**
     * @param {object} data 
     * @param {array} data.ids ID's de las papeletas a revertir
     */
    reversePapeletas: (data) => GenericRequest.post('papeleta/revertir', data),
    devolucionPapeleta: (data, id) => GenericRequest.post('papeleta/devolucion', data, {id}),

    uploadPapeleta: (data, id) => GenericRequest.post('papeleta/upload-papeleta', data, {id}),

    pdf: (id) => GenericRequest.get('papeleta/pdf', {id}, { responseType: 'blob' }),
    pendientesPdf: (id) => GenericRequest.get('papeleta/pendientes-pdf', {id}, { responseType: 'blob' }),

    getDefaultInfo: () => GenericRequest.get('papeleta/get-default-info'),
    
}

export default PapeletaService