import Button from 'components/Button'
import DataTable from 'components/DataTable'
import EditableSelect from 'components/EditableSelect'
import InputCalendar from 'components/InputCalendar'
import InputDropdown from 'components/InputDropdown'
import TitlePage from 'components/TitlePage'
import InputCheckbox from 'components/InputCheckbox'
import InputLayout from 'components/layouts/InputLayout'
import { initialPagination, recursosFilter, recursosObj } from 'data/const'
import ReembolsoPDF from 'pdf/reembolso'
import { useEffect, useRef, useState } from 'react'
import { ReembolsoService } from 'services/reembolso.service'
import { formatDate } from 'utils/utils'
import { buildFilters } from 'utils/utils'
import Dialog from 'components/Dialog'
import useFilters from 'hooks/useFilters'
import Input from 'components/Input'

/** @module Pages/Reembolso/ReembolsoReport */

const date = new Date()
const gestion = date.getFullYear()
const currentDate = formatDate(date, 'd/m/Y')
const initialFilters = {
    fechaInicio: '',
    fechaFin: '',
    unidad: '',
    anulado: false,
    unidad_id: '',
    recursos: '',
    numero_reembolso: '',
    cheque: false,
    comprobante: '',
    numero_papeleta: [],
    documento: '',
}

/**
 * Página, Reporte de reembolsos.
 * @returns {JSX.Element} Retorna el componente ReembolsoReport.
 */
function ReembolsoReport() {
    const { filters, setFilters, handlePage, handleSort } = useFilters({ initialFilters })
    const [reembolsos, setReembolsos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [reportTitle, setReportTitle] = useState('LISTA DE REEMBOLSOS PENDIENTES')
    const [show, setShow] = useState({ info: false })
    const tableRef = useRef()
    const pdfR = new ReembolsoPDF()

    useEffect(() => {
        handleSearch()
    }, [filters.sort, filters.page])

    const openInfoDialog = () => setShow({ ...show, info: true })
    const closeInfoDialog = () => setShow({ ...show, info: false })
    const handleGenerate = async () => {
        const { pageSize, totalRecords } = pagination
        let _reembolsos = []
        if (reembolsos.length > 0 && totalRecords <= pageSize) {
            _reembolsos = reembolsos
        } else {
            const filter = getFilters()
            const response = await ReembolsoService.searchReembolsos({ filter }, { all: true })
            if (response.status === 200) {
                _reembolsos = response.data.data
            }
        }
        if (_reembolsos.length > 0) {
            pdfR.listaReembolsos(_reembolsos, reportTitle)
        } else {
            openInfoDialog()
        }
    }
    const getFilters = () => {
        const { cheque, anulado } = filters
        const fechaInicio = filters.fechaInicio ? formatDate(filters.fechaInicio, 'Y-m-d') : `01/01/${gestion}`
        const fechaFin = formatDate(filters.fechaFin || date, 'Y-m-d')
        const _filters = {
            ...filters,
            fecha_creacion: [`${fechaInicio} 00:00:00`, `${fechaFin} 23:59:59`],
            fecha_anulacion: typeof anulado === 'boolean' ? !anulado : null,
            cheque: typeof cheque === 'boolean' ? !cheque : null,
        }
        const filter = buildFilters(_filters, {
            equal: ['unidad_id', 'recursos'],
            between: ['fecha_creacion'],
            null: ['fecha_anulacion', 'cheque'],
        })
        return filter
    }
    const handleSearch = async () => {
        const { sort, page } = filters
        const filter = getFilters()
        const response = await ReembolsoService.searchReembolsos(
            { filter },
            { ...(sort ? { sort } : {}), ...(page ? { page } : {}) },
        )
        if (response.status === 200) {
            setReembolsos(response.data.data)
            setPagination(response.data.pagination)
        }
    }
    const handleClean = () => {
        tableRef?.current?.clearFilters()
        setFilters(initialFilters)
        setReembolsos([])
    }

    const columns = [
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            body: (rowData) => formatDate(rowData.fecha_creacion, 'd-M-Y'),
            bodyClassName: 'text-center',
            sortable: true,
        },
        {
            field: 'numero_reembolso',
            header: 'Nro. R',
            body: (rowData) => rowData.numero_reembolso + '/' + formatDate(rowData.fecha_creacion, 'y'),
        },
        {
            field: 'unidad',
            header: 'Unidad',
            sortable: true,
        },
        {
            field: 'monto',
            header: 'Monto',
            bodyClassName: 'text-end',
        },
        {
            field: 'cheque',
            header: 'Cheque',
            body: (rowData) => rowData.cheque || 'Sin cheque',
        },
        {
            field: 'fecha_anulacion',
            header: 'Anulado',
            body: (rowData) => formatDate(rowData.fecha_anulacion, 'd/M/Y', 'No'),
            bodyClassName: 'text-center',
            sortable: true,
        },
        {
            field: 'recursos',
            header: 'Recursos',
            body: (rowData) => recursosObj[rowData.recursos],
            bodyClassName: 'text-center',
            sortable: true,
        },
    ]
    const infoFooterDialog = (
        <Button startIcon='bi-x-lg' onClick={closeInfoDialog}>
            Cerrar
        </Button>
    )

    return (
        <>
            <TitlePage title='Buscador de Reembolsos' />
            <div className='content'>
                <div>
                    <div className='fs-5 mb-2'>Filtros</div>
                    <FiltersReembolso
                        filters={filters}
                        setFilters={setFilters}
                        onClean={handleClean}
                        onSearch={handleSearch}
                        setReembolsos={setReembolsos}
                    />
                    <div className='row align-items-center gap-3 mb-3'>
                        <InputLayout label='Titulo del reporte:' inline className='col'>
                            <Input value={reportTitle} onChange={(e) => setReportTitle(e.target.value)} />
                        </InputLayout>
                        <div className='col-auto ms-auto'>
                            <Button startIcon='pi pi-file-pdf' onClick={handleGenerate}>
                                Generar PDF
                            </Button>
                        </div>
                    </div>
                    <div className='fs-5 mb-3'>Reembolsos</div>
                    <DataTable
                        innerRef={tableRef}
                        className='mb-3'
                        values={reembolsos}
                        columns={columns}
                        pagination
                        rowsPerPage={pagination.pageSize}
                        totalRecords={pagination.totalRecords}
                        page={pagination.currentPage}
                        onPage={handlePage}
                        onSort={handleSort}
                    />
                    <Dialog show={show.info} footer={infoFooterDialog}>
                        <div className='d-flex align-items-center gap-3'>
                            <div>
                                <i className='bi-info-circle fs-3'></i>
                            </div>
                            <div>No se encontraron registros</div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}
export default ReembolsoReport

/**
 * Componente. Filtros de reembolsos.
 * @ignore
 * @param {object} props
 * @param {object} props.filters
 * @param {function} props.setFilters
 * @param {function} props.onSearch
 * @param {function} props.onClean
 * @returns {JSX.Element} Retorna el componente FiltersReembolso.
 */
function FiltersReembolso({ filters, setFilters, onSearch, onClean, setReembolsos }) {
    const [unidades, setUnidades] = useState([])

    useEffect(() => {
        if (filters.unidad?.id) setFilters({ ...filters, unidad_id: filters.unidad.id })
        else setFilters({ ...filters, unidad_id: '' })
    }, [filters.unidad])

    const handleFilters = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value })
        setReembolsos([])
    }
    const searchUnidades = (unidad) => {
        const loadData = async () => {
            const response = await ReembolsoService.unidad.search(unidad)
            if (response.status === 200) setUnidades(response.data)
        }
        if (unidad.length > 0) loadData()
        else setUnidades([])
    }

    return (
        <div className='mb-4 border rounded py-3 px-4'>
            <div className='row mb-3'>
                <div className='col'>
                    <div className='row g-3'>
                        <InputLayout
                            label={<div style={{ width: '5.5rem' }}>Fecha inicio:</div>}
                            inline
                            className='col-lg-6'
                        >
                            <InputCalendar
                                name='fechaInicio'
                                value={filters.fechaInicio}
                                onChange={handleFilters}
                                placeholder={`01/01/${gestion}`}
                            />
                        </InputLayout>
                        <InputLayout
                            label={<div style={{ width: '4.5rem' }}>Fecha fin:</div>}
                            inline
                            className='col-lg-6'
                        >
                            <InputCalendar
                                name='fechaFin'
                                value={filters.fechaFin}
                                onChange={handleFilters}
                                placeholder={currentDate}
                            />
                        </InputLayout>

                        <InputLayout
                            label={<div style={{ width: '5.5rem' }}>Recursos:</div>}
                            inline
                            className='col-lg-6'
                        >
                            <InputDropdown
                                name='recursos'
                                options={recursosFilter}
                                value={filters.recursos}
                                onChange={handleFilters}
                            />
                        </InputLayout>
                        <InputLayout label={<div style={{ width: '4.5rem' }}>Unidad:</div>} inline className='col-lg-6'>
                            <EditableSelect
                                name='unidad'
                                options={unidades}
                                optionValue='descripcion'
                                lazy
                                valueAsOption
                                onSearch={searchUnidades}
                                value={filters.unidad}
                                onChange={handleFilters}
                            />
                        </InputLayout>
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='d-flex flex-column gap-3' style={{ minWidth: '17.5rem' }}>
                        <InputLayout
                            label={<div style={{ width: '4rem' }}>Anulado:</div>}
                            inline
                            style={{ minHeight: '2.25rem' }}
                        >
                            <InputCheckbox
                                name='anulado'
                                value={filters.anulado}
                                onChange={handleFilters}
                                triState
                                className='me-2'
                                style={{ fontSize: '1.75rem' }}
                            />
                            <span className='text-primary'>
                                {typeof filters.anulado === 'boolean'
                                    ? filters.anulado
                                        ? 'ANULADOS'
                                        : 'NO ANULADOS'
                                    : 'TODOS'}
                            </span>
                        </InputLayout>
                        <InputLayout
                            label={<div style={{ width: '4rem' }}>Cheque:</div>}
                            inline
                            style={{ minHeight: '2.25rem' }}
                        >
                            <InputCheckbox
                                name='cheque'
                                value={filters.cheque}
                                onChange={handleFilters}
                                triState
                                className='me-2'
                                style={{ fontSize: '1.75rem' }}
                            />
                            <span className='text-primary'>
                                {typeof filters.cheque === 'boolean'
                                    ? filters.cheque
                                        ? 'CON CHEQUE'
                                        : 'SIN CHEQUE'
                                    : 'TODOS'}
                            </span>
                        </InputLayout>
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <Button startIcon='pi pi-filter-slash' variant='outline-blue' onClick={onClean} className='me-3'>
                    Limpiar
                </Button>
                <Button startIcon='pi pi-search' onClick={onSearch}>
                    Buscar
                </Button>
            </div>
        </div>
    )
}
