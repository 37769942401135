import { classNames } from '../utils'
import { cloneElement } from 'react'
import { Transition } from 'react-transition-group'
const fadeStyles = {
    entering: 'show',
    entered: 'show',
    exiting: '',
}
export function Fade({ children, ...props }) {
    return (
        <Transition {...props} timeout={150}>
            {(status, innerProps) =>
                cloneElement(children, {
                    ...innerProps,
                    className: classNames(children.props.className, fadeStyles[status]),
                })
            }
        </Transition>
    )
}
