import { useState } from 'react'

import { Card } from 'react-bootstrap'

import Button from 'components/Button'
import DataTable from 'components/DataTable'
import InputCalendar from 'components/InputCalendar'
import InputLayout from 'components/layouts/InputLayout'

import { ReembolsoService } from 'services/reembolso.service'
import { formatCurrency, formatDate } from 'utils/utils'
import ReembolsoPDF from 'pdf/reembolso'
import TitlePage from 'components/TitlePage'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import FormFondoRotatorio from './components/FormFondoRotatorio'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import CustomInput from 'components/CustomInput'

/** @module Pages/Reembolso/ReembolsoKardex */

const date = new Date()
const currentDate = formatDate(date, 'd/m')
const currentYear = formatDate(date, 'Y')

/**
 * Página, Kardex de viáticos.
 * @returns {JSX.Element} Retorna el componente ReembolsoKardex.
 */
function ReembolsoKardex() {
    const dispatch = useDispatch()
    const [movimientos, setMovimientos] = useState([])
    const [show, setShow] = useState({cargo: false, cierre: false, info: false})
    const [filters, setFilters] = useState({fechaInicio: date, fechaFin: '', gestion: date})
    const pdfR = new ReembolsoPDF()

    const columns = [
        {
            field: 'fecha',
            header: 'Fecha',
            alignHeader: 'center',
            body: rowData => formatDate(rowData.fecha, 'd/m/Y', ''),
            style: {width: '10rem'}
        },
        {
            field: 'detalle',
            header: 'Detalle',
            alignHeader: 'center',
            bodyClassName: 'text-nowrap',
        },
        {
            field: 'ingresos',
            header: 'Ingresos',
            alignHeader: 'center',
            bodyClassName: 'text-end',
            style: {width: '12rem'}
        },
        {
            field: 'egresos',
            header: 'Egresos',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.egresos),
            bodyClassName: 'text-end',
            style: {width: '12rem'}
        },
        {
            field: 'saldo',
            header: 'Saldo',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.saldo),
            bodyClassName: 'text-end',
            style: {width: '12rem'}
        },
    ]
    
    const openCargoDialog = () => setShow({...show, cargo: true})
    const closeCargoDialog = () => setShow({...show, cargo: false})
    const openCierreDialog = () => setShow({...show, cierre: true})
    const closeCierreDialog = () => setShow({...show, cierre: false})
    const openInfoDialog = () => setShow({...show, info: true})
    const closeInfoDialog = () => setShow({...show, info: false})

    const handleFilters = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
        if (movimientos.length > 0) {
            setMovimientos([])
        }
    }
    const handleSearch = async () => {
        const filter = getFilters()
        const response = await ReembolsoService.getKardexViaticos(filter)
        if (response.status === 200) {
            setMovimientos(response.data)
        }
    }
    const getFilters = () => {
        const fechaInicio = formatDate(filters.fechaInicio||date, 'm-d')
        const fechaFin = formatDate(filters.fechaFin||date, 'm-d')
        const year = formatDate(filters.gestion||date, 'Y')
        const filter = {
            fechaInicio: `${year}-${fechaInicio} 00:00:00`, 
            fechaFin: `${year}-${fechaFin} 23:59:59`
        }
        return filter
    }
    const handleGenerate = async () => {
        if (movimientos.length > 0) {
            pdfR.kardex(movimientos)
        } else {
            openInfoDialog()
        }
    }
    const updateFondoRotatorio = async (values) => {
        const response = await ReembolsoService.fondoRotatorio.update(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 200) closeCargoDialog()
    }
    const closeFondoRotatorio = async () => {
        const response = await ReembolsoService.fondoRotatorio.close()
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 200) closeCierreDialog()
    }

    const infoFooterDialog = <Button startIcon='bi-x-lg' onClick={closeInfoDialog}>Cerrar</Button>
    const options = <>
        <Button startIcon='bi-cash' className='me-2' onClick={openCargoDialog}>Fondo Rotatorio</Button>
        <Button startIcon='bi-journal-x' onClick={openCierreDialog}>Cierre Fondo Rotatorio</Button>
    </>
    
    return <>
        <TitlePage title='Kardex de Viáticos' options={options}/>
        <div className='content align-items-center' style={{backgroundColor: '#efefef'}}>
            <Card className='flex-1 w-xl-75 p-5'>
                <div className='d-flex justify-content-center align-items-center gap-3 pb-3 mb-3'>
                    <InputLayout label='Fecha inicio:' inline>
                        <InputCalendar 
                            name='fechaInicio' 
                            value={filters.fechaInicio} 
                            onChange={handleFilters} 
                            placeholder={currentDate} 
                            formatLabel='d/m'
                            hideYear
                        />
                    </InputLayout>
                    <InputLayout label='Fecha fin:' inline>
                        <InputCalendar 
                            name='fechaFin' 
                            value={filters.fechaFin} 
                            onChange={handleFilters} 
                            formatLabel='d/m'
                            placeholder='dd/mm'
                            hideYear
                        />
                    </InputLayout>
                    <InputLayout label='Gestión:' inline>
                        <InputCalendar 
                            name='gestion'
                            containerStyle={{width: '8.5rem'}}
                            value={filters.gestion} 
                            onChange={handleFilters} 
                            onlyYearPicker formatLabel='Y'
                            customInput={<CustomInput 
                                className='form-control fw-bold' 
                                startIcon={<div className='text-black'>Gestión</div>} 
                                placeholder={currentYear} 
                            />} 
                        />
                    </InputLayout>
                    <Button startIcon='pi pi-search' onClick={handleSearch} variant='outline-blue'>Buscar</Button>
                    <Button startIcon='pi pi-file-pdf' onClick={handleGenerate} className='text-nowrap'>Generar PDF</Button>
                </div>
                <div className='d-flex justify-content-center'>
                    <DataTable 
                        values={movimientos}
                        columns={columns}
                    />
                </div>
                <Dialog
                    show={show.cargo}
                    header='Fondo Rotatorio'
                    keyboard={false}
                    onHide={closeCargoDialog}
                >
                    <FormFondoRotatorio onSubmit={updateFondoRotatorio} onCancel={closeCargoDialog} />
                </Dialog>
                <ConfirmDialog
                    show={show.cierre}
                    onHide={closeCierreDialog}
                    onReject={closeCierreDialog}
                    onAccept={closeFondoRotatorio}
                >
                    Una vez realice el cierre, su saldo será cero.
                    <br/>
                    Esta seguro de realizar el <span className='text-primary'>Cierre de Fondo</span> para la gestión {currentYear}?
                </ConfirmDialog>
                <Dialog
                    show={show.info}
                    footer={infoFooterDialog}
                >
                    <div className='d-flex align-items-center gap-3'>
                        <div><i className='bi-info-circle fs-3'></i></div>
                        <div>No se encontraron registros</div>
                    </div>
                </Dialog>
            </Card>
        </div>
    </>
}
export default ReembolsoKardex