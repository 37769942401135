import React, { useEffect, useMemo, useRef, useState } from 'react'
import { classNames, compareData, debounce, joinStyles, propFromName, sortObjects } from '../utils'
import { orders } from './interfaces'
import { DataTableRef } from './DataTableRef'
import { DataTableHeader } from './DataTableHeader'
import { DataTableBody } from './DataTableBody'
import DataTableFooter from './DataTableFooter'
import { Pagination } from '../Pagination'
import { BaseTable } from '../base/BaseDataTable'
// Valores iniciales del orden del DataTable.
const initialSort = { field: orders.noOrder, order: orders.noOrder }
export function DataTable({
    columns = [],
    values,
    rowKeyField,
    headerElement,
    footerElement,
    className,
    style,
    responsive,
    headerSticky,
    tableWrapperClassName,
    tableWrapperStyle,
    tableProps,
    tableClassName,
    tableStyle,
    messageOnEmpty,
    groupRowsMode,
    groupRowsBy,
    subheaderTemplate,
    subheaderClassName,
    subheaderStyle,
    lazy,
    filterable,
    filterDelay,
    defaultFilters,
    onFilter,
    onSort,
    defaultSortField,
    defaultSortOrder,
    selectionMode,
    selectionOn,
    selection,
    onSelectionChange,
    isRowSelectable,
    selectionDetail,
    pagination,
    currentPage,
    totalItems,
    pageSize,
    pageSizeOptions,
    paginationDetail,
    onPage,
    paginationClassName,
    paginationStyle,
    rowClassName,
    rowStyle,
    innerRef,
    skeletonRows = 10,
    onVirtualScroll,
}) {
    const wrapperRef = useRef(null)
    const headerRef = useRef(null)
    const rowsRef = useRef([])
    const rowsIndexRef = useRef({})
    const finalList = useRef(false)
    const [searching, setSearching] = useState(false)
    const [filters, setFilters] = useState(null)
    const [sort, setSort] = useState({
        field: defaultSortField ?? null,
        order: (defaultSortField && defaultSortOrder) || orders.noOrder,
    })
    const { columnHeaders, columnFilters, hasFilters, initFilters, columnFooters, hasFooter } = useMemo(() => {
        const _headers = []
        const _filters = []
        const _footers = []
        const _initFilters = {}
        let _hasFilters = false
        let _hasFooter = false
        let footerColSpanRest = 0
        columns.forEach((column, index) => {
            const { filterElement, filterPlaceholder, alignHeader } = column
            const id = column.id ?? index.toString()
            const field = column.field ?? ''
            const selector = !!column.selector
            const filter = column.filter ?? false
            const footer = column.footer ?? ''
            _headers.push({
                id,
                header: column.header ?? '',
                className: classNames([column.className, column.headerClassName]),
                style: joinStyles([column.style, column.headerStyle]),
                alignHeader,
                sortable: column.sortable ?? false,
                selector: selector,
                field,
            })
            if (filter) _initFilters[field] = ''
            if (!_hasFilters) _hasFilters = filter
            _filters.push({
                id,
                className: classNames([column.className, column.filterClassName]),
                style: joinStyles([column.style, column.filterStyle]),
                filter,
                filterElement,
                filterPlaceholder,
                field,
                selector: selector,
            })
            if (!_hasFooter) _hasFooter = !!footer
            if (footerColSpanRest === 0) {
                _footers.push({
                    id,
                    footer,
                    className: classNames([column.className, column.footerClassName]),
                    style: joinStyles([column.style, column.footerStyle]),
                    colSpan: column.footerColSpan,
                })
                footerColSpanRest = column.footerColSpan ?? 0
            }
            if (footerColSpanRest > 0) footerColSpanRest -= 1
        })
        return {
            columnHeaders: _headers,
            columnFilters: _filters,
            hasFilters: _hasFilters,
            initFilters: _initFilters,
            columnFooters: _footers,
            hasFooter: _hasFooter,
        }
    }, [columns])
    const buildRows = () => {
        rowsRef.current = []
        rowsIndexRef.current = {}
        let _allSelected = selectionMode ? true : false
        let selectableIndex = -1
        const _selection = selection ? (Array.isArray(selection) ? selection : [selection]) : []
        // Ordenar values si corresponde
        let _values = values
        if (!lazy) {
            if (sort.field) {
                // Ordenar filas
                _values = sortObjects(values, [
                    {
                        key: sort.field,
                        order: sort.order ?? undefined,
                    },
                ])
            }
            if (pagination) {
                // Paginar filas
                const _pageSize = pageSize ?? 10
                const _currentPage = currentPage ?? 1
                const offset = (_currentPage - 1) * _pageSize
                _values = _values.slice(offset, offset + _pageSize)
            }
        }
        const _rows = _values.map((value, index) => {
            const id = (rowKeyField ? propFromName(value, rowKeyField) : null) ?? index.toString()
            let selectable = false
            let selected = false
            const rowClassNames = []
            if (selectionMode) {
                selectable = typeof isRowSelectable === 'function' ? isRowSelectable(value) : true
                selected = !!_selection.find((s) => compareData(s, value))
                _allSelected = _allSelected && (!selectable || selected)
                if (selectable) selectableIndex++
                rowClassNames.push(selectable ? 'dtr-selectable' : 'dtr-disabled', selected && 'dtr-selected')
            }
            const _value = {
                id,
                value,
                rowIndex: index,
                selectable,
                selected,
                disabled: !selectable,
                selectableIndex: selectable ? selectableIndex : -1,
                className: classNames([
                    typeof rowClassName === 'function' ? rowClassName(value) : rowClassName,
                    ...rowClassNames,
                ]),
                style: typeof rowStyle === 'function' ? rowStyle(value) : rowStyle,
            }
            return _value
        })
        return { rows: groupRowsBy ? groupRows(_rows, groupRowsBy) : _rows, allSelected: _allSelected }
    }
    /** Agrupador de filas del DataTable. */
    const groupRows = (_rows, field) => {
        // Agrupar filas
        const groups = _rows.reduce((carry, row) => {
            const key = propFromName(row.value, field)
            if (!carry[key]) carry[key] = []
            carry[key].push(row)
            return carry
        }, {})
        // Generar subheaders para cada grupo de filas
        const groupedRows = []
        let _selectableIndex = 0
        Object.keys(groups).forEach((key) => {
            const groupRows = groups[key].map((row) => {
                if (row.selectable) {
                    row.selectableIndex = _selectableIndex
                    _selectableIndex++
                }
                return row
            })
            groupedRows.push({
                id: key,
                value: key,
                rowIndex: -1,
                selectable: false,
                selected: false,
                disabled: false,
                selectableIndex: -1,
                groupRows,
            })
        })
        return groupedRows
    }
    const rowsDependencies = [
        values,
        selection,
        selectionMode,
        groupRowsBy,
        lazy,
        ...(!lazy && pagination ? [currentPage, pageSize] : []),
        ...(!lazy ? [sort] : []),
    ]
    const { rows, allSelected } = useMemo(buildRows, rowsDependencies)
    useEffect(() => {
        if (filterable && !filters) {
            setFilters({ ...initFilters, ...defaultFilters })
        }
    }, [filterable, defaultFilters])
    useEffect(() => {
        if (!searching && wrapperRef.current) {
            const scrollTop = wrapperRef.current.scrollTop
            if (scrollTop > 32) {
                if (wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight === scrollTop) {
                    wrapperRef.current.scrollTop -= 32
                }
            }
        }
    }, [searching])
    /** Handler que se ejecuta cuando se de/selecciona una fila del DataTable. */
    const handleSelect = (value, selected) => {
        if (typeof onSelectionChange === 'function') {
            let _selection = null
            if (selectionMode === 'multiple') {
                if (Array.isArray(selection)) {
                    _selection = selected ? [...selection.filter((s) => !compareData(s, value))] : [...selection, value]
                } else {
                    _selection = selected ? [] : [value]
                }
            } else {
                _selection = selected ? null : value
            }
            onSelectionChange(_selection)
        }
    }
    /** Handler que se ejecuta cuando el valor del input de de/seleccionar todas las filas del DataTable cambia. */
    const handleToggleSelectAll = () => {
        if (onSelectionChange) {
            const getSelectableRows = (data) => {
                return data.reduce((carry, row) => {
                    if (row.groupRows) {
                        carry.push(...getSelectableRows(row.groupRows))
                    } else {
                        row.selectable && carry.push(row.value)
                    }
                    return carry
                }, [])
            }
            const _selection = allSelected ? [] : getSelectableRows(rows)
            onSelectionChange(_selection)
        }
    }
    /** Limpia el orden a los valores iniciales. */
    const clearSort = () => {
        setSort(initialSort)
        onSort && onSort(initialSort.field, initialSort.order)
    }
    /** Restablece el orden a los valores predeterminados. */
    const resetSort = () => {
        const _sort = {
            field: defaultSortField ?? null,
            order: (defaultSortField && defaultSortOrder) || orders.noOrder,
        }
        setSort(_sort)
        onSort && onSort(_sort.field, _sort.order)
    }
    /** Limpia los filtros a sus valores iniciales. */
    const clearFilters = () => {
        setFilters(initFilters)
        onFilter && onFilter(initFilters)
    }
    /** Restablece los filtros a los valores predeterminados. */
    const resetFilters = () => {
        setFilters({ ...initFilters, ...defaultFilters })
        onFilter && onFilter({ ...initFilters, ...defaultFilters })
    }
    /** Establece el foco en una fila seleccionable. */
    const focusRow = (rowIndex) => {
        if (rowIndex >= 0 && rowIndex < rowsRef.current.length) {
            const index = rowsIndexRef.current[rowIndex]
            index && rowsRef.current[index].focus()
        }
    }
    const getSelectionDetail = () => {
        let pDetailElement = ''
        if (selectionMode === 'multiple' && selectionDetail) {
            const totalSelected = Array.isArray(selection) ? selection.length : 0
            let pDetailContent = ''
            if (typeof selectionDetail === 'string') {
                const replacements = {
                    '{{totalSelected}}': totalSelected.toString(),
                }
                const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')
                pDetailContent = selectionDetail.replace(pattern, (matched) => replacements[matched])
            } else {
                pDetailContent = `Items seleccionados: ${totalSelected}`
            }
            pDetailElement = <div className='dts-detail'>{pDetailContent}</div>
        }
        return pDetailElement
    }
    const handleWrapperScroll =
        !finalList.current && !pagination && onVirtualScroll
            ? debounce(async () => {
                  if (wrapperRef.current) {
                      const clientHeight = wrapperRef.current.clientHeight
                      const scrollHeight = wrapperRef.current.scrollHeight
                      const scrollTop = wrapperRef.current.scrollTop
                      if (clientHeight + scrollTop === scrollHeight) {
                          if (!searching) {
                              setSearching(true)
                              const numRows = await onVirtualScroll()
                              if (numRows === 0) {
                                  finalList.current = true
                              }
                              setSearching(false)
                          }
                      }
                  }
              }, 50)
            : undefined
    return (
        <div
            className={classNames([
                'modular-datatable',
                typeof responsive === 'string' && `responsive-${responsive}`,
                className,
            ])}
            style={style}
        >
            <div
                ref={wrapperRef}
                onScroll={handleWrapperScroll}
                className={classNames(['dt-wrapper', headerSticky && 'dth-sticky', tableWrapperClassName])}
                style={tableWrapperStyle}
            >
                <BaseTable className={tableClassName} style={tableStyle} {...tableProps}>
                    <DataTableHeader
                        innerRef={headerRef}
                        columnHeaders={columnHeaders}
                        columnFilters={columnFilters}
                        filterable={filterable && hasFilters}
                        filters={filters}
                        setFilters={setFilters}
                        filterDelay={filterDelay}
                        onFilter={onFilter}
                        sort={sort}
                        setSort={setSort}
                        onSort={onSort}
                        selectionMode={selectionMode}
                        allSelected={allSelected}
                        onToggleSelectAll={handleToggleSelectAll}
                        headerElement={headerElement}
                    />
                    <DataTableBody
                        headerRef={headerRef}
                        headerSticky={headerSticky}
                        rowsRef={rowsRef}
                        rowsIndexRef={rowsIndexRef}
                        columns={columns}
                        rows={rows}
                        selectionMode={selectionMode}
                        selectionOn={selectionOn}
                        onSelect={handleSelect}
                        messageOnEmpty={messageOnEmpty}
                        groupRowsMode={groupRowsMode}
                        groupRowsBy={groupRowsBy}
                        subheaderTemplate={subheaderTemplate}
                        subheaderClassName={subheaderClassName}
                        subheaderStyle={subheaderStyle}
                        skeletonRows={skeletonRows}
                        searching={searching}
                    />
                    {footerElement ?? (hasFooter && <DataTableFooter columnFooters={columnFooters} values={values} />)}
                </BaseTable>
                <DataTableRef
                    ref={innerRef}
                    clearFilters={clearFilters}
                    resetFilters={resetFilters}
                    clearSort={clearSort}
                    resetSort={resetSort}
                    focusRow={focusRow}
                />
            </div>
            {getSelectionDetail()}
            {pagination && (
                <Pagination
                    currentPage={currentPage}
                    totalItems={lazy ? totalItems : values.length}
                    pageSize={pageSize ?? 10}
                    pageSizeOptions={pageSizeOptions}
                    paginationDetail={paginationDetail}
                    onPage={onPage}
                    className={paginationClassName}
                    style={paginationStyle}
                    responsive={responsive}
                />
            )}
        </div>
    )
}
