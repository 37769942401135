import { classNames } from '../utils'
export function FieldGroup({
    children,
    className,
    inline,
    label,
    labelClassName,
    labelStyle,
    showLabel = true,
    feedback,
    feedbackClassName,
    feedbackStyle,
    variantFeedback = 'error',
    showFeedback,
    fieldClassName,
    fieldStyle,
    ...props
}) {
    return (
        <div
            className={classNames(
                'modular-field-group',
                typeof inline === 'string' ? `inline-${inline}` : inline ? 'inline' : '',
                className,
            )}
            {...props}
        >
            <label className={classNames('mod-fg-label', { hide: !showLabel }, labelClassName)} style={labelStyle}>
                {label}
            </label>
            <div className={classNames('mod-fg-field', fieldClassName)} style={fieldStyle}>
                {children}
            </div>
            <div
                className={classNames(
                    'mod-fg-feedback',
                    `mod-fgf-${variantFeedback}`,
                    { show: showFeedback },
                    feedbackClassName,
                )}
                style={feedbackStyle}
            >
                {feedback}
            </div>
        </div>
    )
}
