
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import TableSolicitud from './components/TableSolicitud'
import ViewSolicitud from './components/ViewSolicitud'
import FormInforme from './components/FormInforme'

import SolicitudService from 'services/solicitud.service'
import { setHttpMessage } from 'store/messageSlice'
import TitlePage from 'components/TitlePage'
import SolicitudPDF from 'pdf/solicitud'
import InformeService from 'services/informe.service'
import { buildFilters, fileToBase64 } from 'utils/utils'
import { formatDate } from 'utils/utils'
import { initialPagination } from 'data/const'
import { useNavigate } from 'react-router-dom'
import FormUploadFile from 'components/FormUploadFIle'
import useFilters from 'hooks/useFilters'
import ViewFilePdf from 'components/ViewFilePdf'

/** @module Pages/Solicitud/SolicitudIndex */

const currentYear = formatDate(new Date(), 'Y')

/**
 * Página, Lista de solicitudes de Beneficiario.
 * @returns {JSX.Element} Retorna el componente SolicitudIndex.
 */
function SolicitudIndex() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {sectionKey} = useSelector(state => state.system)
    const [solicitudes, setSolicitudes] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const {filters, reloadFilters, ...filterHandlers} = useFilters()
    const [show, setShow] = useState({view: false, report: false, pdf: false, file: false, viewFile: false})
    const [file, setFile] = useState({value: null, error: ''})
    const [uploading, setUploading] = useState(false)
    const pdfSolicitud = new SolicitudPDF()
    const tableRef = useRef()
    const formRef = useRef()

    useEffect(() => {
        const loadSolicitudes = async () => {
            const {filter, ..._filters} = filters
            const _filter = {
                ...filter, 
                ...(filter?.fecha_creacion
                    ? {}
                    : buildFilters({
                        fecha_creacion: [
                            `${currentYear}-01-01 00:00:00`,
                            `${currentYear}-12-31 23:59:59`
                        ]
                    }, {between: ['fecha_creacion']})
                )
            }
            const response = await SolicitudService.indexSolicitudPost(
                {filter: _filter}, 
                {..._filters, tipo: 'B'}
            )
            if (response.status === 200) {
                setSolicitudes(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        loadSolicitudes()
    }, [filters])

    const openDialog = (name) => setShow({...show, [name]: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeReportDialog = () => setShow({...show, report: false})
    const closePdfDialog = () => setShow({...show, pdf: false})
    const closeFileDialog = () => setShow({...show, file: false})
    const closeViewFileDialog = () => setShow({...show, viewFile: false})

    const handleOpenDialog = (action, rowData) => {
        setSelected(rowData)
        if (action === 'view') {
            openDialog('info')
        } else if (action === 'report') {
            openDialog('report')
        } else if (action === 'pdf') {
            openDialog('pdf')
        } else if (action === 'file') {
            openDialog('file')
        } else if (action === 'view-file') {
            openDialog('viewFile')
        } else if (action === 'update') {
            navigate(`/${sectionKey}/solicitud/update/${rowData.id}`)
        } else if (action === 'cancel') {
            handleAnular()
        }
    }

    const updateReport = async (values) => {
        if (values.informe_id) {
            const response = await InformeService.update(values, values.informe_id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeReportDialog()
            }
        } else {
            const response = await InformeService.create(values)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 201) {
                reloadFilters()
                closeReportDialog()
            }
        }
    }
    const generateReport = async () => {
        if (selected?.informe) {
            pdfSolicitud.informe({...selected.informe, beneficiario: selected.beneficiario})
        }
    }
    const handleAdjuntoPdf = async () => {
        const response = await SolicitudService.viewSolicitud({ id: selected.id })
        if (response.status === 200) {
            pdfSolicitud.solicitudAdjunto(response.data.solicitud)
        }
    }
    const uploadFile = async () => {
        setUploading(true)
        if (selected) {
            const {value, error} = file
            if (value) {
                if (!error) {
                    const base64 = await fileToBase64(value)
                    const filename = value.name.slice(0, value.name.length-4)+'-'+formatDate(new Date(), 'YmdHi')
                    const response = await SolicitudService.uploadSolicitud({file: base64, filename}, selected.id)
                    dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                    if (response.status === 200) {
                        reloadFilters()
                        closeFileDialog()
                    }
                }
            } else {
                setFile({value: null, error: 'Requerido'})
            }
        }
        setUploading(false)
    }
    const handleAnular = async () => {
        if (selected?.id) {
            const response = await SolicitudService.anular(selected.id)
            dispatch(setHttpMessage({
                status: response.status, 
                title: response.data.message, 
                detail: response.data.errors?.length>1?response.data.errors[1].replace(/\\n/g, '\n'):''
            }))
            if (response.status === 200) {
                closeInfoDialog()
                reloadFilters()
            }
        }
    }
    const clearSelected = () => setSelected(null)

    const footerReportDialog = <>
        <Button startIcon='pi pi-times' variant='toutline-blue' onClick={closeReportDialog}>Cerrar</Button>
        <Button startIcon='pi pi-save' onClick={() => formRef?.current?.handleSubmit()}>Guardar</Button>
        {selected?.informe && <Button startIcon='pi pi-file-pdf' onClick={generateReport}>Generar PDF</Button>}
    </>
    const footerFileDialog = <>
        <Button startIcon='pi pi-times' variant='outline-blue' onClick={closeFileDialog}>Cancelar</Button>
        <Button startIcon='bi-cloud-upload-fill'  onClick={uploadFile} loading={uploading}>Guardar</Button>
    </>
    const footerPdfDialog = <Button startIcon='pi pi-times' onClick={closePdfDialog}>Cerrar</Button>

    return <>
        <TitlePage title='Lista de Solicitudes' />
        <div className='content'>
            <div>
                <TableSolicitud 
                    tableRef={tableRef}
                    solicitudes={solicitudes}
                    pagination={pagination}
                    filterHandlers={filterHandlers}
                    handleOpenDialog={handleOpenDialog}
                />

                <Dialog
                    show={show.viewFile}
                    header='Solicitud'
                    footer={<Button startIcon='pi pi-times' onClick={closeViewFileDialog}>Cerrar</Button>}
                    onHide={closeViewFileDialog}
                    style={{width: '60rem'}}
                    onExited={clearSelected}
                    scrollable
                >
                    <ViewFilePdf selected={selected}/>
                </Dialog>
                <Dialog
                    show={show.info}
                    header='Información'
                    footer={<Button startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>}
                    onHide={closeInfoDialog}
                    style={{width: 'auto', maxWidth: '60rem'}}
                    onExited={clearSelected}
                    scrollable
                >
                    <ViewSolicitud id={selected?.id} handleAction={handleOpenDialog} />
                </Dialog>
                <Dialog
                    show={show.report}
                    header='Informe de viaje'
                    footer={footerReportDialog}
                    onHide={closeReportDialog}
                    style={{width: '50rem'}}
                    onExited={clearSelected}
                    scrollable
                >
                    <FormInforme formRef={formRef} solicitud={selected} onSubmit={updateReport} />
                </Dialog>
                <Dialog
                    show={show.pdf}
                    header='Generar PDf'
                    footer={footerPdfDialog}
                    onHide={closePdfDialog}
                    onExited={clearSelected}
                >
                    <Button variant='outline-secondary' className='d-block w-100 mb-3' onClick={handleAdjuntoPdf}>Generar como Adjunto</Button>
                    <Button variant='outline-secondary' className='d-block w-100'>Generar como Carta</Button>
                </Dialog>
                <Dialog
                    show={show.file}
                    header='Subir PDF'
                    footer={footerFileDialog}
                    keyboard={false}
                    onHide={closeFileDialog}
                    onExited={() => {
                        clearSelected()
                        setFile({value: null, error: ''})
                    }}
                >
                    <p className='text-primary text-justify'>
                        Solo puede subir 1 archivo en formato PDF y debe contener los mismo documentos que serán enviados a la DAF
                        en el siguiente orden:
                        <ul>
                            <li>Solicitud</li>
                            <li>Resolución y/u otros documentos que autoricen el viaje (si corresponde)</li>
                            <li>Otros documentos (cronograma, invitaciones, trípticos u otros documentos que acrediten actos o eventos) </li>
                        </ul>
                    </p>
                    <FormUploadFile file={file} setFile={setFile} />
                </Dialog>
            </div>
        </div>
    </>
}
export default SolicitudIndex