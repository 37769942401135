import { useEffect, useRef, useState } from 'react'
import SolicitudService from 'services/solicitud.service'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import { initialPagination } from 'data/const'
import { verifyAccess } from 'utils/rbac.util'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'modules/modular/DataTable'
import { Button } from 'modules/modular/Button'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import InputCalendar from 'components/InputCalendar'
import CustomInput from 'components/CustomInput'
import { formatDate } from 'utils/FormatDate'

/** @module Pages/Solicitud/SolicitudViaticosIndex */

const currentYear = formatDate(new Date(), 'Y')

// Valores iniciales del formulario.
const initialValues = {
    solicitud: '',
    fecha: '',
    gestion: '',
}

/**
 * Página, Lista de solicitudes de DAF.
 * @returns {JSX.Element} Retorna el componente SolicitudDAFIndex.
 */
function SolicitudViaticosIndex() {
    const navigate = useNavigate()
    const tableRef = useRef()
    const [solicitudes, setSolicitudes] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const { filters, handlePage, handleSort, setFilters } = useFilters()
    const [values, setValues] = useState(initialValues)

    useEffect(() => {
        const loadSolicitudes = async () => {
            const { status, data } = await SolicitudService.beneficiariosToViaticos(filters)
            if (status === 200) {
                setSolicitudes(
                    data.data.map((el) => ({
                        ...el,
                        solicitud: `${el.numero_solicitud}-_-${el.identificador}-_-${el.objetivo_viaje}-_-${el.fecha_creacion}`,
                    })),
                )
                setPagination(data.pagination)
            }
        }
        loadSolicitudes()
    }, [filters])

    const handleValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    const handleFilter = (values) => {
        const { solicitud, fecha, gestion } = values
        const _values = {
            solicitud,
            fecha_desde: `${formatDate(gestion, 'Y', currentYear)}-${fecha ? formatDate(fecha, 'm-d') : '01-01'} 00:00:00`,
            fecha_hasta: `${formatDate(gestion, 'Y', currentYear)}-${fecha ? formatDate(fecha, 'm-d') : '12-31'} 23:59:59`,
        }
        setFilters((filters) => {
            if (filters.sort) _values.sort = filters.sort
            if (filters.pageSize) _values.pageSize = filters.pageSize
            return _values
        })
    }
    const handleClean = () => {
        tableRef?.current?.clearFilters()
        setValues(initialValues)
        handleFilter(initialValues)
    }
    const handleSearch = () => handleFilter(values)
    const searchOnEnter = (e) => {
        e.key === 'Enter' && handleSearch()
    }

    const subheaderTemplate = (value) => {
        const [numero_solicitud, identificador, objetivo_viaje, fecha_creacion] = value.split('-_-')
        return (
            <>
                Solicitud N°{numero_solicitud}
                {identificador && ` - ${identificador}`} - {formatDate(fecha_creacion, 'd/m/Y')} -
                <span className='fw-normal ms-1'>{objetivo_viaje}</span>
            </>
        )
    }
    const optionsTemplate = (rowData) => (
        <div className='row g-2'>
            <div className='col-md'>
                <Button
                    variant='outline-blue'
                    size='sm'
                    className='w-100 text-nowrap'
                    startIcon={<span className='bi-eye' />}
                    onClick={() => navigate(`../papeleta?sbId=${rowData.id}`)}
                >
                    Ver papeletas
                </Button>
            </div>
            <div className='col-md'>
                {verifyAccess('papeletaCreate') && (
                    <Button
                        variant='blue'
                        size='sm'
                        className='w-100 text-nowrap'
                        startIcon={<span className='bi-plus-lg' />}
                        onClick={() => navigate(`../papeleta/create/${rowData.id}`)}
                    >
                        Nueva papeleta
                    </Button>
                )}
            </div>
        </div>
    )
    const columns = [
        {
            field: 'nombre',
            header: 'Beneficiario',
            body: (rowData) => (
                <div>
                    <span
                        className='bi-person bg-blue text-white me-2'
                        style={{ height: '1em', width: '1em', borderRadius: '.25rem' }}
                    />
                    {rowData.nombre}
                </div>
            ),
        },
        { field: 'lugar_viaje', header: 'Lugar Viaje' },
        { field: 'fecha_salida', header: 'Fecha Salida', body: (rowData) => formatDate(rowData.fecha_salida, 'd/m/Y') },
        {
            field: 'fecha_llegada',
            header: 'Fecha Retorno',
            body: (rowData) => formatDate(rowData.fecha_llegada, 'd/m/Y'),
        },
        { field: 'papeletas', header: 'Papeletas', alignHeader: 'center', className: 'text-center' },
        { field: 'id', header: 'Opciones', alignHeader: 'center', body: optionsTemplate, style: { width: '20rem' } },
    ]

    return (
        <>
            <TitlePage title='Lista de Solicitudes' />
            <div className='content'>
                <div className='datatable-toolbar row g-2'>
                    <div className='col-xl-auto ms-auto'>
                        <div className='row'>
                            <FieldGroup label='Solicitud:' inline className='col-md-auto ms-auto mb-0'>
                                <Input
                                    name='solicitud'
                                    value={values.solicitud}
                                    onChange={handleValues}
                                    className='solicitud-filtro'
                                    placeholder='nro o id'
                                    onKeyUp={searchOnEnter}
                                />
                            </FieldGroup>
                            <FieldGroup label='Fecha:' inline className='col-md-auto mb-0'>
                                <InputCalendar
                                    name='fecha'
                                    value={values.fecha}
                                    onChange={handleValues}
                                    formatLabel='d/m'
                                    containerClassName='solicitud-filtro'
                                    hideYear
                                    placeholder='dd/mm'
                                />
                            </FieldGroup>
                            <FieldGroup label='Gestión' inline className='col-md-auto mb-0'>
                                <InputCalendar
                                    name='gestion'
                                    containerClassName='solicitud-filtro'
                                    value={values.gestion}
                                    onChange={handleValues}
                                    onlyYearPicker
                                    formatLabel='Y'
                                    customInput={
                                        <CustomInput
                                            className='form-control fw-bold'
                                            startIcon={<div className='text-black'>Gestión</div>}
                                            placeholder={currentYear}
                                        />
                                    }
                                />
                            </FieldGroup>
                            <div className='col-lg-auto text-end'>
                                <Button variant='outline-blue' onClick={handleClean} title='Limpiar' className='me-2'>
                                    <span className='pi pi-filter-slash' />
                                </Button>
                                <Button variant='blue' onClick={handleSearch} title='Buscar'>
                                    <span className='bi-search' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    innerRef={tableRef}
                    values={solicitudes}
                    columns={columns}
                    onSort={handleSort}
                    lazy
                    groupRowsMode='subheader'
                    groupRowsBy='solicitud'
                    subheaderTemplate={subheaderTemplate}
                    subheaderStyle={{ backgroundColor: 'rgba(0, 0, 0, .05)' }}
                    pagination
                    pageSize={pagination.pageSize}
                    pageSizeOptions={[10, 25, 50]}
                    totalItems={pagination.totalRecords}
                    currentPage={pagination.currentPage}
                    onPage={handlePage}
                    responsive
                />
            </div>
        </>
    )
}
export default SolicitudViaticosIndex
