/**
 * Crea una función que se ejecutará solo después de que haya pasado un período de espera
 * desde el último evento.
 *
 * @param func La función que se debe debilitar.
 * @param wait El número de milisegundos que debe esperar antes de ejecutar la función.
 * @returns Una nueva función que está debilitada.
 */
export function debounce(func, wait) {
    let timeout
    return function (...args) {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(this, args), wait)
    }
}
