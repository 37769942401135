import { ErrorMessage, Field } from 'formik'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Select } from 'modules/modular/Select'
import { propFromName } from 'modules/modular/utils'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { toPascalCase } from 'utils/ToPascalCase'

/**
 * @typedef {object} SelectFormikProps
 * @property {string} label
 * @property {string} containerClassName
 * @property {React.CSSProperties} containerStyle
 * @property {boolean} inline
 * @property {boolean} showError
 * @property {boolean} showLabel
 */

/**
 * @param {SelectFormikProps & import('modules/modular/Select').SelectProps} props
 * @returns {JSX.Element} Retorna el componente SelectFormik
 */
function SelectFormik({
    name = '',
    label,
    containerClassName,
    containerStyle,
    inline,
    showError = true,
    showLabel,
    info,
    ...props
}) {
    const input = ({ field, form: { errors, touched } }) => {
        if (propFromName(touched, name) && propFromName(errors, name)) props.isInvalid = true
        return <Select {...field} {...props} />
    }

    const popover = info && (
        <Popover id='popover-basic'>
            <div className='p-2 text-justify'>{info}</div>
        </Popover>
    )

    const error = name ? <ErrorMessage name={name}>{(msg) => msg}</ErrorMessage> : ''

    return (
        <FieldGroup
            label={
                <div className='d-flex align-items-center'>
                    <span className='me-2'>{label ?? toPascalCase(name)}</span>
                    {info && (
                        <OverlayTrigger trigger={['hover', 'focus', 'click']} placement='right' overlay={popover}>
                            <span className='info-icon text-primary bi-info-circle'></span>
                        </OverlayTrigger>
                    )}
                </div>
            }
            className={containerClassName}
            style={containerStyle}
            inline={inline}
            feedback={error}
            showFeedback={showError && error}
            showLabel={showLabel}
        >
            <Field name={name}>{input}</Field>
        </FieldGroup>
    )
}

export { SelectFormik }
