import Dialog from './Dialog'
import DataTable from './DataTable'
import Button from './Button'
import { useEffect, useRef, useState } from 'react'

function ListOfValues({ 
    onSelect, description,
    columns, rows, selectionMode='single', selected,
    show, onHide, header, style, error,
    labelCancel='Cancelar', labelSelect='Seleccionar',
    isDataSelectable, tableClassName,
    tableRef,
}) {
    const btnSelectRef = useRef(null)
    const initialSelection = selectionMode === 'multiple' ? [] : null
    const [currentSelection, setCurrentSelection] = useState(initialSelection)
    const dialogProps = {show, onHide, header, style}
    const dataTableProps = {
        columns, 
        values: rows, 
        selectionMode, 
        selected, 
        isDataSelectable,
        selection: currentSelection, 
        onSelectionChange: (_value) => setCurrentSelection(_value),
        className: tableClassName,
    }    
    
    useEffect(() => {
        if (selectionMode==='single' && currentSelection) {
            btnSelectRef.current?.focus()
        }
    }, [currentSelection])

    const handleSelect = () => {
        typeof onSelect === 'function' && onSelect(currentSelection)
        setCurrentSelection(initialSelection)
    }
    const handleCancel = () => {
        setCurrentSelection(initialSelection)
        typeof onHide === 'function' && onHide()
    }

    const footerDialog = <>
        <Button variant='outline-blue' startIcon='pi pi-times' onClick={handleCancel}>{labelCancel}</Button>
        <Button startIcon='pi pi-check' onClick={handleSelect} ref={btnSelectRef}>{labelSelect}</Button>
    </>

    return (
        <Dialog footer={footerDialog} {...dialogProps} keyboard={false} level={3}>
            {description}
            <DataTable {...dataTableProps} innerRef={tableRef} />
            {error && <div className='invalid-feedback d-block'>{error}</div>}
        </Dialog>
    )
}

export default ListOfValues