import React, { forwardRef } from 'react'
import { Modal } from './Modal'
import { classNames, joinStyles } from '../utils'
import QuestionFill from '../icons/QuestionFill'
import { XLg } from '../icons/XLg'
import CheckLg from '../icons/CheckLg'
import { BaseAcceptButton, BaseRejectButton } from '../base/BaseConfirm'
export const Confirm = forwardRef(
    (
        {
            header = 'Confirmar',
            headerClassName,
            headerStyle,
            bodyClassName,
            bodyStyle,
            footerClassName,
            footerStyle,
            icon,
            iconClassName,
            iconStyle,
            acceptLabel = 'Si',
            rejectLabel = 'No',
            acceptClassName,
            acceptStyle,
            rejectClassName,
            rejectStyle,
            onAccept,
            onReject,
            children,
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <Modal {...props} className={classNames('modular-confirm', className)} ref={ref}>
                <div className={classNames('mod-confirm-header', headerClassName)} style={headerStyle}>
                    {header}
                </div>
                <div className={classNames('mod-confirm-body', bodyClassName)} style={bodyStyle}>
                    <div className='mod-cb-icon'>
                        {icon ?? (
                            <QuestionFill
                                className={iconClassName}
                                style={joinStyles({ fontSize: '2.5rem', lineHeight: 1 }, iconStyle)}
                            />
                        )}
                    </div>
                    <div>{children}</div>
                </div>
                <div className={classNames('mod-confirm-footer', footerClassName)} style={footerStyle}>
                    <BaseRejectButton className={rejectClassName} style={rejectStyle} onClick={onReject}>
                        <XLg className='mod-cfo-icon' /> {rejectLabel}
                    </BaseRejectButton>
                    <BaseAcceptButton className={acceptClassName} style={rejectStyle} onClick={onAccept}>
                        <CheckLg className='mod-cfo-icon' /> {acceptLabel}
                    </BaseAcceptButton>
                </div>
            </Modal>
        )
    },
)
