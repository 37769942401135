import { forwardRef } from 'react'
import { Button } from 'react-bootstrap'
export const BaseButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <Button {...props} ref={ref}>
            {children}
        </Button>
    )
})
BaseButton.displayName = 'BaseButton'
