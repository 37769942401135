import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form as FormFormik, Formik } from 'formik'

import { CustomInputFormik } from 'components/formik/formikFormComponents'
import { login, loginGoogle } from 'store/authSlice'
import { loginValidators } from 'utils/validators/validators'
import { LogoViaTick } from 'assets/svg/viaTick'
import userLogin from 'assets/img/user-login.png'
import Button from 'components/Button'
import umssLogoV from 'assets/img/logo-umss-v.png'
import dticLogo from 'assets/img/logo-dtic.png'
import Footer from 'components/Footer'
import { useNavigate } from 'react-router-dom'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'

const initialValues = { username: '', password: '' }

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [manual, setManual] = useState('')

    useEffect(()=>{
        /* global google */
        // google.accounts.id.initialize({
        //     client_id: process.env.REACT_APP_GOOGLE_API_KEY,
        //     callback: handleCredentialResponse,
        //     ux_mode: 'popup',
        //   });
        //   google.accounts.id.renderButton(
        //     document.getElementById('buttonDiv'),
        //     { theme: 'outline', size: 'medium' }
        //   );
        const loadManuales = async () => {
            const response = await ApiService.config.search({keys: ['manualSistema']})
            if (response.status===200) {
                const {data} = response
                setManual(data.manualSistema?.valor||'')
            }
        }
        loadManuales()
    }, [])

    const handleCredentialResponse = (response) => {
        dispatch(loginGoogle(response.credential))
    }

    const onSubmit = (values) => {
        setError(null)
        setSubmitting(true)
        dispatch(login(values))
            .then(()=>navigate('/'))
            .catch(err=>setError(err))
            .finally(() => setSubmitting(false))
    }

    const openManual = async () => {
        const filename = manual
        if (filename) {
            const response = await ApiService.getLocalFile(filename)
            if (response.status===200) {
                const base64File = response.data.file
                fetch(`data:application/pdf;base64,${base64File}`).then(async (res) => {
                    const fileBlob = await res.blob()
                    const fileURL = URL.createObjectURL(fileBlob)
                    window.open(fileURL)
                })
            } else {
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            }
        }
    }

    return (
        <div className='login-page'>
            <div className='login-header py-3'>
                <div className='d-none d-sm-block' style={{marginLeft: '5rem'}}>
                    <img src={umssLogoV} alt='Logo UMSS' className='me-3' style={{height: '5rem'}} />
                    <img src={dticLogo} alt='Logo DTIC' style={{height: '5rem'}} />
                </div>
                <div 
                    className='text-white fw-bold ms-auto fs-5' 
                    style={{backgroundColor: 'var(--umss-red)', padding: '.5rem 7.5% .5rem 1rem'}}
                >INICIO DE SESIÓN</div>
            </div>
            <div className='login-container'>
                <div className='login'>
                    <div className='position-relative bg-white' style={{padding: '1.5rem', paddingTop: '6.5rem'}}>
                        <div 
                            className='w-100 position-absolute d-flex justify-content-center' 
                            style={{top: '1.5rem', left: 0}}
                        >
                            <img src={userLogin} alt='login' style={{ height: '10rem' }} />
                        </div>
                        <div className='d-flex justify-content-center mb-4' style={{border: '1px solid #102644', borderBottomRightRadius: '2.5rem', borderBottomLeftRadius: '2.5rem', padding: '1.5rem', paddingTop: '6.5rem'}}>
                            <div className='w-100'>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={loginValidators}
                                    onSubmit={onSubmit}
                                >
                                    {() => (
                                        <FormFormik>
                                            <CustomInputFormik 
                                                name='username' 
                                                containerClassName='none-label mb-4' 
                                                className='bottom-line' 
                                                icon='bi-person-circle' 
                                                label='Usuario'
                                                placeholder='Usuario' 
                                            />
                                            <CustomInputFormik 
                                                name='password' 
                                                containerClassName='none-label mb-4' className='bottom-line' 
                                                icon='bi-lock-fill' placeholder='Contraseña' 
                                                type={showPassword ? 'text' : 'password'} 
                                                label='Contraseña' 
                                                endIcon={(
                                                    <div className='flex-1 cursor-pointer' onClick={(e) => {
                                                        e.stopPropagation()
                                                        setShowPassword(!showPassword)
                                                    }}>
                                                        <span className={showPassword ? 'bi-eye-slash' : 'bi-eye'} title={showPassword ? 'Ocultar Contraseña' : 'Mostrar Contraseña'}/>
                                                    </div>
                                                )}
                                            />
                                            { error ? <div className='invalid-feedback d-block mb-3'>{error}</div> : null }
                                            <div className='d-flex justify-content-center p-3'>
                                                <Button type='submit' loading={submitting}>INGRESAR</Button>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <div id='buttonDiv'></div>
                                            </div>
                                        </FormFormik>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='welcome-container'>
                    <div className='logo' style={{fill: '#fff'}}>{LogoViaTick}</div>
                    <div className='welcome d-flex flex-column align-items-center justify-content-center text-white'>
                        <div className='fw-bold' style={{fontSize: 'calc(1rem + 3vw)'}}>BIENVENIDOS AL</div>
                        <div className='lh-1 fst-italic' style={{fontSize: 'calc(1rem + 2vw)'}}>Sistema de Pasajes y Viáticos</div>
                    </div>
                </div>
            </div>
            {manual && <div className='text-center pb-3'>
                <Button variant='outline-blue' style={{width: '12.5rem'}} onClick={openManual} tabIndex='-1'>
                    <div className='d-flex align-items-center'>
                        <div className='bi-journal-text fs-4 me-3'></div>
                        <div className='text-wrap fw-bold'>Manual de Sistema</div>
                    </div>
                </Button>
            </div>}
            <Footer />
        </div>
    )
}