/**
 * Obtiene el valor de la propiedad de un objeto.
 * @param {object} obj Objeto del cual se obtendrá la propiedad.
 * @param {string} str Nombre de la propiedad a obtener. Ejem: person.name.firstName
 * @returns Retorna el valor de la propiedad.
 * @example
 * const programmer = {
 *      person: {
 *          name: {
 *              firstName: 'Charles',
 *              lastName: 'Smith'
 *          },
 *          age: 24
 *      },
 *      tasks: [
 *          'Order files',
 *          'Review reports'
 *      ]
 * }
 * const firstName = propFromName(programmer, 'person.name.firstName')
 * console.log(firstName) // Charles
 */
export const propFromName = (obj, str) => {
    const props = str.split('.')
    let value = obj
    for (let index = 0; index < props.length; index++) {
        const prop = props[index]
        if (typeof value === 'object') {
            value = value[prop]
        } else {
            value = undefined
            break
        }
    }
    return value
}
