import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'

import { EditableSelectFormik } from 'components/formik/formikFormComponents'
import Button, { ButtonIcon } from 'components/Button'
import DataTable from 'components/DataTable'
import TitlePage from 'components/TitlePage'

import PapeletaService from 'services/papeleta.service'
import { ReembolsoService } from 'services/reembolso.service'
import { setHttpMessage } from 'store/messageSlice'
import { reembolsoValidator } from 'utils/validators/validators'
import { formatDate } from 'utils/utils'
import { InfoDialog } from 'components/Dialog'
import { useNavigate } from 'react-router-dom'
import { SelectFormik } from 'components/formik'

/** @module Pages/Reembolso/ReembolsoCreate */

const recursos = [
    { label: 'Institucional', value: 'I' },
    { label: 'Propios', value: 'P' },
    { label: 'Gremios', value: 'M' },
]
// Valores iniciales del formulario.
const initialValues = {
    recursos: 'P',
    unidad: '',
    papeletas: []
}
const columns = [
    {
        selector: true,
        header: '',
        selectionOnClickRow: true,
        style: {width: '4rem'}
    },
    {
        header: 'Nro.',
        field: 'numero_papeleta',
        body: rowData => <div className='text-center'>{rowData.numero_papeleta}</div>,
        style: {width: '8rem'}
    },
    {
        header: 'Doc. Autorización',
        field: 'doc_autorizacion'
    },
    {
        header: 'Beneficiario',
        field: 'beneficiario',
    },
    {
        header: 'Lugar Viaje',
        field: 'lugar_viaje'
    },
    {
        header: 'Cuenta',
        field: 'cuenta'
    },
]

/**
 * Página, Registro de reembolso.
 * @returns {JSX.Element} Retorna el componente ReembolsoCreate.
 */
function ReembolsoCreate() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { sectionKey } = useSelector(state => state.system)
    const { userInfo } = useSelector(state => state.auth)
    const formRef = useRef()
    const [unidades, setUnidades] = useState([])
    const [papeletas, setPapeletas] = useState([])
    const [selection, setSelection] = useState([])
    const [numReembolso, setNumReembolso] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [show, setShow] = useState({success: false})

    useEffect(() => {
        loadNumReembolso()
    }, [])

    const openDialog = (name) => setShow({...show, [name]: true})
    const closeSuccessDialog = () => {
        cleanForm()
        setShow({...show, success: false})
    }

    const loadNumReembolso = async () => {
        const response = await ReembolsoService.getNextNumReembolso()
        if (response.status === 200) setNumReembolso(response.data)
    }
    const handleSubmit = async (values) => {
        setSubmitting(true)
        const data = {
            ...values,
            unidad_id: values.unidad.id,
            papeletas: selection.map(p=>p.id),
        }
        const response = await ReembolsoService.createReembolso(data)
        if (response.status === 201) {
            openDialog('success')
        } else {
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        }
        setSubmitting(false)
    }
    const cleanForm = () => {
        formRef?.current?.resetForm()
        loadNumReembolso()
    }
    const validate = () => {
        const _errors = {}
        if (selection.length === 0) _errors.papeletas = 'Debe seleccionar por lo menos 1 papeleta'
        return _errors
    }
    const searchUnidades = (unidad) => {
        const loadData = async () => {
            const response = await ReembolsoService.unidad.search(unidad)
            if (response.status === 200) setUnidades(response.data)
        }
        if (unidad.length > 0) loadData()
        else setUnidades([])
    }
    const redirectToList = () => {
        navigate(`/${sectionKey}/reembolso`)
    }

    const footerSuccessDialog = <Button variant='outline-success' onClick={redirectToList}>Ir a la lista de reembolsos</Button>

    return <>
        <TitlePage title='Registro de Reembolso' />
        <div className='content'>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validate={validate}
                validationSchema={reembolsoValidator}
                onSubmit={handleSubmit}
            >{() => (
                <Form onKeyDown={(e) => {if (e.key==='Enter') e.preventDefault()}}>
                    <div className='d-flex justify-content-center mb-3'>
                        <div className='w-100 w-xl-75'>
                            <div className='d-flex align-items-center mb-3 fs-5'>
                                <div>
                                    Nro. de Reembolso: {numReembolso}
                                    <ButtonIcon 
                                        variant='text-secondary' className='ms-1'
                                        rounded size='sm'
                                        icon='pi pi-refresh' title='Recargar Nro.'
                                        onClick={loadNumReembolso}
                                    />
                                </div>
                                <div className='ms-auto '>Fecha: {formatDate(new Date(), 'd/m/Y')}</div>
                            </div>
                            <div className='row'>
                                <EditableSelectFormik 
                                    name='unidad' 
                                    label='Unidad' 
                                    containerClassName='col-6'
                                    options={unidades} 
                                    optionValue='descripcion' 
                                    lazy valueAsOption
                                    onSearch={searchUnidades}
                                />
                                <SelectFormik
                                    name='recursos'
                                    options={recursos}
                                    containerClassName='col-6'
                                    disabled={userInfo.fuenteFinanciamiento !== 'DAF'}
                                    placeholder={null}
                                />
                            </div>
                        </div>
                    </div>
                    <label className='form-label fs-5'>Papeletas de viáticos</label>
                    <DataTable 
                        className='mb-3' 
                        values={papeletas} 
                        columns={columns}
                        selectionMode='multiple'
                        onSelectionChange={(selection) => setSelection(selection)}
                        selection={selection} 
                    />
                    <ErrorMessage name='papeletas'>{msg => <div className='d-block invalid-feedback'>{msg}</div>}</ErrorMessage>
                    <Observer setPapeletas={setPapeletas} setSelection={setSelection} />
                    <div className='d-flex justify-content-center mt-4'>
                        <Button startIcon='pi pi-save' type='submit' loading={submitting}>Guardar</Button>
                    </div>
                </Form>
            )}</Formik>
            <InfoDialog
                show={show.success}
                onAccept={closeSuccessDialog}
                footer={footerSuccessDialog}
                closeButton={false}
                variant='success'
            >
                El reembolso ha sido registrado correctamente.
            </InfoDialog>
        </div>
    </>
}
export default ReembolsoCreate

/**
 * Observer del formulario.
 */
function Observer({ setPapeletas, setSelection}) {
    const { values } = useFormikContext()

    /**
     * Obtiene las papeletas de la unidad seleccionada
     */
    useEffect(() => {
        const {unidad} = values
        const unidad_id = unidad?.id||''
        const loadData = async () => {
            const filter = unidad.das ? {da_id: (unidad.das||'').split(',')} : {cuenta_id: (unidad.cuentas||'').split(',')}
            const response = await PapeletaService.searchPapeletas({ filter: {...filter, estado: 'P' } }, {all: true})
            if (response.status === 200) {
                setSelection(response.data.data)
                setPapeletas(response.data.data)
            }
        }
        if (unidad_id) {
            loadData()
        } else {
            setPapeletas([])
            setSelection([])
        }
    }, [values.unidad]) 

}