import { forwardRef } from 'react'
import { classNames } from '../utils'
export const BaseAcceptButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <button {...props} className={classNames(props.className, 'button')} ref={ref}>
            {children}
        </button>
    )
})
BaseAcceptButton.displayName = 'BaseAcceptButton'
export const BaseRejectButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <button {...props} className={classNames(props.className, 'button-outline')} ref={ref}>
            {children}
        </button>
    )
})
BaseRejectButton.displayName = 'BaseRejectButton'
