import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { classNames } from 'utils/utils'

const initialSort = { field: null, order: null }

/** @module Components/DataTable */

/**
 * @typedef {object} ColumnDataTableProps
 * @property {string | undefined} field Propiedad que se mostrará en la columna.
 * @property {React.CSSProperties | undefined} style Estilos en linea de la columna.
 * @property {string | undefined} className Clase de estilo de la columna.
 *
 * @property {string | undefined} headerContenido del header de la columna.
 * @property {string | undefined} headerClassName Clase de estilo del header de la columna.
 * @property {React.CSSProperties | undefined} headerStyle Estilos en linea del header de la columna.
 * @property {"start" | "end" | "center" | undefined} alignHeader Alineamiento del texto del header de la columna.
 * @property {React.ReactNode | Function | undefined} headerTemplate Plantilla que se mostrará en el header de la columna.
 *
 * @property {React.ReactNode | Function | undefined} body Plantilla que se mostrará en las filas de la columna.
 * @property {string | Function | undefined} bodyClassName Clase de estilo de las filas de la columna.
 * @property {React.CSSProperties | undefined} bodyStyle Estilos en linea de las filas de la columna.
 *
 * @property {boolean | undefined} selector Especifica si la columna contendrá los checkbox de selección. Solo en modo selección.
 * @property {boolean | undefined} selectionOnClickRow Especifica si la selección se realiza al dar click en la fila o no.
 *
 * @property {React.ReactNode | Function | undefined} filterElement Filtro personalizado de la columna.
 * @property {boolean | undefined} filter Especifica si la columna tendrá un input de filtro.
 *
 * @property {boolean | undefined} sortable Especifica si la columna tendrá la opción para ordenar la columna.
 *
 * @property {React.ReactNode | undefined} footer Contenido del footer de la columna.
 * @property {string | undefined} footerClassName Clase de estilo del footer de la columna.
 * @property {React.CSSProperties | undefined} footerStyle Estilos en linea del footer de la columna.
 */

/**
 * @typedef {object} DataTableProps 
 * @property {Array<DataTableColumn<any>>} columns Array de objetos con la configuración de las columnas de la tabla.
 * @property {Array<object>} values Valores de las filas de la tabla.
 * @property {string | undefined} className Clase de estilo del componente.
 * @property {React.CSSProperties | undefined} style Estilos en linea del componente.
 * @property {string | undefined} tableClassName Clase de estilo de la tabla.
 * @property {React.CSSProperties | undefined} tableStyle Estilos en linea de la tabla.
 * @property {string | undefined} emptyMessage Mensaje mostrado cuando no hay filas en la tabla.
 *
 * @property {string | undefined} groupRowsBy Propiedad por la cual se agruparan las filas.
 * @property {object | undefined} groupRowsLabels Labels que se mostrarán como subheader de los grupos. 
 * @example
 *  groupRowsLabels -> {
 *      AR: 'Argentina',
 *      BO: 'Bolivia',
 *      BR: 'Brasil',
 *      CH: 'Chile',
 *      CO: 'Colombia'
 *  }
 * @property {"asc" | "desc" | undefined} groupRowsOrder Orden de agrupamiento.
 * @example
 * groupRowsCustomOrder -> ['BO', 'CO', 'AR', 'BR', 'CH']
 * @property {Array<string> | undefined} groupRowsCustomOrder Orden personalizado de agrupamiento. 
 *    
 * @property {boolean | undefined} filterable Especifica si la tabla tendrá la fila de filtros.
 * @property {Function | undefined} onFilter void Callback que se ejecuta cada vez que cambia algún valor de los filtros.
 * @property {Function | undefined} onSort Callback que se ejecuta cada vez que se cambia el orden de una columna.
 * @property {number | undefined} filterDelay Tiempo en segundos para que se ejecute onFilter ante el cambio en los filtros
 * @property {object | undefined} initialFilters Filtros iniciales.
 *    
 * @property {"multiple" | "single" | undefined} selectionMode Modo de selección.
 * @property {Function | undefined} isRowSelectable Función que habilita las filas que son seleccionables.
 * @property {object| Array<object> | null | undefined} selection Valor(es) seleccionados.
 * @property {Function | undefined} onSelectionChange Callback que se ejecuta cada vez que se selecciona o deselecciona alguna fila.
 *  
 * @property {boolean | undefined} pagination Especifica si la tabla tendrá paginación o no.
 * @property {Function | undefined} onPage Callback que se ejecuta cada vez que se cambia de página o se cambia la cantidad de filas por página.
 * @property {number | undefined} rowsPerPage Cantidad de filas por página.
 * @property {Array<number> | undefined} rowsPerPageOptions Array de opciones para cambiar la cantidad de filas por página.
 * @property {number | undefined} totalRecords Cantidad total de registros.
 * @property {number | undefined} page Número de página actual.
 * @property {string | undefined} paginationReport Descripción de paginación.
 * @property {string | undefined} paginationClassName Clase de estilo de la paginación.
 * @property {React.CSSProperties | undefined} paginationStyle Estilos en linea de la paginación.
 *    
 * @property {Function | string | undefined} rowClassName Clase de estilos de las filas.
 * @property {React.CSSProperties | undefined} rowStyle Estilos en linea de las filas.
 * @property {React.RefObject<DataTableElement> | undefined} innerRef Referencia del componente.
 */

/**
 * Componente DataTable, construido en base al componente Table de react-bootstrap.
 * @version 1.0.0
 * @param {DataTableProps} props Propiedades del componente.
 * @returns {JSX.Element} Retorna el componente DataTable.
 */
export default function DataTable({
    columns=[], 
    values, 

    filterable, 
    filterDelay, 
    onFilter, 
    onSort,
    initialFilters,

    selectionMode,
    selection, 
    onSelectionChange, 
    isRowSelectable, 

    pagination, 
    totalRecords,
    rowsPerPage,
    rowsPerPageOptions,
    onPage,
    paginationClassName,
    paginationStyle,
    page,
    paginationReport,

    className,
    style,
    tableClassName, 
    tableStyle, 
    rowClassName,
    rowStyle,

    emptyMessage,
    groupRowsBy,
    groupRowsLabels,
    groupRowsOrder,
    groupRowsCustomOrder,

    innerRef
}) {
    const [allSelected, setAllSelected] = useState(false)
    const [rows, setRows] = useState([])
    // Fila enfocada.
    const [focusedRow, setFocusedRow] = useState(-1)
    // Estado que vacía los filtros.
    const [clear, setClear] = useState(false)
    
    useEffect(() => {
        buildRows()
    }, [values, selection, selectionMode, groupRowsBy]) 
    
    const onSelectionRow = (value, selected) => {
        if (typeof onSelectionChange === 'function') {
            let _selection = null
            if (selectionMode==='multiple') {
                if (Array.isArray(selection)) {
                    if (selected) {
                        _selection = [...selection.filter(s=>!isSelected(s, value))]
                    } else {
                        _selection = [...selection, value]
                    }
                } else {
                    _selection = []
                }
            } else {
                if (selected) {
                    _selection = null
                } else {
                    _selection = value
                }
            }
            onSelectionChange(_selection)
        }
    }

    const buildRows = () => {
        let _allSelected = true
        const _rows = values.map((value, index) => {
            let disabled = false
            let selected = false
            if (selectionMode) {
                disabled = typeof isRowSelectable==='function'?!isRowSelectable(value):false
                selected = selectionMode==="multiple" 
                    ? (Array.isArray(selection)&&!!selection.find(s=>isSelected(s, value))) 
                    : (selectionMode==="single" 
                        ? (selection ? isSelected(selection, value) : false)
                        : false
                    )
                 _allSelected =  _allSelected && (disabled||selected)
            } else {
                _allSelected = false
            }
            const _value = {
                value, 
                rowIndex: index, 

                selectable: !!selectionMode,
                disabled, 
                selected,

                subheader: false,

                tabIndex: selectionMode?0:undefined,
                onSelection: selectionMode?()=>{onSelectionRow(value, selected)}:undefined,

                className: classNames([
                    (typeof rowClassName==='function'?rowClassName(value):rowClassName),
                    (!!selectionMode&&'row-selectable'),
                    (disabled&&'disabled'),
                    (selected&&'selected')
                ])||undefined,
                style: rowStyle
            }
            return _value
        })
        setRows(groupRowsBy?groupRows(_rows):_rows)
        setAllSelected(_allSelected)
    }

    /** Agrupa las filas. */
    const groupRows = (_rows) => {
        const field = groupRowsBy||''
        const groups = _rows.reduce((carry, row) => {
            const value = row.value
            const key = value[field]
            if (!carry[key]) carry[key] = []
            carry[key].push(row)
            return carry
        }, {})
        const auxValues= []
        let counter = 1
        const keys = []
        if (groupRowsCustomOrder) {
            keys.push(...groupRowsCustomOrder)
            for (const key in groups) {
                if (!keys.includes(key)) keys.push(key)
            }
        } else {
            let _keys = Object.keys(groups)
            if (groupRowsOrder==='asc') _keys = _keys.sort()
            if (groupRowsOrder==='desc') _keys = _keys.sort((a, b) => b.localeCompare(a))
            keys.push(..._keys)
        }
        keys.forEach(key => {
            if (groups[key] && groups[key].length > 0) {
                auxValues.push(
                    {
                        value: {[field]: key}, 
                        rowIndex: -counter, 
                        selectable: false,
                        disabled: false, 
                        selected: false, 
                        subheader: true, 
                        subheaderLabel: (groupRowsLabels?groupRowsLabels[key]:key)||''
                    },
                    ...groups[key]
                )
                counter++
            }
        })
        return auxValues
    }
    /** Verifica si value esta seleccionado. */
    const isSelected = (selected, value) => {
        return JSON.stringify(selected) === JSON.stringify(value)
    }

    const clearFilters = () => setClear(true)
    const focusRow = (rowIndex) => {
        if (rowIndex>=0 && rowIndex<values?.length) {
            setFocusedRow(rowIndex)
        } else {
            setFocusedRow(-1)
        }
    }

    return (
        <div className={classNames(['datatable', className])} style={style}>
            <table className={classNames(['table table-style-1', tableClassName??'table-striped table-hover'])} style={tableStyle}>
                <DataTableHeader 
                    columns={columns}
                    selectionMode={selectionMode}
                    allSelected={allSelected}
                    onSort={onSort}
                    filterable={filterable}
                    onFilter={onFilter}
                    filterDelay={filterDelay}
                    onSelectionChange={onSelectionChange}
                    values={rows.filter(row => !row.disabled).map(row => row.value)}
                    initialFilters={initialFilters}
                    clear={clear}
                    setClear={setClear}
                />
                <DataTableBody 
                    columns={columns} 
                    rows={rows} 
                    emptyMessage={emptyMessage} 
                    selectionMode={selectionMode} 
                    focusedRow={focusedRow}
                    setFocusedRow={setFocusedRow}
                />
                <DataTableFooter columns={columns} />
            </table>
            {pagination && (
                <Pagination 
                    totalRecords={totalRecords} 
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onPage={onPage} 
                    page={page}
                    paginationReport={paginationReport}
                    className={paginationClassName} 
                    style={paginationStyle} 
                />
            )}
            <DataTableRef 
                ref={innerRef} 
                clearFilters={clearFilters} 
                focusRow={focusRow}
            />
        </div>
    )
}

/**
 * Header de la tabla de DataTable.
 * @ignore
 * @param {object} props 
 * @param {Array<object>} props.columns 
 * @param {string} props.selectionMode 
 * @param {boolean} props.allSelected 
 * @param {function} props.onSort 
 * @param {boolean} props.filterable 
 * @param {function} props.onFilter 
 * @param {number} props.filterDelay 
 * @param {function} props.onSelectionChange 
 * @param {Array<object>} props.values 
 * @param {object} props.initialFilters 
 * @param {React.MutableRefObject<object>} props.innerRef
 * @returns {JSX.Element} Retorna el componente TableHeader.
 */
function DataTableHeader({
    columns, selectionMode, 
    allSelected,
    onSort, 
    filterable, onFilter, filterDelay, 
    onSelectionChange,
    values,
    initialFilters,
    clear,
    setClear
}) {
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState(initialSort)
    const [initFilters, setInitFilters] = useState(null) // Solo cambia al montar los filtros

    useEffect(() => {
        const getFilters = () =>  {
            const _initFilters = columns.reduce((carry, column) => {
                if (column.filter && column.field) carry[column.field] = null
                return carry
            }, {})
            return {..._initFilters, ...initialFilters}
        }
        if (filterable) {
            const _filters = getFilters()
            setInitFilters(_filters)
        }
    }, []) 
    useEffect(() => {
        if (initFilters && typeof onFilter === 'function') {
            const delay = setTimeout(() => {
                onFilter({...filters}, clear)
                clearTimeout(delay)
            }, (filterDelay||0))
        }
    }, [filters]) 
    useEffect(() => {
        if (initFilters) setFilters(initFilters)
    }, [initFilters])
    useEffect(() => {
        if (((filterable && initFilters) || !filterable) && typeof onSort === 'function') {
            onSort(sort.field, sort.order, clear)
        }
    }, [sort]) 
    useEffect(() => {
        if (clear) {
            setFilters({...(initFilters||{}), ...initialFilters})
            setSort(initialSort)
        }
    }, [clear]) 
    useEffect(() => {
        if (clear) {
            if (JSON.stringify(initialSort)===JSON.stringify(sort)) {
                if (JSON.stringify({...(initFilters||{}), ...initialFilters})===JSON.stringify(filters)) {
                    setClear(false)
                }
            }
        }
    }, [sort, filters]) 

    const handleInput = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
    }
    const handleSort = (columnField) => {
        const getOrder = (currentOrder) => {
            return currentOrder === null ? 0 : ( currentOrder === 0 ? 1 : null )
        }
        const _order = sort.field === columnField ? getOrder(sort.order) : 0
        setSort({ field: columnField, order: _order })
    }
    const getSortIcon = (columnField) => {
        return sort.field && sort.field===columnField && sort.order!==null ? 
            (sort.order ? 'bi-arrow-up' : 'bi-arrow-down') 
            : 'bi-arrow-down-up'
    }
    const handleCheckAll = (checked) => {
        if (typeof onSelectionChange === 'function') {
            if (checked) {
                onSelectionChange(values)
            } else {
                onSelectionChange([])
            }
        }
    }
    const getHeaderProps = (column) => {
        const {className, headerClassName, sortable, field, style, headerStyle, alignHeader} = column
        const headerProps = {
            className: classNames([
                className, 
                headerClassName,
                (sortable&&'sortable'),
                (field===sort.field&&sort.order!==null&&'sorted-column'),
                (alignHeader?`header-${alignHeader}`:null)
            ])||undefined,
            style: style||headerStyle
                ?{...(style||{}), ...(headerStyle||{})}
                :undefined,
            onClick: sortable?()=>handleSort(column.field||''):undefined
        }
        return headerProps
    }
    const getHeader = () => {
        const _headers = []
        const _filterCells = []
        columns.forEach((column, index) => {
            const {header, headerTemplate, sortable, filter, filterElement, selector} = column
            const field = column.field||''
            const headerProps = getHeaderProps(column)
            if (selectionMode && selector) {
                const handleKeyUp = (e) => {
                    if (e.key === 'Enter') handleCheckAll(!allSelected)
                }
                _headers.push(
                    <Th role='columnheader' {...headerProps} key={index}>
                        <div className='columnheader-container'>{
                            selectionMode === 'multiple' && (
                                <div className='datarow-selector'>
                                    <input 
                                        checked={allSelected} 
                                        onChange={(e) => handleCheckAll(e.target.checked)} 
                                        onKeyUp={handleKeyUp}
                                        className='form-check-input' 
                                        type='checkbox'
                                    />
                                </div>
                            )
                        }</div>
                    </Th>
                )
            } else {
                const sortIcon = sortable ? <span className='sortable-icon'><i className={getSortIcon(field)}></i></span> : null
                const headerItem = headerTemplate 
                    ? typeof headerTemplate==='function' ? headerTemplate({sortIcon}) : headerTemplate
                    : <>
                        <span className='columnheader-content'>{header||''}</span>
                        {sortIcon}
                    </>
                _headers.push(
                    <Th role='columnheader' {...headerProps} key={index}>
                        <div className='columnheader-container'>
                            {headerItem}
                        </div>
                    </Th>
                )
                if (filterable) {
                    const value = filters[field]
                    const filterCallback = (value) => {
                        handleInput({ target: { value: value, name: field } })
                    } 
                    const filterItem = filter 
                        ? (filterElement 
                            ? (
                                typeof filterElement === 'function' 
                                    ? filterElement({value: filters[field], filterCallback})
                                    : filterElement
                            ) : (
                                <input
                                    className='form-control' 
                                    name={field} type='text' value={value ? value : ''} 
                                    onChange={handleInput}
                                />
                            )
                        ) : null
                    _filterCells.push(<Th key={index}>{filterItem}</Th>)
                }
            }
        })
        return {
            header: <Tr role='row'>{_headers}</Tr> ,
            rowFilter: filterable ? <Tr className='table-filters' role='row'>{_filterCells}</Tr> : null
        }
    }

    const { header, rowFilter } = getHeader()
    return <>
        <Thead>
            {header}
            {rowFilter}
        </Thead>
        
    </>
}

function DataTableBody({rows, columns, emptyMessage, selectionMode, focusedRow, setFocusedRow}) {
    const rowsRef = useRef([])

    useEffect(() => {
        if (selectionMode && focusedRow>=0 && focusedRow < rows.length) {
            rowsRef.current[focusedRow].focus()
        }
    }, [focusedRow])

    const handleKeyDownRow = (e, index) => {
        switch (e.key) {
            case "ArrowUp":{
                e.preventDefault()
                const prevRowIndex = getPrevSelectableRowIndex(index)
                prevRowIndex!==index && setFocusedRow(prevRowIndex)
                break
            }
            case "ArrowDown": {
                e.preventDefault()
                const nextRowIndex = getNextSelectableRowIndex(index)
                nextRowIndex!==index && setFocusedRow(nextRowIndex)
                break
            }
            default:
                break
        }
    }
    const getPrevSelectableRowIndex = (rowIndex) => {
        let _rowIndex = rowIndex
        if (rowIndex-1>=0) {
            for (let i = rowIndex-1; i >= 0; i--) {
                if (!rows[i].disabled) {
                    _rowIndex = i
                    break
                }
            }
        }
        return _rowIndex
    }
    const getNextSelectableRowIndex = (rowIndex) => {
        let _rowIndex = rowIndex
        if (rowIndex+1<rows.length) {
            for (let i = rowIndex+1; i < rows.length; i++) {
                if (!rows[i].disabled) {
                    _rowIndex = i
                    break
                }
            }
        }
        return _rowIndex
    }

    /** Construye las filas de la tabla. */
    const buildRows = (row) => {
        const {subheaderLabel, rowIndex, ...rowProps} = row
        return rowProps.subheader 
            ? <SubheaderRow 
                key={rowIndex} 
                colSpan={columns.length} 
                label={subheaderLabel}
                className={rowProps.className}
                style={rowProps.style}
            /> 
            : <DataTableRow 
                key={rowIndex} 
                {...rowProps}
                rowIndex={rowIndex}
                columns={columns}
                selectionMode={selectionMode}
                onKeyDown={selectionMode ? (e) => handleKeyDownRow(e, rowIndex) : undefined}
                ref={selectionMode ? (el) => {rowsRef.current[rowIndex] = el} : undefined}
            />
    }

    return (
        <Tbody onBlur={() => setFocusedRow(-1)}>{
            rows.length > 0 
                ? rows.map(buildRows) 
                : <Tr role='row'><Td colSpan={columns.length+1}>{emptyMessage || 'Sin resultados.'}</Td></Tr>
        }</Tbody>
    )
}

function DataTableFooter({columns}) {
    const [cells, setCells] = useState([])

    useEffect(() => {
        let hasFooter = false
        for (const key in columns) {
            if (columns[key].footer) {
                hasFooter = true
                break
            }
        }
        if (hasFooter) buildCells()
    }, [columns])

    const buildCells = () => {
        const _cells = []
        for (const key in columns) {
            const column = columns[key]
            const {footer, style, footerStyle, className, footerClassName} = column
            const propsCell = {
                style: style||footerStyle?{...(style||{}), ...(footerStyle||{})}:undefined,
                className: classNames([className, footerClassName])||undefined
            }
            _cells.push({
                value: footer||'',
                props: propsCell
            })
        }
        setCells(_cells)
    }

    return cells.length>0&&(
        <Tfoot>
            <Tr>
                {cells.map((cell, i) => (
                    <Th role='cell' key={i} {...cell.props}>
                        {cell.value}
                    </Th>
                ))}
            </Tr>
        </Tfoot>
    )
}

function SubheaderRow({colSpan, label, className, style}) {
    return (
        <Tr
            className={classNames(['row-subheader', className])}
            style={style}
        >
            <Td colSpan={colSpan}>{label}</Td>
        </Tr>
    )
}

/**
 * Fila del contenido de la tabla de DataTable.
 * @ignore
 * @param {object} props 
 * @param {Array<object>} props.columns 
 * @param {object} props.value 
 * @param {boolean} props.isSelected 
 * @param {function} props.onSelect 
 * @param {string} props.className 
 * @param {number} props.rowIndex 
 * @param {string} props.selectionMode 
 * @param {number} props.disabled 
 * @returns {JSX.Element} Retorna el componente DataRow.
 */
const DataTableRow = forwardRef(({ 
    columns, 
    value, 
    rowIndex, 
    disabled,
    selected,
    selectionMode,
    tabIndex,
    onSelection, 
    onKeyDown,
    className, 
    style, 
}, ref) => {

    const handleKeyUpRow = (e) => {
        if (e.key === 'Enter' && typeof onSelection === 'function') {
            onSelection()
        }
    }
    let selectOnRow = true
    const cells = columns.map((column, index) => {
        const {body, selector, style, bodyStyle, className, bodyClassName} = column
        if (selector) {
            selectOnRow = !!column.selectionOnClickRow
        }
        const field = column.field||''
        const _bodyClassName = typeof bodyClassName === 'function' ? bodyClassName(value, {rowIndex}) : bodyClassName
        const cellProps = {
            style: style||bodyStyle?{...(style||{}), ...(bodyStyle||{})}:undefined,
            className: classNames([className, _bodyClassName])||undefined
        }
        const bodyValue = body 
            ? (typeof body === 'function' ? body(value, {rowIndex}) : body )
            : (value[field] ?? '')
        const selectorInput = () => {
            let handleChange = () => {}
            let handleKeyUp = undefined
            if (typeof onSelection === 'function' && !selectOnRow) {
                handleChange = onSelection
                handleKeyUp = (e) => {
                    e.key==='Enter' && onSelection()
                }
            }
            return (
                <div className='datarow-selector'>
                    <input 
                        type={selectionMode==='multiple'?'checkbox':'radio'}
                        checked={selected}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        className='form-check-input' 
                        {...(disabled&&{disabled: true})}
                        {...(selectOnRow&&{tabIndex: -1})}
                    />
                </div>
            )
        }

        return (
            <Td role='cell' {...cellProps} key={index}>{ 
                selectionMode && selector 
                    ? selectorInput()
                    : bodyValue
            }</Td>
        )
    })
    const rowProps = selectOnRow&&!disabled ? {
        onClick: onSelection,
        onKeyDown,
        onKeyUp: handleKeyUpRow,
        tabIndex
    } : {}
    return (
        <Tr 
            ref={ref} 
            {...rowProps}
            className={className}
            style={style}
        >{cells}</Tr>
    )
})

/**
 * Paginación del DataTable.
 * @ignore
 * @param {object} props
 * @param {number} props.rowsPerPage
 * @param {Array<number>} props.rowsPerPageOptions
 * @param {number} props.totalRecords
 * @param {Function} props.onPage
 * @param {string} props.className
 * @param {React.CSSProperties} props.style 
 * @returns {JSX.Element} Retorna el componente Pagination.
 */
function Pagination({
    totalRecords=0, 
    rowsPerPage, 
    page, 
    rowsPerPageOptions, 
    onPage, 
    paginationReport,
    className, 
    style,
}) {
    const pageSize = parseInt(rowsPerPage)||10
    const currentPage = parseInt(page)||1
    const totalPages = pageSize>0 ? Math.ceil(totalRecords/pageSize)||1 : 1
    const [pages, setPages] = useState([])

    const pageSizeOptions = rowsPerPageOptions && (
        <div className='dropdown page-size-options'>
            <button className='btn dropdown-toggle' type='button' data-bs-toggle='dropdown'>
                <span>{pageSize}</span>
            </button>
            <ul className='dropdown-menu'>
                {rowsPerPageOptions.map(size => (
                    <li key={size} className='dropdown-item' onClick={() => handlePage(currentPage, size)}>{size}</li>
                ))}
            </ul>
        </div>
    )

    /** 
     * @param {number} page 
     * @param {number} pageSize
     */
    const handlePage = (page, size) => {
        onPage && onPage(page, size||pageSize)
    }

    const getPaginationData = () => {
        const data = []
        let ini = currentPage-2 <= 1 ? 1 : currentPage-2
        let end = currentPage+2 >= totalPages ? totalPages : currentPage+2
        if (end-ini<4) {
            ini = currentPage > totalPages-2 ? (totalPages-4>1?totalPages-4:1) : ini
            end = currentPage < 3 ? (totalPages<5?totalPages:5) : end
        }
        for (let i = ini; i <= end; i++) {
            data.push({
                number: i,
                active: currentPage === i,
                state: currentPage === i ? { active: true } : {},
                onClick: currentPage === i ? undefined : ()=>handlePage(i)
            })
        }
        setPages(data)

    }

    useEffect(()=>{
        getPaginationData()
    }, [totalRecords, rowsPerPage, page]) 

    const infoPagination = typeof paginationReport === 'string'
        ? (<div className='text-end py-1' style={{fontSize: '.75rem'}}>
            {paginationReport
                .replace('{totalRecords}', totalRecords)
                .replace('{totalPages}', totalPages)
                .replace('{rows}', rowsPerPage)
                .replace('{currentPage}', currentPage)}
        </div>)
        : (<div className='text-end py-1' style={{fontSize: '.75rem'}}>
            {`Nro. Registros: ${totalRecords} - Nro. Páginas: ${totalPages}`}
        </div>)

    return <>
        {infoPagination}
        <div className={classNames(['datatable-pagination', className])} style={style}>
            <button 
                className='btn btn-pagination'
                {...(currentPage>1 ? {onClick:()=>handlePage(1)} : {disabled: true})}
            >
                <span className='bi-chevron-double-left'></span>
            </button>
            <button 
                className='btn btn-pagination'
                {...(currentPage-1>0 ? {onClick: ()=>handlePage(currentPage-1)} : {disabled: true})}
            >
                <span className='bi-chevron-left'></span>
            </button>
            {
                currentPage > 0 
                    ? pages.map((page)=>(
                        <button 
                            key={page.number}
                            className={classNames(['btn btn-pagination', page.active&&'active'])}
                            onClick={page.onClick}
                        >
                            {page.number}
                        </button>
                    ))
                    : <button className='btn btn-pagination' disabled>0</button>
            }
            <button 
                className='btn btn-pagination'
                {...(currentPage+1<=totalPages ? {onClick: ()=>handlePage(currentPage+1)} : {disabled: true})}
            >
                <span className='bi-chevron-right'></span>
            </button>
            <button 
                className='btn btn-pagination'
                {...(currentPage<totalPages ? {onClick: ()=>handlePage(totalPages)} : {disabled: true})}
            >
                <span className='bi-chevron-double-right'></span>
            </button>
            {pageSizeOptions}
        </div>
    </>
}

const DataTableRef = forwardRef(({clearFilters, focusRow, ...props}, ref) => {
    useImperativeHandle(ref, () => ({

        clearFilters() {
            clearFilters()
        },
        /**
         * Establece el foco en una fila por indice.
         * @param {number} rowIndex Indice de fila.
         */
        focusRow(rowIndex) {
            focusRow(rowIndex)
        }
        // getElement() {
        //     return contentRef.current
        // },
    }));
    return null
})

const Thead = ({children, ...props}) => <thead {...props}>{children}</thead>
const Tbody = ({children, ...props}) => <tbody {...props}>{children}</tbody>
const Tfoot = ({children, ...props}) => <tfoot {...props}>{children}</tfoot>
const Tr = forwardRef(({children, ...props}, ref) => (
    <tr {...props} ref={ref}>{children}</tr>
))
const Th = ({children, ...props}) => <th {...props}>{children}</th>
const Td = ({children, ...props}) => <td {...props}>{children}</td>