import { forwardRef, useMemo } from 'react'
import { propFromName } from '../utils'
import { BaseOption, BaseSelect } from '../base/BaseSelect'
export const Select = forwardRef(
    ({ options, optionLabel = 'label', optionValue = 'value', placeholder = 'Seleccione', ...props }, ref) => {
        const items = useMemo(() => {
            /** Construye un objeto Item de una opción. */
            const buildItem = (option, index) => {
                const typeOption = typeof option
                let _item = {
                    id: index.toString(),
                    option,
                    index,
                    type: 'null',
                    label: '',
                    value: '',
                }
                if (option !== null) {
                    if (typeOption === 'object') {
                        let v = optionValue === '*' ? JSON.stringify(option) : propFromName(option, optionValue)
                        if (['object', 'undefined'].includes(typeof v)) {
                            v = JSON.stringify(v)
                        }
                        const l = (propFromName(option, optionLabel) ?? '').toString()
                        _item = {
                            ..._item,
                            type: typeOption,
                            label: l,
                            value: v,
                        }
                    } else if (
                        typeOption === 'string' ||
                        typeOption === 'number' ||
                        typeOption === 'bigint' ||
                        typeOption === 'boolean'
                    ) {
                        _item = {
                            ..._item,
                            type: typeOption,
                            label: option,
                            value: option.toString(),
                        }
                    }
                }
                return _item
            }
            return options.map(buildItem)
        }, [options, optionLabel, optionValue])
        return (
            <BaseSelect {...props} ref={ref}>
                {placeholder && typeof placeholder === 'string' && <BaseOption value=''>{placeholder}</BaseOption>}
                {items.map((option) => (
                    <BaseOption key={option.id} value={option.value}>
                        {option.label}
                    </BaseOption>
                ))}
            </BaseSelect>
        )
    },
)
