import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LogoViaTick } from 'assets/svg/viaTick'
import ImgAdmin from 'assets/img/icon-admin.png'
import ImgSol from 'assets/img/icon-solicitante.png'
import ImgBen from 'assets/img/icon-beneficiario.png'
import ImgAut from 'assets/img/icon-autoridad.png'
import ImgPre from 'assets/img/icon-presupuestos.png'
import ImgCaja from 'assets/img/icon-caja.png'
import ImgTes from 'assets/img/icon-viaticos.png'
import ImgDaf from 'assets/img/icon-daf.png'

function Home() {
    const {secciones} = useSelector(state => state.auth)
    
    return (
        <div className='home flex-1 d-flex justify-content-center align-items-center py-5'>
            <div className='row align-items-center justify-content-center' style={{margin: '0 5%'}}>
                <div className='d-none d-sm-flex col-3 col-lg-auto justify-content-center' style={{fill: '#102644'}}>{LogoViaTick}</div>
                <div className='col-lg'>
                    <div className='fst-italic text-center mb-3' style={{color: '#A60322', fontSize: '3.5rem'}}>
                        Sistema de Pasajes y Viáticos
                    </div>
                    <div>
                        <div className='flex-1 d-flex flex-wrap gap-5 justify-content-center align-items-center'>
                            {
                                (secciones['*']||secciones.administracion) && (
                                    <ButtonSection to='/administracion' label='Administración' img={ImgAdmin}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.daf) && (
                                    <ButtonSection to='/daf' label='DAF' img={ImgDaf}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.presupuestos) && (
                                    <ButtonSection to='/presupuestos' label='Presupuestos' img={ImgPre}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.viaticos) && (
                                    <ButtonSection to='/viaticos' label='Viáticos' img={ImgTes}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.caja) && (
                                    <ButtonSection to='/caja' label='Caja' img={ImgCaja}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.solicitante) && (
                                    <ButtonSection to='/solicitante' label='Solicitante' img={ImgSol}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.beneficiario) && (
                                    <ButtonSection to='/beneficiario' label='Beneficiario' img={ImgBen}/>
                                )
                            }
                            {
                                (secciones['*']||secciones.autoridad) && (
                                    <ButtonSection to='/autoridad' label='Autoridad' img={ImgAut}/>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ButtonSection({to, img, label}) {
    return (
        <Link 
            className='d-flex flex-column py-2'
            style={{
                height: '12rem', aspectRatio: 1, 
                fontSize: '1.25rem', 
                background: '#A60322', color: '#fff', 
                textDecoration: 'none',
            }} 
            to={to}
        >
            <div className='flex-1 d-flex justify-content-center align-items-center'>
                <img src={img} alt={label} style={{height: '7.5rem'}} />
            </div>
            <div className='fw-bold text-center'>{(label||'').toUpperCase()}</div>
        </Link>
    )
} 


export default Home