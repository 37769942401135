import './App.css'
import 'modules/modular/dist/css/styles.css'
import 'modules/modular/dist/css/theme.css'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAuth } from 'store/authSlice'
import { clearMessage } from 'store/messageSlice'
import Router from 'routes/Router'
import Toast from 'components/Toast'

let lockToast = false

function App() {
    const dispatch = useDispatch()
    const isLogged = useSelector(state => state.auth.isLogged)
    const message = useSelector(state => state.message)
    const toastRef = useRef(null)
    
    useEffect(() => {
        if ((message.existMessage && message.global) || message.status === 401) {
            let showMessage = true
            if (message.status === 401) {
                if (lockToast) {
                    showMessage = false
                } else {
                    lockToast = true
                }
            } else {
                lockToast = false
            }
            if (showMessage) {
                toastRef?.current?.show({
                    severity: message.severity,
                    title: message.title,
                    detail: message.detail,
                    sticky: message.sticky,
                    delay: message.delay
                })
            }
            dispatch(clearMessage())
        }
    }, [message])

    useEffect(() => {
        if (isLogged) {
            dispatch(getUserAuth())
        }
    }, [isLogged])

    return <>
        <Router isLogged={isLogged} />
        <Toast ref={toastRef} />
    </>
}

export default App;
