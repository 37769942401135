import Button, { ButtonIcon } from 'components/Button'
import CustomInput from 'components/CustomInput'

import DataTable from 'components/DataTable'
import InputCalendar from 'components/InputCalendar'
import { formatDate } from 'utils/utils'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Memo/Components/TableMemo */

// Opciones de las memorandums.
const actions = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {
        icon: 'bi-x-circle', title: 'Anular', action: 'cancel', 
        condition: (item) => (!item.fecha_anulacion&&verifyAccess('memoCancel'))
    },
    {icon: 'bi-file-earmark-pdf-fill', title: 'Ver PDf', action: 'view-file', condition: (item) => !!item.adjunto},
    {
        icon: 'bi-cloud-upload', title: 'Adjuntar Memo', action: 'upload', 
        condition: (item) => (!item.adjunto&&!item.fecha_anulacion&&verifyAccess('memoUpload'))
    },
    {
        icon: 'bi-printer', title: 'Imprimir', action: 'print', 
        condition: (item) => (!item.fecha_anulacion&&verifyAccess('memoPrint'))
    },
]
const currentYear = formatDate(new Date(), 'Y')

/**
 * Componente, Tabla de memorandums.
 * @param {object} props Propiedades del componente.
 * @param {React.Ref<any>} props.tableRef Referencia de la Tabla.
 * @param {Array<object>} props.memos Memorandums.
 * @param {object} props.pagination Información de la paginación de los memorandums.
 * @param {Function} props.handleOpenDialog Handler de acciones de los memorandums.
 * @param {object} props.filterHandlers Handlers de filtros de useHook para el DataTable del componente.
 * @param {Function} props.filterHandlers.handlePage Callback que se ejecuta cuando se cambia el número de página en la tabla.
 * @param {Function} props.filterHandlers.handleSort Callback que se ejecuta cuando se cambia el orden de una columna de la tabla.
 * @param {Function} props.filterHandlers.setFilters Handler de filtros de la tabla.
 * @returns {JSX.Element} Retorna el componente TableMemo.
 */
function TableMemo({ 
    tableRef, 
    memos, 
    pagination, 
    handleOpenDialog,
    filterHandlers: {handleFilter, handlePage, handleSort}
}) {
    const actionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div>{_options}</div>
    }
    const columns = [
        {
            field: 'numero_memo',
            header: 'Memo',
            filter: true,
            sortable: true,
            style: {width: '13rem'},
        },
        {
            field: 'descripcion',
            header: 'Descripción',
            filter: true,
            body: (rowData) => <div className='text-truncate'>{rowData.descripcion}</div>,
            style: {maxWidth: '50rem'}
        },
        {
            field: 'fecha_creacion',
            header: 'Fecha',
            sortable: true,
            filter: true,
            filterElement: (options) => (
                <InputCalendar 
                    value={options.value} onChange={(e) => options.filterCallback(e.value)} 
                    onlyYearPicker formatLabel='Y'
                    customInput={<CustomInput className='form-control fw-bold' startIcon={<div className='text-black'>Gestión</div>} placeholder={currentYear}/>} 
                />
            ),
            body: (rowData) => <div className='text-center'>{formatDate(rowData.fecha_creacion, 'd/m/Y')}</div>,
            style: {width: '11rem'},
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => (<div className='d-flex justify-content-center'>
                <Button startIcon='pi pi-filter-slash' onClick={handleClean}>Limpiar</Button>
            </div>),
            body: actionsBodyTemplate,
            style: { width: '11rem' },
        },
    ]

    const onFilter = (filterValues) => {
        const gestion = formatDate(filterValues.fecha_creacion, 'Y')||currentYear
        handleFilter({
            ...filterValues,
            fecha_creacion: [gestion + '-01-01 00:00:00', gestion + '-12-31 23:59:59']
        })
    }
    const handleClean = () => tableRef?.current?.clearFilters()

    return (
        <div>
            <DataTable 
                innerRef={tableRef}
                values={memos} 
                columns={columns} 
                filterable
                onFilter={onFilter}
                onSort={handleSort}
                pagination
                rowsPerPage={pagination.pageSize}
                totalRecords={pagination.totalRecords}
                page={pagination.currentPage}
                onPage={handlePage}
                initialFilters={{fecha_creacion: new Date()}}
            />
        </div>
    )
}
export default TableMemo