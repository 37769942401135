import { BaseTbody, BaseTd, BaseTr } from '../../base/BaseDataTable'
import DataTableRow from './DataTableRow'
import DataTableGroupRows from './DataTableGroupRows'
import { classNames } from '../../utils'
export function DataTableBody({
    headerRef,
    rowsRef,
    rowsIndexRef,
    columns,
    rows,
    selectionMode,
    selectionOn,
    onSelect,
    messageOnEmpty,
    groupRowsMode,
    groupRowsBy,
    subheaderTemplate,
    subheaderClassName,
    subheaderStyle,
    skeletonRows,
    searching,
    headerSticky,
}) {
    const handleKeyDown = (e, index) => {
        switch (e.key) {
            case 'ArrowUp': {
                e.preventDefault()
                index > 0 && rowsRef.current[index - 1].focus()
                break
            }
            case 'ArrowDown': {
                e.preventDefault()
                index < rowsRef.current.length - 1 && rowsRef.current[index + 1].focus()
                break
            }
            default:
                break
        }
    }
    return (
        <BaseTbody>
            {rows.length > 0 ? (
                rows.map((row) =>
                    groupRowsBy ? (
                        <DataTableGroupRows
                            key={row.id}
                            headerRef={headerRef}
                            rowsRef={rowsRef}
                            rowsIndexRef={rowsIndexRef}
                            columns={columns}
                            selectionMode={selectionMode}
                            selectionOn={selectionOn}
                            onKeyDown={handleKeyDown}
                            onSelect={onSelect}
                            groupRowsMode={groupRowsMode}
                            groupRowsBy={groupRowsBy}
                            subheaderTemplate={subheaderTemplate}
                            subheaderClassName={subheaderClassName}
                            subheaderStyle={subheaderStyle}
                            headerSticky={headerSticky}
                            {...row}
                        />
                    ) : (
                        <DataTableRow
                            key={row.id}
                            innerRef={(el) => {
                                if (el && row.selectable) {
                                    rowsRef.current[row.selectableIndex] = el
                                    rowsIndexRef.current[row.rowIndex] = row.selectableIndex
                                }
                            }}
                            {...row}
                            selectionMode={selectionMode}
                            selectionOn={selectionOn}
                            onKeyDown={handleKeyDown}
                            onSelect={onSelect}
                            columns={columns}
                            rowSpan={1}
                        />
                    ),
                )
            ) : (
                <BaseTr>
                    <BaseTd colSpan={columns.length}>
                        {messageOnEmpty || <div className='dtr-msg-on-empty'>Sin registros</div>}
                    </BaseTd>
                </BaseTr>
            )}
            {searching &&
                new Array(skeletonRows).fill(0).map((_, index) => (
                    <BaseTr key={index}>
                        {columns.map((col, i) => (
                            <BaseTd key={i}>
                                {col.selector && selectionMode ? (
                                    <div className='dtr-selector'>
                                        <div
                                            className={classNames(
                                                selectionMode === 'multiple'
                                                    ? 'dtr-check-skeleton'
                                                    : 'dtr-radio-skeleton',
                                                col.skeletonClassName,
                                            )}
                                            style={col.skeletonStyle}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={classNames('dtr-cell-skeleton', col.skeletonClassName)}
                                        style={col.skeletonStyle}
                                    />
                                )}
                            </BaseTd>
                        ))}
                    </BaseTr>
                ))}
        </BaseTbody>
    )
}
