/**
 * Concatena un conjunto de clases de estilos. Solo toma en cuenta strings y que no estén vacíos.
 * @param args Clases a concatenar. Ejm: classNames('foo', ['bar'], {active: true, disabled: false}).
 * @returns Retorna un string de clases de estilo concatenadas.
 */
export function classNames(...args) {
    const classList = args.reduce((carry, arg) => {
        if (Array.isArray(arg)) {
            carry.push(...arg)
        } else if (typeof arg === 'object') {
            carry?.push(...Object.keys(arg).filter((key) => !!arg[key]))
        } else {
            carry.push(arg)
        }
        return carry
    }, [])
    const cleanedClassList = classList.reduce((carry, string) => {
        if (typeof string === 'string') {
            const trimmed = string.trim()
            if (trimmed.length > 0 && !carry.includes(trimmed)) {
                carry.push(trimmed)
            }
        }
        return carry
    }, [])
    return cleanedClassList.join(' ') || undefined
}
