import InputFormik from 'components/formik/InputFormik'
import DropdownFormik from 'components/formik/DropdownFormik'
import { Form, Formik } from 'formik'
import { invitadoValidator } from 'utils/validators/validators'

/** @module Pages/Solicitud/Components/FormInvitado */

// Valores iniciales del formulario.
const initialValues = {
    nombre_beneficiario: '',
    apellido_beneficiario: '',
    cargo_obj: '',
    origen: '',
}

/**
 * Componente, Formulario de invitado.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {Array<object>} props.cargosInvitados Cargos de invitados disponibles para seleccionar.
 * @returns {JSX.Element} Retorna el componente FormInvitado.
 */
function FormInvitado({formRef, onSubmit, cargosInvitados}) {
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={invitadoValidator}
        >{() => (
            <Form>
                <InputFormik name='nombre_beneficiario' label='Nombres'/>
                <InputFormik name='apellido_beneficiario' label='Apellidos'/>
                <DropdownFormik
                    name='cargo_obj' 
                    options={cargosInvitados}
                    optionLabel='cargo'
                    optionValue='cargo_id'
                    valueAsOption
                    label='Cargo'
                />
                <InputFormik 
                    name='origen' 
                    label='Lugar de origen' 
                    placeholder='País, Ciudad u otro'
                    info='Lugar desde donde llega el invitado.'
                />
            </Form>
        )}</Formik>
    )
}
export default FormInvitado