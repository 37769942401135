import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { logout } from 'store/authSlice'
import { verifyAccess } from 'utils/rbac.util'
import { classNames } from 'utils/utils'
import logoUser from 'assets/img/user-red.png'
import { 
    adminLinks, 
    autoridadLinks, 
    solicitanteLinks, 
    beneficiarioLinks, 
    cajaLinks, 
    dafLinks, 
    presupuestosLinks, 
    viaticosLinks 
} from 'data/links'
import { setSection } from 'store/systemSlice'
import umssLogoV from 'assets/img/logo-umss-s.png'
import dticLogo from 'assets/img/logo-dtic-s.png'
import Sidebar from './Sidebar'
import Button from './Button'
import Avatar from './Avatar'

const availableSections = [
    'administracion',
    'autoridad',
    'solicitante',
    'beneficiario',
    'caja',
    'daf',
    'presupuestos',
    'viaticos',
]

export default function NavBar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {secciones} = useSelector(state => state.auth)
    const {sectionKey, section} = useSelector(state => state.system)
    const {userInfo} = useSelector(state => state.auth)
    const location = useLocation()
    const [links, setLinks] = useState([])
    const [showSidebar, setShowSidebar] = useState(false)
    // Links permitidos según permisos y/o roles.
    const navbarLinks = useMemo(() => {
        return links.reduce((carry, link) => {
            /** Verifica si se tiene los permisos y/o roles requeridos por el link. */
            const verify = (link) => {
                return verifyAccess(link.permissions||'', link.conditionPermissions||'and')
            }
            const items = link.items?.filter(item => verify(item))
            if (items===undefined || (items!==undefined&&items.length>0)) {
                if (verify(link)) {
                    carry.push(link)
                }
            }
            return carry
        }, [])
    }, [links])

    const buildPath = (path='') => {
        const sectionPath = sectionKey?`/${sectionKey}`:''
        return `${sectionPath}${path===''||path.charAt(0)==='/'?path:`/${path}`}`
    }
    /** Función que devuelve la plantilla personalizada para los items del Sidebar. */
    const customItemTemplate = (item, props) => {
        return (
            <NavLink 
                to={buildPath(item.url)||'#'}
                {...((item.end===undefined||item.end===true)&&{end: true})}
                className={({isActive}) => classNames([props.className, (isActive&&!item.items&&'active')])} 
                onClick={() => props.onClick()}
            >
                {item.icon
                    ? (
                        <span className={props.iconClassName}>
                            {typeof item.icon === 'string'
                                ? <span className={item.icon}></span>
                                : item.icon
                            }
                        </span>
                    )
                    :null
                }
                <span className={props.labelClassName}>{item.label}</span>
                {item.items
                    ? (
                        <span className={props.submenuIconClassName}>
                            <span className={props.expanded?"bi-chevron-up":"bi-chevron-down"}></span>
                        </span>
                    ) : null
                }
            </NavLink>
        )
    }
    /** Convierte un objeto Link a un objeto SidebarItem. */
    const linkToSidebarItem = (link) => {
        const {path, items, ...restLink} = link
        return {
            ...restLink,
            url: path, 
            ...(!!items&&{
                items: items.reduce((carry, _link)=> {
                    _link.label && carry.push(linkToSidebarItem(_link))
                    return carry
                }, [])
            }),
            itemTemplate: customItemTemplate
        }
    }

    // Items del Sidebar.
    const sidebarItems = useMemo(() => navbarLinks.map(link => linkToSidebarItem(link)), [navbarLinks])
    
    // Muestra los links correspondientes a la sección
    useEffect(() => {
        let _links = []
        if (sectionKey === 'administracion') {
            _links = adminLinks
        } else if (sectionKey === 'viaticos') {
            _links = viaticosLinks
        } else if (sectionKey === 'caja') {
            _links = cajaLinks
        } else if (sectionKey === 'presupuestos') {
            _links = presupuestosLinks
        } else if (sectionKey === 'solicitante') {
            _links = solicitanteLinks
        } else if (sectionKey === 'beneficiario') {
            _links = beneficiarioLinks
        } else if (sectionKey === 'autoridad') {
            _links = autoridadLinks
        } else if (sectionKey === 'daf') {
            _links = dafLinks
        }
        setLinks(_links)
    }, [sectionKey])
    // Cambia el valor de la sección cuando detecta el cambio de sección en la url
    useEffect(() => {
        const {pathname} = location
        let newSection = ''
        const _secciones = Object.keys(secciones)
        for (const _section of availableSections) {
            if (pathname.includes(`/${_section}`)) {
                if (_secciones.includes('*')||_secciones.includes(_section)) {
                    newSection = _section
                    break
                }
            }
        }
        if (!newSection && _secciones.length===1) newSection = _secciones[0]==='*'?(pathname==='/'?'':'administracion'):_secciones[0]
        if (newSection !== section) dispatch(setSection(newSection))
    }, [location, secciones])

    const handleLogout = () => {
        dispatch(logout())
        navigate('/')
    }

    const buildNavLink = (link, index) => (
        <NavLink
            key={index} 
            to={buildPath(link.path)||'#'} 
            {...((link.end===undefined||link.end===true)&&{end: true})}
            className='nav-link'
        >
            {link.label}
        </NavLink>
    )
    const buildNavDropdown = (link, index) => (
        <NavDropdown 
            key={index}
            title={link.label} 
            className={link.items?.find(item => item.path&&buildPath(item.path)===location.pathname)?'active':undefined}
        >{
            link.items?.map((item, _index) => (
                item.divider
                    ? <NavDropdown.Divider key={_index} />
                    : <NavDropdown.Item as='button' className='p-0' key={_index}>
                        <NavLink
                            to={buildPath(item.path)||'#'} 
                            {...((item.end===undefined||item.end===true)&&{end: true})}
                            className='dropdown-item'
                        >
                            {item.label}
                        </NavLink>
                    </NavDropdown.Item>
            ))
        }</NavDropdown>
    )
    const buildNavbarLink = (link, index) => {
        return link.items ? buildNavDropdown(link, index) : buildNavLink(link, index)
    }

    const redirectToSettings = () => {
        navigate(buildPath('usuario/configuracion'))
    }
    const handleGoToSections = () => navigate('/')
    const toggleSidebar = () => setShowSidebar(!showSidebar)
    
    return <>
        <Navbar variant='dark' style={{paddingTop: '.75rem', paddingBottom: '.75rem'}}>
            <div className='d-flex align-items-center w-100'>
                <div className='d-none d-lg-block'>
                    <img src={umssLogoV} alt='Logo UMSS' className='me-3' style={{height: '3rem'}} />
                    <img src={dticLogo} alt='Logo DTIC' style={{height: '3rem'}} />
                </div>
                <Button
                    className="btn-icon-lg d-block d-lg-none" 
                    variant="text-secondary"
                    startIcon="bi-list" 
                    onClick={() => toggleSidebar()} 
                />
                <Nav className='content-links ms-auto d-none d-lg-flex'>
                    {navbarLinks.map(buildNavbarLink)}
                </Nav>
                <Nav 
                    className='content-avatar'
                    style={navbarLinks.length > 0 ? {borderLeft: '1px solid #fff'} : {borderBottomLeftRadius: '1.25rem', borderTopLeftRadius: '1.25rem'}}
                >
                    <div 
                        className='nav-link'
                    >
                        {userInfo.apellido_1 ? `${userInfo.apellido_1} ${userInfo.nombre_1}` : userInfo.username}
                    </div>
                    <div className='dropdown'>
                        <div data-bs-toggle='dropdown'>
                            <Avatar 
                                size='lg'
                                className='cursor-pointer'
                                imgProps={{
                                    alt: userInfo.username,
                                    src: logoUser
                                }}
                            />
                        </div>
                        <ul className='dropdown-menu dropdown-menu-end user-select-none'>
                            <li className='dropdown-item cursor-pointer' onClick={redirectToSettings}>
                                <span className='pi pi-cog me-1'></span> Configuración
                            </li>
                            {
                                (Object.values(secciones).length>1||secciones['*']) 
                                && (
                                    <li className='dropdown-item cursor-pointer' onClick={handleGoToSections}>
                                        <span className='bi-grid me-1'></span> Secciones
                                    </li>
                                )
                            }
                            <li className='dropdown-item cursor-pointer' onClick={handleLogout}>
                                <span className='pi pi-sign-out me-1'></span> Cerrar sesión
                            </li>
                        </ul>
                    </div>
                </Nav>
            </div>
        </Navbar>
        <Sidebar 
            show={showSidebar} 
            closeSidebar={toggleSidebar} 
            items={sidebarItems}
            header={<div className="text-center fs-5">Sistema de Viáticos</div>}
            theme='umss'
        />
    </>
}