import Input from 'components/Input'
import ScrollUp from 'components/ScrollUp'
import TitlePage from 'components/TitlePage'
import InputLayout from 'components/layouts/InputLayout'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ApiService from 'services/api.service'

/** @module Pages/Unidad/EstructuraProgramatica */

/**
 * Página, Estructura programática.
 * @returns {JSX.Element} Retorna el componente EstructuraProgramatica.
 */
function EstructuraProgramatica() {
    const [estructura, setEstructura] = useState([])
    const [filters, setFilters] = useState({ da: '', ue: '', u: '' })

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.getEstructuraProgramatica()
            if (response.status === 200) setEstructura(response.data)
        }
        loadData()
    }, [])

    const handleChange = (e) => setFilters({ ...filters, [e.target.name]: e.target.value })
    const getEstructura = () => {
        const f = {
            u: filters.u.toLowerCase().trim(),
            ue: filters.ue.toLowerCase().trim(),
            da: filters.da.toLowerCase().trim(),
        }

        return estructura.reduce((carry, da) => {
            if (f.da.length === 0 || [da.descripcion.toLowerCase(), da.codigo].some((el) => el.includes(f.da))) {
                const ues = da.unidadEjecutoras.reduce((ueCarry, ue) => {
                    if (
                        f.ue.length === 0 ||
                        [ue.descripcion.toLowerCase(), ue.codigo].some((el) => el.includes(f.ue))
                    ) {
                        const unidades = ue.unidades.reduce((uCarry, u) => {
                            if (
                                f.u.length === 0 ||
                                [u.descripcion.toLowerCase(), u.programa, u.proyecto, u.actividad].some((el) =>
                                    el.includes(f.u),
                                )
                            ) {
                                uCarry.push(
                                    <tr
                                        key={`${u}-${da.codigo}.${ue.codigo}.${u.programa}.${u.proyecto}.${u.actividad}`}
                                    >
                                        <td>{da.codigo}</td>
                                        <td>{ue.codigo}</td>
                                        <td>{u.programa}</td>
                                        <td>
                                            {parseInt(u.proyecto) > 0 && u.proyecto.length > 3 ? (
                                                <>
                                                    <span style={{ color: '#888888' }}>
                                                        {u.proyecto.substring(0, 5)}
                                                    </span>
                                                    {u.proyecto.substring(5, 8)}
                                                    <span style={{ color: '#888888' }}>{u.proyecto.substring(9)}</span>
                                                </>
                                            ) : (
                                                <span style={{ color: '#888888' }}>{u.proyecto}</span>
                                            )}
                                        </td>
                                        <td>
                                            {parseInt(u.actividad) > 0 ? (
                                                u.actividad
                                            ) : (
                                                <span style={{ color: '#888888' }}>{u.actividad}</span>
                                            )}
                                        </td>
                                        <td className='text-start'>{u.descripcion}</td>
                                    </tr>,
                                )
                            }
                            return uCarry
                        }, [])
                        ueCarry.push(
                            <tr key={`ue-${da.codigo}.${ue.codigo}`}>
                                <th className='border-end-0'></th>
                                <th className='border-0'>{ue.codigo}</th>
                                <th className='text-start border-start-0' colSpan={4}>
                                    {ue.descripcion}
                                </th>
                            </tr>,
                            ...unidades,
                        )
                    }
                    return ueCarry
                }, [])
                carry.push(
                    <tr key={`da-${da.codigo}`}>
                        <th className='border-end-0'>{da.codigo}</th>
                        <th className='text-start border-start-0' colSpan={5}>
                            {da.descripcion}
                        </th>
                    </tr>,
                    ...ues,
                )
            }
            return carry
        }, [])
    }

    return (
        <>
            <TitlePage title='Estructura Programática' />
            <div className='content align-items-center' style={{ backgroundColor: '#efefef' }}>
                <Card className='w-xl-75 p-5'>
                    <div>
                        <div className='border rounded position-relative p-3 pt-4 mb-3'>
                            <div className='position-absolute bg-white px-2' style={{ top: '-.65rem' }}>
                                Filtros
                            </div>
                            <div className='row g-3'>
                                <InputLayout className='col-md-4 col-xxl-auto' label='DA:' inline>
                                    <Input name='da' value={filters.da} onChange={handleChange} />
                                </InputLayout>
                                <InputLayout className='col-md-4 col-xxl-auto' label='UE:' inline>
                                    <Input name='ue' value={filters.ue} onChange={handleChange} />
                                </InputLayout>
                                <InputLayout className='col-md-4 col-xxl-auto' label='Unidad:' inline>
                                    <Input name='u' value={filters.u} onChange={handleChange} />
                                </InputLayout>
                            </div>
                        </div>
                        <table
                            className='table table-sm table-bordered text-center'
                            style={{ borderColor: '#000', verticalAlign: 'middle' }}
                        >
                            <thead style={{ backgroundColor: '#f0f0f0' }}>
                                <tr>
                                    <th colSpan={6}>ESTRUCTURA PROGRAMÁTICA UMSS</th>
                                </tr>
                                <tr>
                                    <th style={{ width: '5rem' }}>DA</th>
                                    <th style={{ width: '5rem' }}>UE</th>
                                    <th style={{ width: '5rem' }}>PROG.</th>
                                    <th style={{ width: '10rem' }}>PROY.</th>
                                    <th style={{ width: '5rem' }}>ACTV.</th>
                                    <th>DETALLE</th>
                                </tr>
                            </thead>
                            <tbody>{getEstructura()}</tbody>
                        </table>
                    </div>
                </Card>
            </div>
            <ScrollUp id='main-container' />
        </>
    )
}
export default EstructuraProgramatica
