import { BaseDataTableInput, BaseTd } from '../../base/BaseDataTable'
export default function ColumnFilterCell({ field, filterElement, filterPlaceholder, value, onChangeFilter }) {
    const handleChangeFilter = ({ target }) => {
        onChangeFilter(target.name, target.value)
    }
    const filterCallback = (value) => {
        onChangeFilter(field, value)
    }
    return (
        <BaseTd>
            {filterElement ? (
                typeof filterElement === 'function' ? (
                    filterElement({ value, filterCallback })
                ) : (
                    filterElement
                )
            ) : (
                <BaseDataTableInput
                    name={field}
                    type='text'
                    placeholder={filterPlaceholder}
                    value={value}
                    onChange={handleChangeFilter}
                    className='dtf-input'
                />
            )}
        </BaseTd>
    )
}
