export default function ArrowDownUp(props) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.5 14V2M4.5 2L1 6M4.5 2L8 6M11.5 2V14M11.5 14L8 10M11.5 14L15 10'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
