import { forwardRef } from 'react'
import { Form } from 'react-bootstrap'
export const BaseSelect = forwardRef(({ children, ...props }, ref) => {
    return (
        <Form.Select {...props} ref={ref}>
            {children}
        </Form.Select>
    )
})
BaseSelect.displayName = 'BaseSelect'
export function BaseOption({ children, ...props }) {
    return <option {...props}>{children}</option>
}
