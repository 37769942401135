import Button, { ButtonIcon } from 'components/Button'
import CustomInput from 'components/CustomInput'
import DataTable from 'components/DataTable'
import InputCalendar from 'components/InputCalendar'
import InputDropdown from 'components/InputDropdown'
import { formatDate } from 'utils/utils'
import { estadosObj } from 'data/const'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Papeleta/Components/TablePapeletas */

const estados = [
    { value: '', label: 'Todos' },
    { value: 'N', label: 'Registrado'},
    { value: 'P', label: 'Pagado'},
    { value: 'E', label: 'Espera'},
    { value: 'T', label: 'Reembolsado'},
]
// Opciones de las papeletas de la sección de Tesorería.
const actionsViaticos = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {
        icon: 'bi-printer', title: 'Imprimir', action: 'print', 
        condition: (item) => (!item.fecha_anulacion&&verifyAccess('papeletaPrint'))
    },
    {icon: 'bi-file-earmark-pdf-fill', title: 'Ver PDf', action: 'view-file', condition: (item) => !!item.adjunto},
    {
        icon: 'bi-cloud-upload', title: 'Adjuntar Viático', action: 'upload', 
        condition: (item) => (!item.adjunto&&!item.fecha_anulacion&&verifyAccess('papeletaUpload'))
    },
    {
        icon: 'bi-x-circle', title: 'Anular', action: 'cancel', 
        condition: (item) => (!item.fecha_anulacion&&['N','P'].includes(item.estado)&&verifyAccess('papeletaCancel'))
    },
]
// Opciones de las papeletas de la sección de Caja.
const actionsCaja = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {
        icon: 'bi-arrow-repeat', title: 'Devolución', action: 'devolucion', 
        condition: (item) => (['P'].includes(item.estado)&&verifyAccess('papeletaDevolucion'))
    },
]
const currentYear = formatDate(new Date(), 'Y')

/**
 * Componente, Tabla de papeletas.
 * @param {object} props Propiedades del componente.
 * @param {React.Ref<any>} props.tableRef Referencia de la Tabla.
 * @param {Array<object>} props.papeletas Papeletas.
 * @param {object} props.pagination Información de la paginación de los papeletas.
 * @param {Function} props.handleAction Handler de acciones de papeletas.
 * @param {string} props.sectionKey Código de la sección actual.
 * @param {object} props.filterHandlers Handlers de filtros de useHook para el DataTable del componente.
 * @param {Function} props.filterHandlers.handlePage Callback que se ejecuta cuando se cambia el número de página en la tabla.
 * @param {Function} props.filterHandlers.handleSort Callback que se ejecuta cuando se cambia el orden de una columna de la tabla.
 * @param {Function} props.filterHandlers.setFilters Handler de filtros de la tabla.
 * @returns {JSX.Element} Retorna el componente TablePapeletas.
 */
function TablePapeletas({
    tableRef, papeletas, pagination, handleAction, sectionKey, 
    initialFilters,
    filterHandlers: {handleFilter, handleSort, handlePage}
}) {
    const optionsBodyTemplate = (rowData) => {
        const actions = sectionKey==='viaticos'?actionsViaticos:(sectionKey==='caja'?actionsCaja: [])
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleAction(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className='table-options'>{_options}</div>
    }
    const columns = [
        {
            field: 'numero_papeleta',
            header: 'Nro.',
            filter: true,
            sortable: true,
            style: { width: '8rem' },
        },
        {
            field: 'doc_autorizacion',
            header: 'Doc. Autorización',
            filter: true,
            sortable: true,
            style: { width: '15rem' },
        },
        {
            field: 'nombre_beneficiario',
            header: 'Beneficiario',
            filter: true,
            sortable: true,
        },
        {
            field: 'lugar_viaje',
            header: 'Lugar viaje',
            filter: true,
            sortable: true,
        },
        {
            field: 'estado',
            header: 'Estado',
            body: rowData => estadosObj[rowData.estado],
            filter: true,
            filterElement: (options) => (
                <InputDropdown 
                    options={estados} 
                    value={options.value} 
                    onChange={(e) => options.filterCallback(e.value)} 
                />
            ),
            sortable: true,
            style: { width: '9rem' },
        },
        {
            field: 'fecha_registro',
            header: 'Fecha',
            body: rowData => <div className='text-center'>{formatDate(rowData.fecha_registro, 'd/m/Y')}</div>,
            sortable: true,
            filter: true, 
            filterElement: (options) => (
                <InputCalendar 
                    value={options.value} onChange={(e) => options.filterCallback(e.value)} 
                    onlyYearPicker formatLabel='Y'
                    customInput={<CustomInput className='form-control fw-bold' startIcon={<div className='text-black'>Gestión</div>} placeholder={currentYear}/>} 
                />
            ),
            style: { width: '11rem' },
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => (
                <div className='d-flex justify-content-center'>
                    <Button startIcon='pi pi-filter-slash' onClick={handleClean} title='Limpiar filtros'>Limpiar</Button>
                </div>
            ),
            body: optionsBodyTemplate,
            style: { width: '11rem' },
        },
    ]

    const onFilter = (filterValues) => {
        const values = { ...filterValues}
        const gestion = formatDate(values.fecha_registro, 'Y')||currentYear
        handleFilter({
            ...values,
            fecha_registro: [gestion + '-01-01 00:00:00', gestion + '-12-31 23:59:59']
        })
    }
    const handleClean = () => tableRef?.current?.clearFilters()

    return (
        <div>
            <DataTable 
                innerRef={tableRef}
                values={papeletas} 
                columns={columns} 
                filterable
                onFilter={onFilter}
                onSort={handleSort}
                pagination
                rowsPerPage={pagination.pageSize}
                rowsPerPageOptions={[10, 25, 50]}
                totalRecords={pagination.totalRecords}
                page={pagination.currentPage}
                onPage={handlePage}
                initialFilters={initialFilters}
            />
        </div>
    )
}
export default TablePapeletas