import { InputFormik } from 'components/formik/formikFormComponents'
import { Form, Formik } from 'formik'
import { Button } from 'modules/modular/Button'
import { useEffect, useRef, useState } from 'react'
import { ReembolsoService } from 'services/reembolso.service'

/** @module Pages/Reembolso/Components/FormFondoRotatorio */

// const currentDate = formatDate(new Date(), 'm-d')

/**
 * Componente, Formulario de registro de fondo rotatorio.
 * @param {object} props Propiedades del componente.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {Function} props.onCancel Callback que se ejecuta al cancelar la modificación del formulario.
 * @returns {JSX.Element} Retorna el componente FormFondoRotatorio.
 */
function FormFondoRotatorio({ onSubmit, onCancel }) {
    const formRef = useRef(null)
    const [cierre, setCierre] = useState(false)

    useEffect(() => {
        const loadData = async () => {
            const { status, data } = await ReembolsoService.fondoRotatorio.get()
            if (status === 200) {
                formRef?.current?.resetForm({
                    values: { monto: data.monto },
                })
                setCierre(data.cierre)
            }
        }
        loadData()
    }, [])

    return (
        <Formik innerRef={formRef} initialValues={{ monto: '' }} onSubmit={onSubmit}>
            {() => (
                <Form>
                    {cierre && (
                        <div className='alert alert-danger py-2'>
                            El Fondo Rotatorio no se puede editar porque ya se ha realizado el cierre del fondo de esta
                            gestión.
                        </div>
                    )}
                    <InputFormik name='monto' type='currency' disabled={cierre} />
                    <div className='text-end'>
                        <Button variant={cierre ? 'blue' : 'outline-blue'} startIcon={<span className='bi-x-lg' />} onClick={onCancel}>
                            {cierre ? 'Cerrar' : 'Cancelar'}
                        </Button>
                        {!cierre && (
                            <Button
                                type='submit'
                                variant='blue'
                                startIcon={<span className='bi-check2' />}
                                className='ms-2'
                            >
                                Guardar
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}
export default FormFondoRotatorio
