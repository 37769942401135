import PDF from './pdf'
import store from 'store'
import { formatDate, numberName } from 'utils/utils'
import { formatCurrency } from 'utils/utils'
import { fuentesFinanciamiento } from 'data/const'

const configPapeletaCm = {
    unit: 'cm',
    pageMargins: {
        left: 1,
        // top: .75,
        top: .35,
        right: 1.4,
        bottom: 0
    },
    numero_papeleta: {
        fontSize: 12,
        marginLeft: 16.3
    },
    fecha_papeleta_columns: {
        width_column_1: 13,
        width_column_2: 1.95,
        width_column_3: 3.4
    },
    fecha_papeleta: {
        marginBottom: .8
    },
    mes_gestion_papeleta: {
        alignment: 'justify'
    },
    pasaje_viaticos_columns: {
        width_column_1: 14.9,
        width_column_2: 1.2,
        width_column_3: 2.7
    },
    beneficiario: {
        marginLeft: 4.5, 
        marginBottom: .425,
        marginTop: .5
    },
    lugar_viaje: {
        marginLeft: 3.25, 
        marginBottom: .425
    },
    fechas_viaje_columns: {
        width_column_1: 6,
        width_column_2: 6.5,
        width_column_3: 3.3
    },
    fechas_viaje: {
        marginBottom: .45
    },
    pasaje_viaticos: {
        marginBottom: 1.25
    },
    fecha_salida: {
        marginLeft: 2.75
    },
    fecha_llegada: {
        marginLeft: 2.25
    },
    dias_viaje: {
        marginLeft: .25
    },
    doc_autorizacion: {
        marginLeft: 4, 
        marginBottom: .425
    },
    actividad_cuenta: {
        width_column_1: 8,
        width_column_2: 6.8
    },
    codigo_actividad: {
        marginLeft: 3.25
    },
    actividad: {
        marginLeft: 1,
        noWrap: true,
        style: ['text-center', 'font-roboto-c']
    },
    codigo_cuenta: {
        marginLeft: 2.4
    },
    cuenta: {
        noWrap: true, 
        marginLeft: 1.8, 
        style: ['text-center', 'font-roboto-c']
    },
    num_terrestre: {
        marginTop: .9
    },
    num_aereo: {
        marginBottom: .9
    },
    pasaje_terrestre: {
        marginTop: .9,
        style: ['text-end']
    },
    pasaje_aereo: {
        style: ['text-end']
    },
    subtotal_pasajes: {
        marginBottom: .5,
        style: ['text-end']
    },
    viaticos: {
        style: ['text-end']
    },
    estipendios: {
        style: ['text-end']
    },
    rciva: {
        style: ['text-end']
    },
    subtotal_viaticos: {
        marginBottom: .5,
        style: ['text-end']
    },
    total: {
        style: ['text-end']
    },
    firmas: {
        style: ['text-center'],
        marginLeft: 1,
        marginRight: 1,
        marginBottom: .35,
    },
    label_monto: {
        marginLeft: 4.1,
        marginBottom: .5,
    },
    interesado: {
        marginLeft: 15.5,
    },
    nota: {
        marginLeft: .5,
        marginBottom: .8 
    },
    glosa: {
        marginLeft: 1.5
    }

}
class PapeletaPdf extends PDF {

    papeleta(papeleta, config=null) {
        const configPapeleta = config||configPapeletaCm
        const getProps = (props) => {
            return this.getProps(props, configPapeleta.unit)
        }
        const blankLayout = {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0
        }
        const docAutorizacion = papeleta.doc_autorizacion.toUpperCase()
        const content = [
            { 
                text: `${papeleta.numero_papeleta}/${formatDate(papeleta.fecha_registro, 'y')}`,
                ...getProps(configPapeleta.numero_papeleta)
            },
            {
                layout: blankLayout, 
                table: {
                    widths: [
                        this.cmToPts(configPapeleta.fecha_papeleta_columns.width_column_1), 
                        this.cmToPts(configPapeleta.fecha_papeleta_columns.width_column_2),
                        this.cmToPts(configPapeleta.fecha_papeleta_columns.width_column_3)
                    ],
                    body: [[
                        { text: '' },
                        { 
                            text: formatDate(papeleta.fecha_registro, 'd'),
                            ...getProps(configPapeleta.dia_papeleta)
                        },
                        { 
                            text: formatDate(papeleta.fecha_registro, 'F Y').replace(' ', ' de '),
                            ...getProps(configPapeleta.mes_gestion_papeleta)
                        }
                    ]]
                },
                ...getProps(configPapeleta.fecha_papeleta)
            },
            {
                layout: blankLayout, 
                table: {
                    widths: [
                        this.cmToPts(configPapeleta.pasaje_viaticos_columns.width_column_1), 
                        this.cmToPts(configPapeleta.pasaje_viaticos_columns.width_column_2),
                        this.cmToPts(configPapeleta.pasaje_viaticos_columns.width_column_3)
                    ],
                    body: [[
                        [
                            { 
                                text: papeleta.beneficiario || papeleta.nombre_beneficiario, 
                                ...getProps(configPapeleta.beneficiario)
                            },
                            { 
                                text: papeleta.lugar_viaje.toUpperCase(), 
                                ...getProps(configPapeleta.lugar_viaje)
                            },
                            {
                                layout: blankLayout,
                                table: {
                                    widths: [
                                        this.cmToPts(configPapeleta.fechas_viaje_columns.width_column_1), 
                                        this.cmToPts(configPapeleta.fechas_viaje_columns.width_column_2),
                                        this.cmToPts(configPapeleta.fechas_viaje_columns.width_column_3)
                                    ],
                                    body: [[
                                        { 
                                            text: formatDate(papeleta.fecha_salida, 'd-M-y'), 
                                            ...getProps(configPapeleta.fecha_salida)
                                        },
                                        { 
                                            text: formatDate(papeleta.fecha_llegada, 'd-M-y'), 
                                            ...getProps(configPapeleta.fecha_llegada)
                                        },
                                        { 
                                            text: papeleta.dias, 
                                            ...getProps(configPapeleta.dias_viaje)
                                        }
                                    ]]
                                },
                                ...getProps(configPapeleta.fechas_viaje)
                            },
                            { 
                                text: docAutorizacion, 
                                ...getProps(configPapeleta.doc_autorizacion)
                            },
                            {
                                layout: blankLayout,
                                table: {
                                    widths: [
                                        this.cmToPts(configPapeleta.actividad_cuenta.width_column_1), 
                                        this.cmToPts(configPapeleta.actividad_cuenta.width_column_2)
                                    ],
                                    body: [
                                        [
                                            { 
                                                text: papeleta.codigo_actividad,
                                                ...getProps(configPapeleta.codigo_actividad)
                                            },
                                            { 
                                                text: [
                                                    papeleta.codigo_cargo_cta, '   ', 
                                                    papeleta.recursos, '  ', 
                                                    papeleta.da_abreviacion_cargo_cta, '   ', 
                                                    papeleta.da_codigo_cargo_cta, ' ', 
                                                ], 
                                                ...getProps(configPapeleta.codigo_cuenta)
                                            },
                                        ],
                                        [
                                            { 
                                                text: papeleta.actividad.substring(0,35), 
                                                ...getProps(configPapeleta.actividad)
                                            },
                                            { 
                                                text: papeleta.cargo_cta.substring(0,30), 
                                                ...getProps(configPapeleta.cuenta)
                                            },
                                        ]
                                    ]
                                },
                                marginBottom: 12
                            },
                        ],
                        [
                            { 
                                text: papeleta.num_terrestre||0,
                                ...getProps(configPapeleta.num_terrestre)
                            },
                            { 
                                text: papeleta.num_aereo||0, 
                                ...getProps(configPapeleta.num_aereo)
                            },
                            { text: papeleta.dias_viaticos },
                            { text: '0'},
                            { text: papeleta.rciva==='0.00'?'N':'S'},
                        ],
                        [
                            { 
                                text: formatCurrency(papeleta.pasaje_terrestre),
                                ...getProps(configPapeleta.pasaje_terrestre)
                            },
                            { 
                                text: formatCurrency(papeleta.pasaje_aereo), 
                                ...getProps(configPapeleta.pasaje_aereo)
                            },
                            { 
                                text: formatCurrency(papeleta.subtotal_pasajes),
                                ...getProps(configPapeleta.subtotal_pasajes)
                            },
                            { 
                                text: formatCurrency(papeleta.viaticos),
                                ...getProps(configPapeleta.viaticos)
                            },
                            { 
                                text: '0.00',
                                ...getProps(configPapeleta.estipendios)
                            },
                            { 
                                text: formatCurrency(papeleta.rciva),
                                ...getProps(configPapeleta.rciva)
                            },
                            { 
                                text: formatCurrency(papeleta.subtotal_viaticos),
                                ...getProps(configPapeleta.subtotal_viaticos)
                            },
                            { 
                                text: formatCurrency(papeleta.total),
                                ...getProps(configPapeleta.total)
                            }
                        ]
                    ]]
                },
                ...getProps(configPapeleta.pasaje_viaticos)
            },
            {
                columns: [
                    { 
                        text: [
                            papeleta.encargado_viaticos.toUpperCase(), '\n', 
                            { text: 'Encargado Viáticos', style: ['font-manuscript-w', 'fw-bold'] }
                        ], 
                        width: '50%'
                    },
                    { 
                        text: [
                            papeleta.jefe_tesoreria.toUpperCase(), '\n', 
                            { text: 'Jefe Depto. Tesorería', style: ['font-manuscript-w', 'fw-bold'] }
                        ], 
                        width: '50%'
                    },
                ],
                ...getProps(configPapeleta.firmas)
            },
            { 
                text: [
                    numberName(papeleta.total).toLocaleUpperCase(), ' 00/100', 
                    { text: ' Bolivianos.', style: ['font-manuscript-w', 'fw-bold'] }
                ], 
                ...getProps(configPapeleta.label_monto)
            },
            { 
                text: 'INTERESADO', 
                ...getProps(configPapeleta.interesado)
            },
            { 
                text: ['NOTA: ', papeleta.nota],
                ...getProps(configPapeleta.nota)
            },
            { 
                text: papeleta.glosa, 
                ...getProps(configPapeleta.glosa)
            },
        ]
        const pageMargins = configPapeleta.unit==='cm'
            ? [
                this.cmToPts(configPapeleta.pageMargins.left),
                this.cmToPts(configPapeleta.pageMargins.top),
                this.cmToPts(configPapeleta.pageMargins.right),
                this.cmToPts(configPapeleta.pageMargins.bottom),
            ]
            : [
                configPapeleta.pageMargins.left,
                configPapeleta.pageMargins.top,
                configPapeleta.pageMargins.right,
                configPapeleta.pageMargins.bottom
            ]
        this.generatePDF(
            content, this.ruler, null, 
            { 
                pageMargins,
                pageSize: {
                    height: 396,
                    width: 612
                },
                open: 'to_print'
            }
        )
    }

    listaEmitidos(data, filters) {
        const {
            userInfo: { fuente_financiamiento },
        } = store.getState().auth
        this.unidad = fuentesFinanciamiento[fuente_financiamiento]
        this.seccion = fuente_financiamiento === 'DAF' ? 'Tesorería y C. Publico' : ''
        const title = filters?.anulado ? 'LISTA DE VIÁTICOS ANULADOS' : 'LISTA DE VIÁTICOS EMITIDOS'
        const getContent = (papeletas) => {
            let total = 0
            const tableHeader = [
                { text: 'No. Viati.', style: ['fw-bold'], noWrap: true }, 
                { text: 'Fecha Emi.', style: ['fw-bold'] }, 
                { text: 'Nombre', style: ['text-center', 'fw-bold'] },
                { text: 'No. Dias', style: ['fw-bold'], noWrap: true }, 
                { text: 'Monto', style: ['fw-bold'] }, 
                { text: 'Lugar', style: ['text-center', 'fw-bold'] }, 
                { text: 'Autorización', style: ['text-center', 'fw-bold'] }, 
                { text: 'Observ.', style: ['fw-bold'] }
            ]
            const getRows = () => {
                return papeletas.map(papeleta => {
                    total += 1*papeleta.total
                    return [
                        { text: papeleta.numero_papeleta, style: ['text-center'] },
                        { text: formatDate(papeleta.fecha_registro, 'd-M-Y'), noWrap: true },
                        { text: papeleta.beneficiario.replace(/\s+/g, ' ').trim() },
                        { text: papeleta.dias, style: ['text-center'] },
                        { text: formatCurrency(papeleta.total), style: ['text-end'] },
                        { text: papeleta.lugar_viaje },
                        { text: papeleta.doc_autorizacion },
                        { text: papeleta.estado === 'A' ? 'Anulado' : '' }
                    ]
                })
            }
            return [
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            tableHeader,
                            ...getRows(),
                            [
                                '', '', 
                                { text: 'TOTAL', style: ['text-center', 'fw-bold'] }, 
                                '', 
                                { text: formatCurrency(total), style: ['text-end', 'fw-bold'] }, 
                                '', '', ''
                            ]
                        ]
                    },
                    layout: {
                        hLineWidth: (i, node) => {
                            return i < 2 || i >= node.table.body.length-1 ? 1 : 0
                        },
                        vLineWidth: () => 0,
                    },
                    style: ['font-roboto-c']
                }
            ]
        }
        const getFilters = () => {
            const _filters = []
            const {actividad, cuenta} = filters
            const fechaIni = formatDate(filters.fechaInicio, 'd-M-Y')
            const fechaFin = formatDate(filters.fechaFin || new Date(), 'd-M-Y')
            let fechaText = 'Fecha: '
            if (fechaIni) {
                fechaText += fechaIni
                if (fechaFin && fechaIni !== fechaFin) {
                    fechaText += ' al ' + fechaFin 
                }
            } else {
                fechaText = ''
            }
            if (fechaText) _filters.push({ text: fechaText, style: 'm-left-page' })
            if (actividad?.id) {
                _filters.push({
                    text: ['Actividad: ', filters.actividad.codigo_actividad, ' - ', filters.actividad.nombre_corto||filters.actividad.descripcion],
                    style: 'm-left-page'
                })
            }
            if (cuenta?.id) {
                _filters.push({
                    text: ['Cuenta: ', filters.cuenta.codigo_cuenta, ' - ', filters.cuenta.nombre_corto||filters.cuenta.descripcion],
                    style: 'm-left-page'
                })
            }
            return _filters
        }
        this.generateReport(title, getContent(data), {filters: getFilters()})
    }

}

export default PapeletaPdf