import { forwardRef } from 'react'
import { classNames } from '../utils'
import { Checkbox } from '../Checkbox'
export const BaseTable = ({ children, ...props }) => <table {...props} className={classNames('table', props.className)}>{children}</table>
export const BaseThead = forwardRef(({ children, ...props }, ref) => (
    <thead {...props} ref={ref}>
        {children}
    </thead>
))
BaseThead.displayName = 'BaseThead'
export const BaseTbody = ({ children, ...props }) => <tbody {...props}>{children}</tbody>
export const BaseTfoot = ({ children, ...props }) => <tfoot {...props}>{children}</tfoot>
export const BaseTr = forwardRef(({ children, ...props }, ref) => (
    <tr {...props} ref={ref}>
        {children}
    </tr>
))
BaseTr.displayName = 'BaseTr'
export const BaseTh = ({ children, ...props }) => <th {...props}>{children}</th>
export const BaseTd = ({ children, ...props }) => <td {...props}>{children}</td>
export const BaseDataTableCheckbox = forwardRef(({ checked, ...props }, ref) => {
    return <Checkbox {...props} value={checked} ref={ref} />
})
BaseDataTableCheckbox.displayName = 'BaseDataTableCheckbox'
export const BaseDataTableRadio = forwardRef((props, ref) => {
    return <input {...props} type='radio' className={classNames(['radio', props.className])} ref={ref} />
})
BaseDataTableRadio.displayName = 'BaseDataTableRadio'
export const BaseDataTableInput = forwardRef((props, ref) => {
    return <input {...props} className={classNames(['input', props.className])} ref={ref} />
})
BaseDataTableInput.displayName = 'BaseDataTableInput'
