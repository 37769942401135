import imgAdmin from 'assets/img/icon-admin.png'
import imgSol from 'assets/img/icon-solicitante.png'
import imgBen from 'assets/img/icon-beneficiario.png'
import imgAut from 'assets/img/icon-autoridad.png'
import imgPre from 'assets/img/icon-presupuestos.png'
import imgCaja from 'assets/img/icon-caja.png'
import imgTes from 'assets/img/icon-viaticos.png'
import imgDaf from 'assets/img/icon-daf.png'
import Welcome from 'components/Welcome'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import ApiService from 'services/api.service'
import { setHttpMessage } from 'store/messageSlice'
import Button from 'components/Button'

const icons = {
    administracion: imgAdmin,
    autoridad: imgAut,
    solicitante: imgSol,
    beneficiario: imgBen,
    caja: imgCaja,
    daf: imgDaf,
    presupuestos: imgPre,
    viaticos: imgTes,
}

const manualesKeys = {
    solicitante: 'manualSolicitante',
    beneficiario: 'manualBeneficiario',
    daf: 'manualDAF', 
    presupuestos: 'manualPresupuesto', 
    viaticos: 'manualViaticos', 
    caja: 'manualCaja', 
    administracion: 'manualAdministracion'
}
const manualNames = {
    solicitante: 'Manual de Solicitante',
    beneficiario: 'Manual de Beneficiario', 
    daf: 'Manual de DAF', 
    presupuestos: 'Manual de Presupuestos', 
    viaticos: 'Manual de Viáticos', 
    caja: 'Manual de Caja', 
    administracion: 'Manual de Administración'
}

export default function HomeSection() {
    const dispatch = useDispatch()
    const {section, sectionKey} = useSelector(state => state.system)
    const [manuales, setManuales] = useState({})

    useEffect(() => {
        const loadManuales = async () => {
            const _manualesKeys = Object.values(manualesKeys)
            const response = await ApiService.config.search({keys: _manualesKeys})
            if (response.status===200) {
                const {data} = response
                setManuales(_manualesKeys.reduce((carry, key) => {
                    carry[key] = data[key]?.valor||''
                    return carry
                }, {}))
            }
        }
        loadManuales()
    }, [])

    const openManual = async () => {
        const filename = manuales[manualesKeys[sectionKey]]
        if (filename) {
            const response = await ApiService.getLocalFile(filename)
            if (response.status===200) {
                const base64File = response.data.file
                fetch(`data:application/pdf;base64,${base64File}`).then(async (res) => {
                    const fileBlob = await res.blob()
                    const fileURL = URL.createObjectURL(fileBlob)
                    window.open(fileURL)
                })
            } else {
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            }
        }
    }

    return <>
        <Welcome 
            img={icons[sectionKey]} 
            section={section}
            sectionKey={sectionKey}
            hasManual={!!manuales[manualesKeys[sectionKey]]}
            openManual={openManual}
        />
        {!!manuales[manualesKeys[sectionKey]] && <div className='text-center mb-3'>
            <Button 
                startIcon={<span className='bi-journal-text fs-4 me-3'/>}
                onClick={openManual}
                variant='outline-blue' 
                className='fw-bold'
                style={{width: '12.5rem'}} 
            >
                {manualNames[sectionKey]}
            </Button>
        </div>}
    </>
}