import { forwardRef } from 'react'
import { classNames } from '../utils'
import { BaseButton } from '../base/BaseButton'
export const Button = forwardRef(({ startIcon, endIcon, loading, children, className, ...props }, ref) => {
    const getIcon = (pos) => {
        let icon = pos === 'end' ? endIcon : startIcon
        if (loading && pos === 'start') {
            icon = <span className='mod-button-spinner' />
        }
        return <span className={'mod-button-icon-' + pos}>{icon}</span>
    }
    const btnStartIcon = (loading || startIcon) && getIcon('start')
    const btnEndIcon = endIcon && getIcon('end')
    if (loading) props.disabled = !!loading
    return (
        <BaseButton type='button' {...props} className={classNames('modular-button', className)} ref={ref}>
            <div className='mod-button-content'>
                {btnStartIcon}
                {children}
                {btnEndIcon}
            </div>
        </BaseButton>
    )
})
Button.displayName = 'Button'
