import { Form, Formik } from 'formik'
import { CheckboxFormik, InputFormik, DropdownFormik } from 'components/formik/formikFormComponents'
import { reglamentoValidators } from 'utils/validators/validators'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Reglamento/Components/FormReglamento */

/**
 * Componente, Formulario de registro/edición de Reglamento.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selected Reglamento seleccionada.
 * @returns {JSX.Element} Retorna el componente FormReglamento.
 */
function FormReglamento({formRef, onSubmit, selected}) {
    const initialValues = selected ? selected : { 
        tipo: '', 
        numero: '',
        descripcion: '',
        activo: ''
    }
    const tipos = [
        'RESOLUCIÓN RECTORAL',
        'OTRO'
    ]

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={reglamentoValidators}
            onSubmit={onSubmit}
        >{() => (
            <Form>
                <DropdownFormik name='tipo' options={tipos} />
                <InputFormik name='numero' label='Número' />
                <InputFormik name='descripcion' label='Descripción' />
                {verifyAccess('reglamentoActive') && <CheckboxFormik name='activo' style={{fontSize: '1.25rem'}}/>}
                {!selected && <div className='alert alert-primary mb-0'>
                    Este Reglamento se convertirá en el activo una vez se registre y el anterior quedara inactivo. 
                    Después de realizar el registro debe configurar las categorías y escalas de viáticos del nuevo reglamento.
                </div>}
            </Form>
        )}</Formik>
    )
}
export default FormReglamento