import { Card } from 'react-bootstrap'
import FormSolicitud from './components/FormSolicitud'
import TitlePage from 'components/TitlePage'
import { useParams } from 'react-router-dom'

/** @module Pages/Solicitud/SolicitudCreate */

/**
 * Página, Registro/Edición de solicitud.
 * @returns {JSX.Element} Retorna el componente SolicitudCreate.
 */
function SolicitudCreate() {
    const { id } = useParams()

    return (
        <>
            <TitlePage title={id ? 'Editar Solicitud' : 'Registro de Solicitud'} />
            <div className='content align-items-center' style={{ backgroundColor: '#efefef' }}>
                <Card className='w-xl-75' style={{ minHeight: '100%' }}>
                    <FormSolicitud />
                </Card>
            </div>
        </>
    )
}
export default SolicitudCreate
