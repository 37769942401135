import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import FormReglamento from './components/FormReglamento'
import ViewReglamento from './components/ViewReglamento'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import DataTable from 'components/DataTable'
import InputCheckbox from 'components/InputCheckbox'
import { initialPagination } from 'data/const'

/** @module Pages/Reglamento/ReglamentoIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {
    contain: ['tipo', 'numero', 'descripcion'], bool: ['activo']
}
// Opciones de reglamentos.
const actions = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update', condition: (item) => item.activo},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete', condition: (item) => item.activo},
]

/**
 * Página de administración de Reglamento.
 * @returns {JSX.Element} Retorna el componente ReglamentoIndex.
 */
function ReglamentoIndex() {
    const dispatch = useDispatch()
    const [reglamentos, setReglamentos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, handleFilter, handleSort, handlePage} = useFilters({initialFilters: null, filterOptions})
    const [selectedReglamento, setSelectedReglamento] = useState(null)
    const [show, setShow] = useState({form: false, delete: false, info: false})
    const [submitting, setSubmitting] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    
    useEffect(()=>{
        const getReglamentos = async () =>{
            const response = await ApiService.reglamento.index(filters)
            if (response.status === 200) {
                setReglamentos(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) getReglamentos()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData=null) => {
        setSelectedReglamento(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeInfoDialog = () => setShow({...show, info: false})

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values) => {
        setSubmitting(true)
        const response = await ApiService.reglamento.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            reloadFilters()
            closeFormDialog()
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values) => {
        if (selectedReglamento) {
            setSubmitting(true)
            const response = await ApiService.reglamento.update(values, selectedReglamento.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedReglamento) {
            const response = await ApiService.reglamento.delete(selectedReglamento.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDeleteDialog()
            }
        }
    }
    const handleClean = () => tableRef?.current?.clearFilters() 
    const clearSelected = () => setSelectedReglamento(null)

    const actionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div>{_options}</div>
    }
    const columns = [
        {
            field: 'tipo',
            header: 'Tipo',
            filter: true,
            sortable: true,
        },
        {
            field: 'numero',
            header: 'Número',
            filter: true,
            sortable: true,
            style: { width: '8rem' },
        },
        {
            field: 'descripcion',
            header: 'Descripción',
            sortable: true,
            filter: true,
            bodyClassName: 'text-truncate'
        },
        {
            field: 'activo',
            header: 'Activo',
            filter: true,
            sortable: true,
            filterElement: (options) => (<div className='d-flex justify-content-center'>
                <InputCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} triState style={{fontSize: '1.5rem'}} />
            </div>),
            body: (rowData) => rowData.activo ? 'Si' : 'No',
            style: { width: '8rem', textAlign: 'center' },
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => (<div className='d-flex justify-content-center'>
                <Button startIcon='pi pi-filter-slash' onClick={handleClean} >Limpiar</Button>
            </div>),
            body: actionsBodyTemplate,
            style: { width: '10rem' },
        },
    ]
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit} 
            loading={submitting}
        >Guardar</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>
    const options = <>
        <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create')} >Nuevo</Button>
    </>

    return <>
        <TitlePage title='Lista de Reglamentos' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={reglamentos} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                />
                <Dialog
                    show={show.form}   
                    header={ selectedReglamento ? 'Editar Reglamento' : 'Registrar Reglamento' } 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{ width: '30rem' }}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormReglamento 
                        formRef={formRef} selected={selectedReglamento}
                        onSubmit={ selectedReglamento ? handleUpdate : handleCreate }  
                    />
                </Dialog>
                <ConfirmDialog
                    show={show.delete}
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={handleDelete}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> el Reglamento "{selectedReglamento?.numero}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información del Reglamento' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '25rem' }}
                    onExited={clearSelected}
                >
                    {selectedReglamento ? <ViewReglamento selectedReglamento={selectedReglamento} /> : null}
                </Dialog>
            </div>
        </div>
    </>
}
export default ReglamentoIndex