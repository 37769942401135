export const ITEMTOKEN = 'user'
export const CURRENCIES = ['$us', 'Bs']
export const numEscalas = 5
export const initialPagination = {
    previousPage: null,
    currentPage: 1,
    nextPage: null,
    totalPages: 1,
    pageSize: 10,
    totalRecords: 0
}
export const categorias = [
    'Categoría 1',
    'Categoría 2',
    'Categoría 3',
    'Categoría 4',
    'Categoría 5',
    'Categoría 6',
]
export const estadosViaticos = [
    {label: 'Todos', value: ''},
    {label: 'Nuevos', value: 'N'},
    {label: 'Pagados', value: 'P'},
    {label: 'Anulados', value: 'A'},
    {label: 'Revertidos', value: 'R'},
]

export const recursosObj = {
    I: 'Institucionales',
    P: 'Propios',
    M: 'Gremios'
}
export const recursos = [
    {label: recursosObj['I'], value: 'I'},
    {label: recursosObj['P'], value: 'P'},
    {label: recursosObj['M'], value: 'M'},
]
export const recursosFilter = [
    {label: 'Todos', value: ''},
    {label: recursosObj['I'], value: 'I'},
    {label: recursosObj['P'], value: 'P'},
    {label: recursosObj['M'], value: 'M'},
]
export const sections = {
    administracion: 'Administración',
    solicitante: 'Solicitante',
    beneficiario: 'Beneficiario',
    autoridad: 'Autoridad',
    presupuestos: 'Presupuestos',
    viaticos: 'Viáticos',
    daf: 'DAF',
    caja: 'Caja',
}
export const estadosObj = {
    '': 'Todos',
    N: 'Registrado',
    P: 'Pagado',
    E: 'Espera',
    T: 'Reembolsado',
    R: 'Revertido',
    A: 'Anulado'
}

export const fuentesFinanciamiento = {
    AGR: 'Facultad de Ciencias Agrícolas y Pecuarias',
    ARQ: 'Facultad de Arquitectura',
    BQM: 'Facultad de Bioquímica y Farmacia',
    DAF: 'Dirección Administrativa y Financiera',
    DER: 'Facultad de Ciencias Jurídicas',
    DICYT: 'Dir. de Investigación Científica y Tecnológica',
    ECO: 'Facultad de Ciencias Económicas',
    ENF: 'Facultad de Enfermeria',
    EUPG: 'Escuela Universitaria de Postgrado',
    FCS: 'Facultad de Ciencias Sociales',
    FDR: 'Facultad de Desarrollo Rural y Territorial',
    FPVA: 'Facultad Politécnica del Valle Alto',
    HUM: 'Facultad de Humanidades y Cs. de la Educación',
    MED: 'Facultad de Medicina',
    ODT: 'Facultad de Odontología',
    TEC: 'Facultad de Ciencias y Tecnología',
    VET: 'Facultad de Ciencias Veterinarias y Zootecnia',
}
