import { formatDate } from 'utils/utils'
import PDF from './pdf'
import logoUmss from 'assets/img/logoumss.png'

class SolicitudPDF extends PDF {

    solicitudHeader(config={}) {
        const logoWidth = 45
        return {
            fontSize: 14,
            margin: [42, 21, 42, 0],
            table: {
                headerRows: 1,
                widths: [logoWidth, '*', logoWidth],
                body: [
                    [
                        {
                            image: logoUmss,
                            width: logoWidth,
                        },
                        {
                            text: [
                                { text: `UNIVERSIDAD MAYOR DE SAN SIMÓN`, style: ['fw-bold'] }, 
                                // '\n',
                                // { text: 'FACULTAD DE HUMANIDADES Y CIENCIAS DE LA EDUCACIÓN', noWrap: true}, 
                                // '\n',
                                // { text: 'CONDECORADA CON EL "CONDOR DE LOS ANDES" ACREDITADA  AL MERCOSUR', fontSize: 10}
                            ],
                            style: ['text-center']
                        },
                        {
                            text: `Logo2`,
                        },
                    ],
                ],
            },
            layout: 'noBorders',
        }

    }

    solicitudAdjunto(solicitud) {
        const getDestinos = () => {
            const groups = {}
            solicitud.solicitudBeneficiarios.forEach((sb) => {
                const _key = sb.lugar_viaje
                if (groups[_key]) groups[_key].push(sb)
                else groups[_key] = [sb]
            })
            const destinos = []
            for (const key in groups) {
                destinos.push({
                    lugar_viaje: key,
                    beneficiarios: groups[key]
                })
            }
            return destinos
        }
        const destinos = getDestinos(solicitud)
        const destino = destinos.length > 0 ? destinos[0] : {}
        const content = [
            { text: 'Solicitud de viaje', fontSize: 14, style: ['text-center'], marginBottom: 10 },
            { text: ['Lugar, ', formatDate(new Date(), 'd M Y').replace(/ /g, ' de ')], style: ['text-center'], marginBottom: 20 },
            { text: 'I. Destino:', style: ['header'] },
            { text: destino.lugar_viaje, fontSize: 12, marginLeft: 21 },
            { text: 'II. Beneficiarios:', style: ['header'] },
            {
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            { text: 'Nombre completo', style: ['tableHeader'] }, { text: 'CI', style: ['tableHeader'] }, { text: 'Cargo', style: ['tableHeader'] }, 
                            { text: 'Desde (dd/mm)', style: ['tableHeader'] }, { text: 'Hrs.', style: ['tableHeader'] }, 
                            { text: 'Hasta (dd/mm)', style: ['tableHeader'] }, { text: 'Hrs.', style: ['tableHeader'] }
                        ],
                        ...destino.beneficiarios.map(b => [
                            { text: [b.nombre_beneficiario, ' ', b.apellido_beneficiario] },
                            { text: b.documento },
                            { text: b.funcion||b.cargo },
                            { text: formatDate(b.fecha_salida, 'd/m') },
                            { text: formatDate(b.fecha_salida, 'H:i') },
                            { text: formatDate(b.fecha_llegada, 'd/m') },
                            { text: formatDate(b.fecha_llegada, 'H:i') },
                        ])
                    ]
                }
            },
            { text: 'III. Justificación del viaje:', style: ['header'] },
            { text: 'a) Objetivo del viaje:', style: ['header'], marginLeft: 10 },
            {
                table: {
                    widths: ['*'],
                    body: [[{ text: solicitud.objetivo_viaje, margin: 5 }]]
                },
                marginLeft: 10
            },
            { text: 'b) Actividad(es) a realizar:', style: ['header'], marginLeft: 10 },
            {
                table: {
                    widths: ['*'],
                    body: [[{ text: solicitud.actividades, margin: 5 }]]
                },
                marginLeft: 10
            },
            { text: 'c) Resultado(s):', style: ['header'], marginLeft: 10 },
            {
                table: {
                    widths: ['*'],
                    body: [[{ text: solicitud.resultado, margin: 5 }]]
                },
                marginLeft: 10
            },
            {
                columns: [
                    { 
                        width: '*', 
                        text: ['---------------------------------------------------', '\n', 'Firma'], 
                        style: ['text-center'] 
                    },
                    { 
                        width: '*', 
                        text: ['---------------------------------------------------', '\n', 'Firma'], 
                        style: ['text-center'] 
                    },
                ],
                marginTop: 70
            }
        ]
        const header = this.solicitudHeader()
        const footer = null
        const config = { pageMargins: [42, 120, 42, 0] }
        this.generatePDF(content, header, footer, config)
    }

    informe(informe) {
        const {fecha_salida, fecha_llegada, lugar_viaje, fecha_registro,
            lugar_emision, antecedentes, objetivo_viaje, desarrollo, 
            conclusiones, adjuntos, jefe, beneficiario} = informe
        const nombresBeneficiario = beneficiario.nombre_beneficiario+' '+beneficiario.apellido_beneficiario
        const content = [
            {text: 'Informe de viaje', style: ['text-center', 'fw-bold'], marginBottom: 5, fontSize: 14},
            {
                text: [lugar_emision,', ', formatDate(fecha_registro, 'd F Y').replace(/ /g, ' de ')], 
                style: ['text-center'], marginBottom: 20
            },
            {text: [{text: 'Nombres y Apellidos: ', style: ['fw-bold']}, nombresBeneficiario], style: ['my-1']},
            {columns: [
                {
                    width: '*',
                    text: [
                        {text: 'Fecha de salida: ', style: ['fw-bold']}, formatDate(fecha_salida, 'd/m/Y H:i')
                    ]
                },
                {
                    width: '*',
                    text: [
                        {text: 'Fecha de retorno: ', style: ['fw-bold']}, formatDate(fecha_llegada, 'd/m/Y H:i')
                    ]
                },
            ], style: ['my-1']},
            {text: [{text: 'Lugar de viaje: ', style: ['fw-bold']}, lugar_viaje], style: ['my-1'], marginBottom: 20},
            {text: 'Antecedentes:', style: ['header']},
            {table:{
                widths: ['*'],
                heights: [50],
                body: [[antecedentes]]
            }},
            {text: 'Objetivo:', style: ['header']},
            {table:{
                widths: ['*'],
                heights: [50],
                body: [[objetivo_viaje]]
            }},
            {text: 'Desarrollo:', style: ['header']},
            {table:{
                widths: ['*'],
                heights: [50],
                body: [[desarrollo]]
            }},
            {text: 'Conclusiones y resultados u objetivos específicos alcanzados:', style: ['header']},
            {table:{
                widths: ['*'],
                heights: [50],
                body: [[conclusiones]]
            }},
            {text: 'Documentos que adjunta:', style: ['header']},
            {table:{
                widths: ['*'],
                heights: [50],
                body: [[adjuntos]]
            }},
            {columns: [
                {
                    width: '*',
                    text: [
                        'Firma Beneficiario', '\n', nombresBeneficiario
                    ],
                    style: ['text-center', 'fw-bold']
                },
                {
                    width: '*',
                    text: [
                        'Firma Jefe Inmediato', '\n', jefe
                    ],
                    style: ['text-center', 'fw-bold']
                },
            ], marginTop: 75}
        ]
        this.generatePDF(content, null, null, {pageMargins: [42, 42, 42, 42]})
    }

}

export default SolicitudPDF