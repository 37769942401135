import { BaseTd, BaseTfoot, BaseTr } from '../base/BaseDataTable'
export default function DataTableFooter({ columnFooters, values }) {
    return (
        <BaseTfoot>
            <BaseTr>
                {columnFooters.map(({ id, className, style, footer, colSpan }) => (
                    <BaseTd key={id} colSpan={colSpan} className={className} style={style}>
                        {typeof footer === 'function' ? footer({ values }) : footer}
                    </BaseTd>
                ))}
            </BaseTr>
        </BaseTfoot>
    )
}
