import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import FormDirAdtva from './components/FormDirAdtva'
import ViewDirAdtva from './components/ViewDirAdtva'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import DataTable from 'components/DataTable'
import { initialPagination } from 'data/const'

/** @module Pages/DirAdtva/DirAdtvaIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {contain: ['codigo', 'descripcion', 'abreviacion']}
// Opciones de las direcciones administrativas.
const actions = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]

/**
 * Página de administración de Direcciones Administrativas.
 * @returns {JSX.Element} Retorna el componente DirAdtvaIndex.
 */
function DirAdtvaIndex() {
    const dispatch = useDispatch()
    const [dirAdtvas, setDirAdtvas] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, handleFilter, handlePage, handleSort} = useFilters({initialFilters: null, filterOptions})
    const [selectedDirAdtva, setSelectedDirAdtva] = useState(null)
    const [show, setShow] = useState({form: false, delete: false, info: false})
    const [submitting, setSubmitting] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    
    useEffect(()=>{
        const getDirAdtvas = async () =>{
            const response = await ApiService.dirAdtvas.index(filters)
            if (response.status === 200) {
                setDirAdtvas(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) getDirAdtvas()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData) => {
        setSelectedDirAdtva(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeInfoDialog = () => setShow({...show, info: false})

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values, formikBag) => {
        setSubmitting(true)
        const response = await ApiService.dirAdtvas.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            reloadFilters()
            closeFormDialog()
        } else if (response.status === 422) {
            const errors = response.data.errors
            const _errors = {}
            for (const key in errors) {
                _errors[key] = errors[key][0]
            }
            formikBag.setErrors(_errors)
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values, formikBag) => {
        if (selectedDirAdtva) {
            setSubmitting(true)
            const response = await ApiService.dirAdtvas.update(values, selectedDirAdtva.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            } else if (response.status === 422) {
                const errors = response.data.errors
                const _errors = {}
                for (const key in errors) {
                    _errors[key] = errors[key][0]
                }
                formikBag.setErrors(_errors)
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedDirAdtva) {
            const response = await ApiService.dirAdtvas.delete(selectedDirAdtva.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDeleteDialog()
            }
        }
    }
    const clearSelected = () => setSelectedDirAdtva(null)
    const handleClean = () => tableRef?.current?.clearFilters()

    const actionsBodyTemplate = (rowData) => {
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded 
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div>{_options}</div>
    }
    const columns = [
        {
            field: 'codigo',
            header: 'Código',
            filter: true,
            sortable: true,
            style: { width: '10rem' },
        },
        {
            field: 'descripcion',
            header: 'Nombre',
            filter: true,
            sortable: true,
        },
        {
            field: 'abreviacion',
            header: 'Abreviación',
            filter: true,
            sortable: true,
            style: {width: '10.5rem'}
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => <div className='d-flex justify-content-center'><Button startIcon='pi pi-filter' onClick={handleClean} >Limpiar</Button></div>,
            body: actionsBodyTemplate,
            style: { width: '10rem' },
        },
    ]

    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>
    const options = <>
        <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create', null)} >Nuevo</Button>
    </>

    return <>
        <TitlePage title='Lista de Direcciones Administrativas' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={dirAdtvas} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                />

                <Dialog
                    show={show.form}   
                    header={ selectedDirAdtva ? 'Editar DA' : 'Registrar DA' } 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{ width: '30rem' }}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormDirAdtva 
                        formRef={formRef} selectedDirAdtva={selectedDirAdtva}
                        onSubmit={ selectedDirAdtva ? handleUpdate : handleCreate }  
                    />
                </Dialog>

                <ConfirmDialog
                    show={show.delete}
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={handleDelete}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> la Dirección Administrativa "{selectedDirAdtva?.descripcion}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información de Dirección Administrativa' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '35rem' }}
                    onExited={clearSelected}
                >
                    {selectedDirAdtva ? <ViewDirAdtva selectedDirAdtva={selectedDirAdtva} /> : null}
                </Dialog>

            </div>
        </div>
    </>
}
export default DirAdtvaIndex