import Input from 'components/Input'
import ScrollUp from 'components/ScrollUp'
import TitlePage from 'components/TitlePage'
import InputLayout from 'components/layouts/InputLayout'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ApiService from 'services/api.service'

/** @module Pages/Cuenta/LibretaCuentas */

/**
 * Página, Libreta de cuentas.
 * @returns {JSX.Element} Retorna el componente LibretaCuentas.
 */
function LibretaCuentas() {
    const [libreta, setLibreta] = useState([])
    const [filters, setFilters] = useState({ da: '', cuenta: '' })

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.getLibretaCuentas()
            if (response.status === 200) setLibreta(response.data)
        }
        loadData()
    }, [])

    const handleChange = (e) => setFilters({ ...filters, [e.target.name]: e.target.value })
    const getLibreta = () => {
        const f = {
            cuenta: filters.cuenta.toLowerCase().trim(),
            da: filters.da.toLowerCase().trim(),
        }
        return libreta.reduce((carry, da) => {
            const cuentas = da.cuentas.reduce((cCarry, c) => {
                if (
                    f.cuenta.length === 0 ||
                    [c.descripcion.toLowerCase(), c.codigo].some((el) => el.includes(f.cuenta))
                ) {
                    cCarry.push(
                        <tr key={`c-${da.codigo}.${c.codigo}`}>
                            <td>{da.codigo}</td>
                            <td>{c.codigo}</td>
                            <td className='text-start'>{c.descripcion}</td>
                        </tr>,
                    )
                }
                return cCarry
            }, [])
            if (f.cuenta.length === 0 || [da.descripcion.toLowerCase(), da.codigo].some((el) => el.includes(f.da))) {
                carry.push(
                    <tr key={`da-${da.codigo}`}>
                        <th className='border-end-0'>{da.codigo}</th>
                        <th className='text-start border-start-0' colSpan={2}>
                            {da.descripcion}
                        </th>
                    </tr>,
                    ...cuentas,
                )
            }
            return carry
        }, [])
    }

    return (
        <>
            <TitlePage title='Libreta de Cuentas' />
            <div className='content align-items-center' style={{ backgroundColor: '#efefef' }}>
                <Card className='w-100 w-xl-75 p-5'>
                    <div>
                        <div className='border rounded position-relative p-3 pt-4 mb-3'>
                            <div className='position-absolute bg-white px-2' style={{ top: '-.65rem' }}>
                                Filtros
                            </div>
                            <div className='row g-3'>
                                <InputLayout className='col-sm-6 col-md-auto' label='DA:' inline>
                                    <Input name='da' value={filters.da} onChange={handleChange} />
                                </InputLayout>
                                <InputLayout className='col-sm-6 col-md-auto' label='Cuenta:' inline>
                                    <Input name='cuenta' value={filters.cuenta} onChange={handleChange} />
                                </InputLayout>
                            </div>
                        </div>
                        <table className='table table-sm table-bordered text-center' style={{ borderColor: '#000' }}>
                            <thead style={{ backgroundColor: '#f0f0f0' }}>
                                <tr>
                                    <th colSpan={3}>LIBRETA DE CUENTAS UMSS</th>
                                </tr>
                                <tr>
                                    <th>DA</th>
                                    <th>Categoría</th>
                                    <th>Descripción</th>
                                </tr>
                            </thead>
                            <tbody>{getLibreta()}</tbody>
                        </table>
                    </div>
                </Card>
            </div>
            <ScrollUp id='main-container' />
        </>
    )
}
export default LibretaCuentas
