/**
 * Junta las propiedades css de los objetos de un array.
 * @param args Objetos CSSProperties a unir.
 * @returns Retorna un objeto con todas las propiedades css.
 */
export function joinStyles(...args) {
    const styles = args.reduce((carry, arg) => {
        carry.push(...(Array.isArray(arg) ? arg : [arg]))
        return carry
    }, [])
    const _styles = styles.reduce((carry, item) => {
        return typeof item === 'object' ? { ...carry, ...item } : carry
    }, {})
    return Object.keys(_styles).length > 0 ? _styles : undefined
}
