/**
 * Crea una función que se ejecutará a intervalos regulares.
 *
 * @param func La función que se debe limitar.
 * @param limit El intervalo de tiempo (en milisegundos) entre ejecuciones.
 * @returns Una nueva función que está limitada.
 */
export function throttle(func, limit) {
    let lastFunc = null
    let lastRan = 0
    return function (...args) {
        const now = Date.now()
        if (lastRan && now - lastRan < limit) {
            if (lastFunc) clearTimeout(lastFunc)
            lastFunc = setTimeout(
                () => {
                    lastRan = now
                    func.apply(this, args)
                },
                limit - (now - lastRan),
            )
        } else {
            lastRan = now
            func.apply(this, args)
        }
    }
}
