import { Form, Formik } from 'formik'
import { CheckboxFormik, CustomInputFormik, InputFormik } from 'components/formik/formikFormComponents'
import { userCreateValidators, userUpdateValidators } from 'utils/validators/validators'
import SelectFuenteFinanciamiento from './SelectFuenteFinanciamiento'
import { FieldGroup } from 'modules/modular/FieldGroup'
import SearchBeneficiario from 'components/SearchBeneficiario'
import { useState } from 'react'

/** @module Pages/Usuario/Components/FormUser */

/**
 * Componente, Formulario de registro/edición de Usuario.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.selectedUser Usuario seleccionado.
 * @returns {JSX.Element} Retorna el componente FormUser.
 */
function FormUser({ formRef, onSubmit, selectedUser }) {
    const initialValues = selectedUser
        ? selectedUser
        : {
              nombre: '',
              username: '',
              password: '',
              confirmar: '',
              beneficiario_id: '',
              send: true,
          }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={selectedUser ? userUpdateValidators : userCreateValidators}
            onSubmit={onSubmit}
        >
            {({ values, setValues }) => <FormUserContent user={selectedUser} values={values} setValues={setValues} />}
        </Formik>
    )
}

function FormUserContent({ user, values, setValues }) {
    const [visible, setVisible] = useState({ password: false, confirmar: false })

    const toggleVisible = (key) => setVisible({ ...visible, [key]: !visible[key] })

    const handleSelect = (s) => {
        if (s) {
            setValues({
                ...values,
                nombre: [s.nombre_1, s.nombre_2, s.apellido_1, s.apellido_2, s.apellido_3]
                    .join(' ')
                    .replace(/\s+/g, ' ')
                    .trim(),
                username: s.email,
                beneficiario_id: s.id,
                password: s.documento,
                confirmar: s.documento,
            })
        }
    }

    return (
        <Form>
            {!user && (
                <>
                    <FieldGroup label='Buscar Persona'>
                        <SearchBeneficiario onSelect={handleSelect} oneByCargo={false} label='C.I.:' />
                    </FieldGroup>
                    <div className='border-bottom mb-3' />
                </>
            )}
            <InputFormik name='nombre' />
            <InputFormik name='username' label='Usuario' disabled={!!values.beneficiario_id} />
            <SelectFuenteFinanciamiento />
            {!user && (
                <>
                    <CustomInputFormik
                        name='password'
                        type={visible.password ? 'text' : 'password'}
                        label='Contraseña'
                        endIcon={
                            <div className='cursor-pointer text-dark' onClick={() => toggleVisible('password')}>
                                <span
                                    {...(visible.password
                                        ? { className: 'bi-eye-slash', title: 'Ocultar Contraseña' }
                                        : { className: 'bi-eye', title: 'Mostrar Contraseña' })}
                                />
                            </div>
                        }
                    />
                    <CustomInputFormik
                        name='confirmar'
                        type={visible.confirmar ? 'text' : 'password'}
                        label='Confirmar contraseña'
                        endIcon={
                            <div className='cursor-pointer text-dark' onClick={() => toggleVisible('confirmar')}>
                                <span
                                    {...(visible.confirmar
                                        ? { className: 'bi-eye-slash', title: 'Ocultar Contraseña' }
                                        : { className: 'bi-eye', title: 'Mostrar Contraseña' })}
                                />
                            </div>
                        }
                    />
                    <CheckboxFormik
                        name='send'
                        label={<span className='fw-medium'>Enviar cuenta de usuario por correo.</span>}
                        style={{ fontSize: '1.25rem' }}
                    />
                </>
            )}
        </Form>
    )
}

export default FormUser
