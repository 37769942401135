import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import FormCuenta from './components/FormCuenta'
import ViewCuenta from './components/ViewCuenta'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import DataTable from 'components/DataTable'
import InputCheckbox from 'components/InputCheckbox'
import { initialPagination } from 'data/const'

/** @module Pages/Cuenta/CuentaIndex */

// Opciones de filtros para el hook useFilters.
const filterOptions = {
    contain: ['codigo', 'descripcion', 'da', 'codigo_da', 'nombre_corto'], bool: ['activo']
}
// Opciones de cuentas para Administrador.
const actionsAdmin = [
    {icon: 'bi-eye', title: 'Información', action: 'view'},
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
    {icon: 'bi-trash', title: 'Eliminar', action: 'delete'},
]
// Opciones de cuentas para Tesorería.
const actionsViaticos = [
    {icon: 'bi-pencil', title: 'Editar', action: 'update'},
]

/**
 * Página de administración de Cuentas.
 * @param {object} props Propiedades del componente.
 * @param {"admin" | "viaticos"} props.type Especifica la sección de la que se mostrarán las opciones.
 * @returns {JSX.Element} Retorna el componente CuentaIndex.
 */
function CuentaIndex({type}) {
    const dispatch = useDispatch()
    const [cuentas, setCuentas] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, handleFilter, handlePage, handleSort} = useFilters({initialFilters: null, filterOptions})
    const [selectedCuenta, setSelectedCuenta] = useState(null)
    const [show, setShow] = useState({form: false, delete: false, info: false})
    const [submitting, setSubmitting] = useState(false)
    const tableRef = useRef()
    const formRef = useRef()
    
    useEffect(() => {
        const getCuentas = async () =>{
            const response = await ApiService.cuenta.index(filters)
            if (response.status === 200) {
                setCuentas(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) getCuentas()
    }, [filters])

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData=null) => {
        if (rowData!==null) setSelectedCuenta(rowData)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        } else if (action === 'view') {
            openDialog('info')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeInfoDialog = () => setShow({...show, info: false})

    const handleSubmit = () => formRef?.current?.handleSubmit()
    const handleCreate = async (values) => {
        setSubmitting(true)
        const response = await ApiService.cuenta.create(values)
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 201) {
            reloadFilters()
            closeFormDialog()
        }
        setSubmitting(false)
    }
    const handleUpdate = async (values) => {
        if (selectedCuenta) {
            setSubmitting(true)
            const response = await ApiService.cuenta.update(values, selectedCuenta.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeFormDialog()
            }
            setSubmitting(false)
        }
    }
    const handleDelete = async () => {
        if (selectedCuenta) {
            const response = await ApiService.cuenta.delete(selectedCuenta.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeDeleteDialog()
            }
        }
    }
    const clearFilters = () => tableRef?.current?.clearFilters()
    const clearSelected = () => setSelectedCuenta(null)

    const actionsBodyTemplate = (rowData) => {
        let actions = type==='admin' ? actionsAdmin : (type==='viaticos' ? actionsViaticos : [])
        const _options = actions.reduce((carry, option) => {
            if ((typeof option.condition === 'function' && option.condition(rowData)) || typeof option.condition !== 'function') {
                carry.push(
                    <ButtonIcon 
                        variant='text-secondary' rounded
                        icon={option.icon} title={option.title} key={option.title} 
                        onClick={() => handleOpenDialog(option.action, rowData)}
                    />
                )
            }
            return carry
        }, [])
        return <div className={type==='viaticos'?'text-center':null}>{_options}</div>
    }
    const columns = [
        {
            field: 'codigo_da',
            header: 'DA',
            filter: true,
            sortable: true,
            style: { width: '8rem' },
        },
        {
            field: 'codigo',
            header: 'Código',
            filter: true,
            sortable: true,
            style: { width: '8rem' },
        },
        {
            field: 'descripcion',
            header: 'Nombre',
            filter: true,
            sortable: true,
        },
        {
            field: 'nombre_corto',
            header: 'Nombre Corto',
            body: (rowData) => rowData.nombre_corto||'-',
            filter: true,
            sortable: true,
        },
        {
            field: 'activo',
            header: 'Activo',
            filter: true,
            sortable: true,
            filterElement: (options) => (
                <div className='d-flex justify-content-center'>
                    <InputCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} triState style={{fontSize: '1.5rem'}}/>
                </div>
            ),
            body: (rowData) => rowData.activo ? 'Si' : 'No',
            style: { width: '8rem', textAlign: 'center' },
        },
        {
            field: 'id',
            header: 'Opciones',
            filter: true,
            filterElement: () => <div className='d-flex justify-content-center'><Button startIcon='pi pi-filter' onClick={clearFilters} >Limpiar</Button></div>,
            body: actionsBodyTemplate,
            style: { width: '10rem' },
        },
    ]
    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button 
            variant='text-secondary' startIcon='pi pi-save' 
            type='submit' onClick={handleSubmit}
            loading={submitting}
        >Guardar</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeInfoDialog}>Cerrar</Button>
    const options = <>
        {type === 'admin' && <Button startIcon='pi pi-plus' onClick={()=>handleOpenDialog('create')} >Nuevo</Button>}
    </>

    return <>
        <TitlePage title='Lista de Cuentas' options={options} />
        <div className='content'>
            <div>
                <DataTable 
                    innerRef={tableRef}
                    values={cuentas} 
                    columns={columns} 
                    filterable
                    onFilter={handleFilter}
                    onSort={handleSort}
                    pagination
                    rowsPerPage={pagination.pageSize}
                    totalRecords={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPage={handlePage}
                    rowClassName={rowData=>rowData.activo?'':'row-disabled'}
                    rowsPerPageOptions={[10, 25, 50]}
                />

                <Dialog
                    show={show.form}   
                    header={ selectedCuenta ? 'Editar Cuenta' : 'Registrar Cuenta' } 
                    footer={formFooterDialog}
                    keyboard={false}
                    style={{ width: '30rem' }}
                    onHide={closeFormDialog}
                    onExited={clearSelected}
                >
                    <FormCuenta 
                        formRef={formRef} selectedCuenta={selectedCuenta}
                        onSubmit={ selectedCuenta ? handleUpdate : handleCreate }
                        type={type}  
                    />
                </Dialog>
                <ConfirmDialog
                    show={show.delete}
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={handleDelete}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ELIMINAR</span> la Cuenta "{selectedCuenta?.descripcion}"?
                </ConfirmDialog>
                <Dialog
                    show={show.info}   
                    header='Información de cuenta' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '35rem' }}
                    onExited={clearSelected}
                >
                    {selectedCuenta ? <ViewCuenta selectedCuenta={selectedCuenta} /> : null}
                </Dialog>

            </div>
        </div>
    </>
}
export default CuentaIndex