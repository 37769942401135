import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHttpMessage } from 'store/messageSlice'
import ApiService from 'services/api.service'

import Dialog, { ConfirmDialog } from 'components/Dialog'
import Button, { ButtonIcon } from 'components/Button'

import TitlePage from 'components/TitlePage'
import { CURRENCIES, numEscalas } from 'data/const'
import InputDropdown from 'components/InputDropdown'
import Input from 'components/Input'
import FormDestino from './components/FormDestino'
import FormTipoCambio from './components/FormTipoCambio'
import { formatCurrency } from 'utils/FormatNumber'

/** @module Pages/EscalaViaticos/EscalaViaticosIndex */

/**
 * Página de administración de Escalas de Viáticos.
 * @returns {JSX.Element} Retorna el componente EscalaViaticosIndex.
 */
function EscalaViaticosIndex() {
    const dispatch = useDispatch()
    const [show, setShow] = useState({form: false, delete: false, tipoCambio: false})
    const [destinos, setDestinos] = useState([])
    const [indexDestino, setIndexDestino] = useState(null)
    const [editable, setEditable] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [initDestinos, setInitDestinos] = useState([])
    const [reglamentos, setReglamentos] = useState([])
    const [selectedReglamento, setSelectedReglamento] = useState('')
    const formRef = useRef()
    const tcRef = useRef()

    useEffect(()=>{
        const loadReglamentos = async () => {
            const response = await ApiService.reglamento.getAll()
            if (response.status === 200) {
                const _reglamentos =response.data
                let activeReglamento = ''
                _reglamentos.forEach(r => {
                    if (r.activo) activeReglamento = r
                })
                if (activeReglamento) setSelectedReglamento(activeReglamento)
                setReglamentos(_reglamentos)
            }
        }
        loadReglamentos()
    }, [])
    useEffect(() => {
        if (selectedReglamento?.id) loadDestinos()
    }, [selectedReglamento])

    const loadDestinos = async () => {
        const response = await ApiService.destino.getByReglamento(selectedReglamento.id)
        if (response.status === 200) {
            const _destinos = response.data.map(d => ({...d, escalas: normalizeEscalas(d.escalas, numEscalas)}))
            setDestinos(_destinos)
            setInitDestinos(_destinos)
        }
    }

    // Controlador para abrir los modales
    const handleOpenDialog = (action, index) => {
        setIndexDestino(index)
        if (action === 'update' || action === 'create') {
            openDialog('form')
        } else if (action === 'delete') {
            openDialog('delete')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const openTipoCambioDialog = () => setShow({...show, tipoCambio: true})
    const closeFormDialog = () => setShow({...show, form: false})
    const closeDeleteDialog = () => setShow({...show, delete: false})
    const closeTipoCambioDialog = () => setShow({...show, tipoCambio: false})

    const buildRowDestino = (destino, index) => {
        const update = () => handleOpenDialog('update', index) 
        const remove = () => handleOpenDialog('delete', index)
        const _destino = <Destino value={destino} remove={remove} update={update} editable={editable}/>
        const replaceEscala = (i, value) => {
            const _escalas = destino.escalas.map((e, _i) => _i===i?value:e)
            const _destino = {...destino, escalas: _escalas}
            replaceDestino(index, _destino)
        }
        const _escalas = destino.escalas.map((escala, i) => <Escala key={i} index={i} value={escala} replace={replaceEscala} editable={editable}/>)
        return <div className='d-flex border-bottom' key={index}>{_destino}{_escalas}</div>
    }
    const normalizeEscalas = (escalas, numCat=5) => {
        const _escalas = {}
        for (let i = 1; i <= numCat; i++) {
            _escalas[`cat${i}`] = {categoria: `cat${i}`, monto: '', moneda: 'Bs'}
        }
        const cats = ['cat1', 'cat2', 'cat3', 'cat4', 'cat5'].slice(0, numCat)
        escalas.forEach(e => {
            if (cats.includes(e.categoria)) {
                _escalas[e.categoria] = e
            }
        })
        return Object.values(_escalas)
    }
    const replaceDestino = (index, value) => {
        const _destinos = destinos.map((d, i) => i===index?value:d)
        setDestinos(_destinos)
    }
    const removeDestino = () => {
        if (indexDestino!==null) {
            const _destinos = destinos.filter((d, i) => i!==indexDestino)
            setDestinos(_destinos)
        }
        closeDeleteDialog()
    }
    const createDestino = async (values) => { 
        setDestinos([...destinos, values])
        closeFormDialog()
    }
    const updateDestino = async (values) => {
        if (indexDestino!==null) {
            const _destinos = destinos.map((d, i) => i===indexDestino?values:d)
            setDestinos(_destinos)
        }
        closeFormDialog()
    }
    const clearIndex = () => setIndexDestino(null)

    const handleUpdate = async () => {
        setSubmitting(true)
        if (selectedReglamento) {
            const response = await ApiService.destino.updateBash({destinos}, selectedReglamento.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) loadDestinos()
        }
        setEditable(false)
        setSubmitting(false)
    }
    const handleCancel = () => {
        setDestinos(initDestinos)
        setEditable(false)
    }
    const createTipoCambio = async (values) => {
        const response = await ApiService.tipoCambio.create({...values, moneda: '$us'})
        dispatch(setHttpMessage({status: response.status, title: response.data.message}))
        if (response.status === 200) closeTipoCambioDialog()
    }

    const formFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeFormDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={() => formRef?.current?.handleSubmit()}>Guardar</Button>
    </>
    const tipoCambioFooterDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeTipoCambioDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='pi pi-save' type='submit' onClick={() => tcRef?.current?.handleSubmit()}>Guardar</Button>
    </>
    const options = <>
        <Button startIcon='bi-cash-coin' onClick={openTipoCambioDialog}>Tipo de Cambio</Button>
    </>

    return <>
        <TitlePage title='Escalas de Viáticos' options={options} />
        <div className='content'>
            <div className='w-100'>
                <div className='d-flex align-items-center mb-4'>
                    <span className='me-2'>Reglamento:</span>
                    <InputDropdown 
                        value={selectedReglamento}
                        onChange={(e) => setSelectedReglamento(e.target.value)} 
                        label='Reglamento'
                        options={reglamentos}
                        optionValue='id'
                        valueAsOption
                        valueTemplate={(option)=>`${option.tipo} ${option.numero}`}
                        itemTemplate={(option)=>`${option.tipo} ${option.numero}`}
                        className='w-auto'
                    />
                    {selectedReglamento.activo && (
                        <div className='ms-auto'>
                            {editable 
                                ? <>
                                    <Button startIcon='bi-x-lg' onClick={handleCancel} className='me-3' disabled={submitting}>Cancelar</Button>
                                    <Button startIcon='pi pi-save' onClick={handleUpdate} loading={submitting}>Guardar</Button>
                                </>
                                : <Button startIcon='bi-pencil' onClick={()=>setEditable(true)}>Editar</Button>
                            }
                        </div>
                    )}
                </div>
                <div className='border'>
                    <div className='d-flex border-bottom' style={{backgroundColor: '#f8f9fa'}}>
                        <div className='fw-bold' style={{padding: '.25rem 1rem', width: '25%'}}>
                            <div className='lh-1 text-end'>Categorías</div>
                            <div className='border-top' style={{transform: 'rotate(5deg)'}}></div>
                            <div className='lh-1'>Destinos</div>
                        </div>
                        {(new Array(numEscalas)).fill(1).map((_, index) => (
                            <div 
                                key={index}
                                className='text-center fw-bold border-start py-2 px-3' 
                                style={{width: `${formatCurrency(75/numEscalas)}%`}}
                            >Categoría {index+1}</div>
                        ))}
                    </div>
                    {destinos.map(buildRowDestino)}
                    {editable && (
                        <div className='d-flex'>
                            <div className='w-25 text-center'>
                                <Button startIcon='pi pi-plus' variant='tab-red' onClick={()=>handleOpenDialog('create', null)} className='active'>Destino</Button>
                            </div>
                            <div className='w-75 border-start'></div>
                        </div>
                    )}
                </div>
                <Dialog
                    show={show.form}   
                    header={indexDestino===null ? 'Crear Destino' : 'Editar Destino'} 
                    footer={formFooterDialog}
                    keyboard={false}
                    onHide={closeFormDialog}
                    onExited={clearIndex}
                >
                    <FormDestino 
                        formRef={formRef} 
                        onSubmit={indexDestino===null?createDestino:updateDestino}
                        selectedDestino={indexDestino===null?null:destinos[indexDestino]} 
                    />
                </Dialog>
                <Dialog
                    show={show.tipoCambio}   
                    header={'Tipo de Cambio'} 
                    footer={tipoCambioFooterDialog}
                    keyboard={false}
                    onHide={closeTipoCambioDialog}
                    onExited={clearIndex}
                >
                    <FormTipoCambio formRef={tcRef} onSubmit={createTipoCambio}/>
                </Dialog>
                <ConfirmDialog
                    show={show.delete}  
                    onHide={closeDeleteDialog}
                    onReject={closeDeleteDialog}
                    onAccept={removeDestino}
                    onExited={clearIndex}
                >
                    Esta seguro de ELIMINAR el Destino?
                </ConfirmDialog>
            </div>
        </div>
    </>
}
export default EscalaViaticosIndex

/**
 * Componente, Destino de viaje con sus escalas de viáticos.
 * @param {object} props Propiedades del componente.
 * @param {object} props.value Valor del destino.
 * @param {Function} props.remove Función de formik para remover un destino.
 * @param {Function} props.update Función de formik para editar un destino.
 * @param {boolean} props.editable Especifica si el Destino es editable o no.
 * @returns {JSX.Element} Retorna el componente Destino.
 */
function Destino({value, remove, update, editable}) {
    return (
        <div className='d-flex py-2 px-3 w-25'>
            <div style={{padding: '.375rem 0'}}>{value.lugar}</div>
            <div className='ms-auto'>
                {editable && <>
                    <ButtonIcon icon='bi-pencil' variant='text-secondary' onClick={update} rounded title='Editar'/>
                    <ButtonIcon icon='bi-trash' variant='text-secondary' onClick={remove} rounded title='Eliminar'/>
                </>}
            </div>
        </div>
    )
}

/**
 * Componente, Input de Escala de Viático editable.
 * @param {object} props Propiedades del componente.
 * @param {object} props.value Valor de la escala de viático.
 * @param {number} props.index Index de la escala de viático.
 * @param {Function} props.replace Función de formik que reemplaza una escala por otra.
 * @returns {JSX.Element} Retorna el componente Escala.
 */
function Escala({value, index, replace, editable}) {

    const handleChange = (e) => {
        replace(index, {...value, [e.target.name]: e.target.value})
    }

    return (
        <div className='py-2 px-3 border-start' style={{width: `${formatCurrency(75/numEscalas)}%`}}>
            {editable
                ? <div className='group-currency'>
                    <Input name='monto' value={value.monto} onChange={handleChange} type='currency' className='text-end'/>
                    <InputDropdown 
                        name='moneda' 
                        value={value.moneda} 
                        onChange={handleChange}
                        options={CURRENCIES} 
                        placeholder='Sel.'
                    />
                </div>
                : <div className='text-end' style={{padding: '.375rem 0'}}>
                    {value.monto?(value.monto*1).toFixed(2):''} {value.monto?value.moneda:''}
                </div>
            }
        </div>
    )
}