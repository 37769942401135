import { SelectFormik } from 'components/formik'
import { useEffect, useState } from 'react'
import ApiService from 'services/api.service'

export default function SelectFuenteFinanciamiento() {
    const [fuentes, setFuentes] = useState([])

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ApiService.fuenteFinanciamiento.index()
            if (status === 200) setFuentes(data)
        }
        load()
    }, [])

    return <SelectFormik name='fuente_financiamiento' options={fuentes} optionLabel='descripcion' optionValue='id' />
}
