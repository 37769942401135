import imgAdmin from 'assets/img/icon-admin-red.png'
import imgSol from 'assets/img/icon-solicitante-red.png'
import imgBen from 'assets/img/icon-beneficiario-red.png'
import imgAut from 'assets/img/icon-autoridad-red.png'
import imgPre from 'assets/img/icon-presupuestos-red.png'
import imgCaja from 'assets/img/icon-caja-red.png'
import imgTes from 'assets/img/icon-viaticos-red.png'
import imgDaf from 'assets/img/icon-daf-red.png'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

const icons = {
    administracion: imgAdmin,
    autoridad: imgAut,
    solicitante: imgSol,
    beneficiario: imgBen,
    caja: imgCaja,
    daf: imgDaf,
    presupuestos: imgPre,
    viaticos: imgTes,
}

function TitlePage({title, options, children}) {
    const navigate = useNavigate()
    const {section, sectionKey} = useSelector(state => state.system)

    return (
        <div className='title-page'>
            <Link className='d-flex justify-content-center text-decoration-none' to='/'>
                {sectionKey
                    ? <img src={icons[sectionKey]} alt={section} className='me-2' style={{height: '2.5rem'}}/>
                    : <div onClick={()=>navigate('/')}><span className='bi-arrow-left me-2'></span>Inicio</div>
                }
                <span className='fs-3 user-select-none text-red d-none d-sm-block' style={{letterSpacing: '-1px'}}>{(section||'').toUpperCase()}</span>
            </Link>
            <div className='fs-3 user-select-none'>
                <span className='ms-2 me-3 fw-light'>|</span>
                <span className='fw-light'>{children||title}</span>
            </div>
            <div className='title-page-options'>{options}</div>
        </div>
    )
}

export default TitlePage