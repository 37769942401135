import { forwardRef, useImperativeHandle } from 'react'
export const DataTableRef = forwardRef(({ clearSort, resetSort, clearFilters, resetFilters, focusRow }, ref) => {
    useImperativeHandle(ref, () => ({
        clearFilters() {
            clearFilters()
        },
        resetFilters() {
            resetFilters()
        },
        clearSort() {
            clearSort()
        },
        resetSort() {
            resetSort()
        },
        focusRow(rowIndex) {
            focusRow(rowIndex)
        },
    }))
    return null
})
