import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { verifyAccess } from 'utils/rbac.util'

/* Administración */
import CategoriaIndex from 'pages/administracion/Categoria/CategoriaIndex'
import DirAdtvaIndex from 'pages/administracion/DirAdtva/DirAdtvaIndex'
import EscalaViaticosIndex from 'pages/administracion/EscalaViaticos/EscalaViaticosIndex'
import ReglamentoIndex from 'pages/administracion/Reglamento/ReglamentoIndex'
import RoleIndex from 'pages/administracion/Role/RoleIndex'
import UnidadEjecutoraIndex from 'pages/administracion/UnidadEjecutora/UnidadEjecutoraIndex'
import UnidadIndex from 'pages/administracion/Unidad/UnidadIndex'
import UserIndex from 'pages/administracion/User/UserIndex'
import CuentaIndex from 'pages/administracion/Cuentas/CuentaIndex'

/* Beneficiario */
import SolicitudIndex from 'pages/solicitud/SolicitudIndex'
import SolicitudCreate from 'pages/solicitud/SolicitudCreate'

/* Papeletas */
import PapeletaIndex from 'pages/papeleta/PapeletaIndex'
import PapeletaCreate from 'pages/papeleta/PapeletaCreate'
import PapeletaReport from 'pages/papeleta/PapeletaReport'
import PapeletaAction from 'pages/papeleta/PapeletaAction'

/* Memorandum's */
import MemoIndex from 'pages/memorandum/MemoIndex'
import MemoCreate from 'pages/memorandum/MemoCreate'
import MemoTemplate from 'pages/memorandum/MemoTemplate'

/* Reembolsos */
import ReembolsoCreate from 'pages/reembolso/ReembolsoCreate'
import ReembolsoIndex from 'pages/reembolso/ReembolsoIndex'
import ReembolsoReport from 'pages/reembolso/ReembolsoReport'
import ReembolsoEmitidos from 'pages/reembolso/ReembolsoEmitidos'

/* Solicitudes */
import SolicitudDAFIndex from 'pages/solicitud/SolicitudDAFIndex'
import SolicitudAutoridadIndex from 'pages/solicitud/SolicitudAutoridadIndex'
import SolicitudPresupuestoIndex from 'pages/solicitud/SolicitudPresupuestoIndex'

/* Generales */

import Home from 'pages/Home'

import InternalServerError from 'pages/InternalServerError'

import MainLayout from 'components/layouts/MainLayout'

import LoadingSection from 'pages/LoadingSection'

import EstructuraProgramatica from 'pages/administracion/Unidad/EstructuraProgramatica'
import LibretaCuentas from 'pages/administracion/Cuentas/LibretaCuentas'
import Variables from 'pages/Variables'
import UnidadReembolsosIndex from 'pages/reembolso/UnidadReembolsosIndex'
import BeneficiarioIndex from 'pages/administracion/Beneficiario/BeneficiarioIndex'
import ReembolsoKardex from 'pages/reembolso/ReembolsoKardex'
import HomeSection from 'pages/HomeSection'
import CargoInvitadoIndex from 'pages/administracion/CargoInvitado/CargoInvitadoIndex'
import ReembolsoDetail from 'pages/reembolso/ReembolsoDetail'
import UserSettings from 'pages/administracion/User/UserSettings'
import ReglamentoViaticos from 'pages/administracion/Reglamento/ReglamentoViaticos'
import Config from 'pages/Config'
import SolicitudViaticosIndex from 'pages/solicitud/SolicitudViaticosIndex'
import EnableSolicitante from 'pages/administracion/Role/EnableSolicitante'

const publicRoutes = [
    { path: 'usuario/configuracion', element: <UserSettings /> },
    { path: 'reglamento-viaticos', element: <ReglamentoViaticos /> },
    { path: '500', element: <InternalServerError /> },
    { path: '*', element: <LoadingSection /> },
]
const routesAdministracion = [
    {
        path: 'administracion',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'categoria', element: <CategoriaIndex />, required: 'categoriaView' },
            { path: 'direccion-administrativa', element: <DirAdtvaIndex />, required: 'daView' },
            { path: 'escala-viaticos', element: <EscalaViaticosIndex />, required: 'escalaViaticosView' },
            { path: 'cuenta', element: <CuentaIndex type='admin' />, required: 'cuentaView' },
            { path: 'reglamento', element: <ReglamentoIndex />, required: 'reglamentoView' },
            { path: 'rol', element: <RoleIndex />, required: 'rolView' },
            { path: 'unidad-ejecutora', element: <UnidadEjecutoraIndex />, required: 'ueView' },
            { path: 'unidad', element: <UnidadIndex type='admin' />, required: 'unidadView' },
            { path: 'usuario', element: <UserIndex />, required: 'usuarioView' },
            { path: 'variables', element: <Variables /> },
            { path: 'beneficiario', element: <BeneficiarioIndex /> },
            { path: 'cargo-invitado', element: <CargoInvitadoIndex />, required: 'cargoInvitadoView' },

            { path: 'estructura-programatica', element: <EstructuraProgramatica /> },
            { path: 'libreta-cuentas', element: <LibretaCuentas /> },
            { path: 'configuracion', element: <Config /> },

            ...publicRoutes,
        ],
    },
]
const routesCaja = [
    {
        path: 'caja',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'viaticos', element: <PapeletaIndex />, required: 'papeletaView' },
            { path: 'viaticos/accion', element: <PapeletaAction />, required: ['papeletaPay', 'papeletaReverse'] },
            { path: 'reembolso', element: <ReembolsoIndex />, required: 'reembolsoView' },
            { path: 'reembolso/view/:id', element: <ReembolsoDetail />, required: 'reembolsoView' },
            { path: 'reembolso/create', element: <ReembolsoCreate />, required: 'reembolsoCreate' },
            { path: 'listados', element: <ReembolsoReport />, required: 'reembolsoReport' },
            { path: 'listados/emitidos', element: <ReembolsoEmitidos />, required: 'reembolsoReport' },
            { path: 'listados/kardex', element: <ReembolsoKardex />, required: 'reembolsoReport' },
            { path: 'unidad', element: <UnidadReembolsosIndex />, required: 'unidadReembolsosView' },

            { path: 'estructura-programatica', element: <EstructuraProgramatica /> },
            { path: 'libreta-cuentas', element: <LibretaCuentas /> },

            ...publicRoutes,
        ],
    },
]
const routesViaticos = [
    {
        path: 'viaticos',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'papeleta', element: <PapeletaIndex />, permissions: 'papeletaView' },
            { path: 'papeleta/create', element: <PapeletaCreate />, permissions: 'papeletaCreate' },
            { path: 'papeleta/create/:mbId', element: <PapeletaCreate />, permissions: 'papeletaCreate' },
            { path: 'reporte/viaticos', element: <PapeletaReport />, permissions: 'papeletaReport' },
            { path: 'solicitud', element: <SolicitudViaticosIndex />, permissions: 'solicitudView' },
            { path: 'unidad', element: <UnidadIndex type='viaticos' />, permissions: 'unidadView' },
            { path: 'cuenta', element: <CuentaIndex type='viaticos' />, permissions: 'cuentaView' },

            { path: 'estructura-programatica', element: <EstructuraProgramatica /> },
            { path: 'libreta-cuentas', element: <LibretaCuentas /> },

            ...publicRoutes,
        ],
    },
]
const routesPresupuestos = [
    {
        path: 'presupuestos',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'solicitud', element: <SolicitudPresupuestoIndex />, permissions: 'solicitudView' },

            { path: 'estructura-programatica', element: <EstructuraProgramatica /> },

            ...publicRoutes,
        ],
    },
]
const routesBeneficiario = [
    {
        path: 'beneficiario',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'solicitud', element: <SolicitudIndex />, permissions: 'solicitudView' },
            { path: 'solicitud/create', element: <EnableSolicitante /> },

            ...publicRoutes,
        ],
    },
]
const routesSolicitante = [
    {
        path: 'solicitante',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'solicitud', element: <SolicitudIndex />, permissions: 'solicitudView' },
            {
                path: 'solicitud/create',
                element: <SolicitudCreate />,
                permissions: 'solicitudCreate',
            },
            { path: 'solicitud/update/:id', element: <SolicitudCreate />, permissions: 'solicitudUpdate' },

            ...publicRoutes,
        ],
    },
]
const routesDaf = [
    {
        path: 'daf',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'memorandum', element: <MemoIndex />, permissions: 'memoView' },
            { path: 'memorandum/create', element: <MemoCreate />, permissions: 'memoCreate' },
            { path: 'memorandum/create/:sId', element: <MemoCreate />, permissions: 'memoCreate' },
            { path: 'template-memorandum', element: <MemoTemplate />, permissions: 'plantillaMemoView' },
            { path: 'solicitud', element: <SolicitudDAFIndex />, permissions: 'solicitudView' },

            ...publicRoutes,
        ],
    },
]
const routesAutoridad = [
    {
        path: 'autoridad',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomeSection /> },
            { path: 'solicitud', element: <SolicitudAutoridadIndex />, permissions: 'solicitudView' },

            ...publicRoutes,
        ],
    },
]

export default function PrivateRoutes() {
    const { secciones } = useSelector((state) => state.auth)

    const buildRoutes = (route, index) => {
        const { children, required, operator, ...props } = route
        return children ? (
            <Route key={route.path ?? `${index}-${children.length}`} {...props}>
                {children.map(buildRoutes)}
            </Route>
        ) : (
            verifyAccess(required, operator) && <Route key={route.path ?? (route.index ? '/' : index)} {...props} />
        )
    }

    return (
        <>
            <Routes>
                {(secciones['*'] || secciones.administracion) && routesAdministracion.map(buildRoutes)}
                {(secciones['*'] || secciones.caja) && routesCaja.map(buildRoutes)}
                {(secciones['*'] || secciones.viaticos) && routesViaticos.map(buildRoutes)}
                {(secciones['*'] || secciones.presupuestos) && routesPresupuestos.map(buildRoutes)}
                {(secciones['*'] || secciones.solicitante) && routesSolicitante.map(buildRoutes)}
                {(secciones['*'] || secciones.beneficiario) && routesBeneficiario.map(buildRoutes)}
                {(secciones['*'] || secciones.daf) && routesDaf.map(buildRoutes)}
                {(secciones['*'] || secciones.autoridad) && routesAutoridad.map(buildRoutes)}

                <Route path='/' element={<MainLayout />}>
                    {Object.values(secciones).length === 1 && !secciones['*'] ? (
                        <Route index element={<LoadingSection />} />
                    ) : (
                        <Route index element={<Home />} />
                    )}
                    {publicRoutes.map(buildRoutes)}
                </Route>
            </Routes>
        </>
    )
}
