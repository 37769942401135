import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import TableMemo from './components/TableMemo'
import Button from 'components/Button'
import Dialog, { ConfirmDialog } from 'components/Dialog'
import ViewMemo from './components/ViewMemo'

import { initialPagination } from 'data/const'
import MemoService from 'services/memo.service'
import { setHttpMessage } from 'store/messageSlice'
import TitlePage from 'components/TitlePage'
import useFilters from 'hooks/useFilters'
import { fileToBase64 } from 'utils/utils'
import { formatDate } from 'utils/utils'
import FormUploadFile from 'components/FormUploadFIle'
import MemoPdf from 'pdf/memo'
import ViewFilePdf from 'components/ViewFilePdf'
import ApiService from 'services/api.service'

/** @module Pages/Memo/MemoIndex */

// Valores iniciales de los filtros.
const filterOptions = { contain: ['numero_memo', 'descripcion'], between: ['fecha_creacion'] }

/**
 * Página, lista de memorandums.
 * @returns {JSX.Element} Retorna el componente MemoIndex.
 */
function MemoIndex() {
    const dispatch = useDispatch()
    const [memos, setMemos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const {filters, reloadFilters, ...filterHandlers} = useFilters({initialFilters: null, filterOptions})
    const [selectedMemo, setSelectedMemo] = useState(null)
    const [show, setShow] = useState({info: false, cancel: false, upload: false, viewFile: false})
    const [uploading, setUploading] = useState(false)
    const [file, setFile] = useState({value: null, error: ''})
    const tableRef = useRef()
    const pdfM = new MemoPdf()

    useEffect(() => {
        const loadData = async () => {
            const {filter, ..._filters} = filters
            const response = await MemoService.indexMemosPost({filter}, _filters)
            if (response.status === 200) {
                setMemos(response.data.data)
                setPagination(response.data.pagination)
            }
        }
        if (filters) loadData()
    }, [filters]) 

    // Controlador para abrir los modales
    const handleOpenDialog = (action, rowData) => {
        setSelectedMemo(rowData)
        if (action === 'cancel') {
            openDialog('cancel')
        } else if (action === 'view') {
            openDialog('info')
        } else if (action==='upload') {
            openDialog('upload')
        } else if (action === 'print') {
            handlePrint(rowData)
        } else if (action === 'view-file') {
            openDialog('viewFile')
        }
    }
    const openDialog = (name) => setShow({...show, [name]: true})
    const closeInfoDialog = () => setShow({...show, info: false})
    const closeCancelDialog = () => setShow({...show, cancel: false})
    const closeUploadDialog = () => setShow({...show, upload: false})
    const closeViewFileDialog = () => setShow({...show, viewFile: false})
    const clearSelected = () => setSelectedMemo(null)

    const handlePrint =  async (data) => {
        const response = await ApiService.config.search({keys: 'configMemo'})
        const config = response.status===200 ? JSON.parse(response.data.configMemo?.valor||null) : null
        pdfM.memo(data, config)
    }
    const handleCancel = async () => {
        if (selectedMemo) {
            const response = await MemoService.cancelMemo(selectedMemo.id)
            dispatch(setHttpMessage({status: response.status, title: response.data.message}))
            if (response.status === 200) {
                reloadFilters()
                closeCancelDialog()
            }
        }
    }
    const uploadFile = async () => {
        setUploading(true)
        if (selectedMemo) {
            const {value, error} = file
            if (value) {
                if (!error) {
                    const base64 = await fileToBase64(value)
                    const filename = value.name.slice(0, value.name.length-4)+'-'+formatDate(new Date(), 'YmdHi')
                    const response = await MemoService.uploadMemo({file: base64, filename}, selectedMemo.id)
                    dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                    if (response.status === 200) {
                        reloadFilters()
                        closeUploadDialog()
                    }
                }
            } else {
                setFile({value: null, error: 'Requerido'})
            }
        }
        setUploading(false)
    }

    const footerUploadDialog = <>
        <Button variant='text-secondary' startIcon='pi pi-times' onClick={closeUploadDialog}>Cancelar</Button>
        <Button variant='text-secondary' startIcon='bi-cloud-upload-fill' onClick={uploadFile} loading={uploading}>Subir</Button>
    </>
    const infoFooterDialog = <Button variant='text-secondary' startIcon='pi pi-times' type='submit' onClick={closeInfoDialog}>Cerrar</Button>


    return <>
        <TitlePage title='Lista de Memorándums' />
        <div className='content'>
            <div>
                <TableMemo 
                    tableRef={tableRef}
                    memos={memos} 
                    pagination={pagination} 
                    filterHandlers={filterHandlers}
                    handleOpenDialog={handleOpenDialog}
                />
                <Dialog
                    show={show.viewFile}
                    header='Solicitud'
                    footer={(
                        <Button 
                            startIcon='pi pi-times' 
                            variant='text-secondary' 
                            onClick={closeViewFileDialog}
                        >Cerrar</Button>
                    )}
                    onHide={closeViewFileDialog}
                    style={{width: '60rem'}}
                    onExited={clearSelected}
                    scrollable
                >
                    <ViewFilePdf selected={selectedMemo}/>
                </Dialog>
                <Dialog
                    show={show.info}   
                    header='Memorándum' 
                    footer={infoFooterDialog}
                    onHide={closeInfoDialog}
                    style={{ width: '65rem' }}
                    onExited={clearSelected}
                >
                    {selectedMemo ? <ViewMemo memo={selectedMemo} /> : null}
                </Dialog>
                <ConfirmDialog
                    show={show.cancel} 
                    onHide={closeCancelDialog}
                    onReject={closeCancelDialog}
                    onAccept={handleCancel}
                    onExited={clearSelected}
                >
                    Esta seguro de <span className='text-primary'>ANULAR</span> el memorándum "{selectedMemo?.numero_memo}"?
                </ConfirmDialog>
                <Dialog
                    show={show.upload}
                    header='Subir PDF'
                    footer={footerUploadDialog}
                    keyboard={false}
                    onHide={closeUploadDialog}
                    onExited={() => {
                        clearSelected()
                        setFile({value: null, error: ''})
                    }}
                >
                    <FormUploadFile file={file} setFile={setFile} />
                </Dialog>
            </div>
        </div>
    </>
}
export default MemoIndex