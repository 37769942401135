import { classNames, joinStyles } from '../../utils'
import DataTableRow from './DataTableRow'
import { BaseTd, BaseTr } from '../../base/BaseDataTable'
export default function DataTableGroupRows({
    headerRef,
    rowsRef,
    rowsIndexRef,
    columns,
    selectionMode,
    selectionOn,
    onKeyDown,
    onSelect,
    groupRowsMode,
    groupRowsBy,
    subheaderTemplate,
    subheaderClassName,
    subheaderStyle,
    headerSticky,
    id,
    value,
    className,
    style,
    groupRows,
}) {
    return (
        <>
            {groupRowsMode === 'subheader' && (
                <BaseTr
                    key={id}
                    className={classNames(['dtr-subheader', className, subheaderClassName])}
                    style={joinStyles([
                        headerSticky && headerRef.current && { top: `${headerRef.current.offsetHeight}px` },
                        style,
                        subheaderStyle,
                    ])}
                >
                    <BaseTd colSpan={columns.length}>{subheaderTemplate ? subheaderTemplate(value) : value}</BaseTd>
                </BaseTr>
            )}
            {groupRows?.map((row, index) => (
                <DataTableRow
                    key={row.id}
                    innerRef={(el) => {
                        if (el && row.selectable) {
                            rowsRef.current[row.selectableIndex] = el
                            rowsIndexRef.current[row.rowIndex] = row.selectableIndex
                        }
                    }}
                    {...row}
                    selectionMode={selectionMode}
                    selectionOn={selectionOn}
                    onKeyDown={onKeyDown}
                    onSelect={onSelect}
                    columns={columns}
                    rowSpanField={groupRowsBy}
                    rowSpan={groupRowsMode === 'rowspan' && index === 0 ? groupRows.length : 1}
                />
            ))}
        </>
    )
}
