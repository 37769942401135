import TitlePage from 'components/TitlePage'
import { Button } from 'modules/modular/Button'
import { Confirm } from 'modules/modular/Modal'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ApiService from 'services/api.service'
import { getUserAuth } from 'store/authSlice'
import { setHttpMessage } from 'store/messageSlice'

export default function EnableSolicitante() {
    const dispatch = useDispatch()
    const [show, setShow] = useState({ confirm: false })

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })

    /** Habilita un estudiante que pertenece a la FUL realizar Solicitudes. */
    const handleConfirm = async () => {
        const { status, data } = await ApiService.usuario.enableSolicitante()
        dispatch(setHttpMessage({ status: status, title: data.message }))
        if (status === 200) {
            dispatch(getUserAuth())
        }
        closeModal('confirm')
    }

    return (
        <>
            <TitlePage title='Registro de Solicitud' />
            <div className='content align-items-center' style={{ backgroundColor: '#efefef' }}>
                <Card className='w-xl-75' style={{ minHeight: '100%' }}>
                    <div className='p-4 d-flex flex-column align-items-center justify-content-center h-100'>
                        <div className='text-center fs-5'>
                            <span className='text-primary'>
                                <span className='fs-4'>Como Beneficiario</span>
                                <br />
                                Usted NO tiene permitido Registrar Solicitudes para Asignación de Pasajes y Viáticos.
                            </span>
                            <br />
                            <br />
                            <br />
                            <span className='fs-4'>¿Usted necesita registrar Solicitudes?</span>
                            <br />
                            Si usted es el "Encargado de Realizar Solicitudes para Asignación de Pasajes y Viáticos",
                            <br />
                            presione el siguiente botón:
                            <br />
                            <div className='py-2'>
                                <Button variant='blue' onClick={() => openModal('confirm')}>
                                    Habilitar Registro de Solicitudes
                                </Button>
                            </div>
                        </div>
                        <Confirm
                            show={show.confirm}
                            onAccept={handleConfirm}
                            onReject={() => closeModal('confirm')}
                            onHide={() => closeModal('confirm')}
                        >
                            Usted <span className='text-primary'>CONFIRMA</span> que es
                            <span className='fw-medium mx-1'>
                                "Encargado de Realizar Solicitudes para Asignación de Pasajes y Viáticos"
                            </span>
                            y desea <span className='text-primary'>HABILITAR</span> el registro de Solicitudes.
                        </Confirm>
                    </div>
                </Card>
            </div>
        </>
    )
}
