import { GenericRequest, SignalRequest } from './request'

const ApiService = {

    getServiceFile: (filename) => GenericRequest.get('solicitud/get-file', {filename}),
    getLocalFile: (filename) => GenericRequest.get('user/get-local-file', {filename}),

    config: {
        search: (data) => GenericRequest.post('user/search-config', data),
        update: (id, data) => GenericRequest.post('user/update-config', data, {id}),
        updateFile: (data) => GenericRequest.post('user/update-config-file', data)
    },

    tipoCambio: {
        getActual: () => GenericRequest.get('escala-viaticos/get-tipo-cambio'),
        create: (data) => GenericRequest.post('escala-viaticos/create-tipo-cambio', data),
    },

    beneficiario: {
        index: (params={}) => SignalRequest.get('beneficiario', params),
        view: (id) => GenericRequest.get('beneficiario/view', {id}),
        search: (documento, oneByCargo, byName) => GenericRequest.get('beneficiario/search-beneficiario', {documento, oneByCargo: oneByCargo?1:0, byName: byName?1:0}),
        assignDefaultCargo: (id) => GenericRequest.get('beneficiario/assign-default-cargo', {id}),
        update: (id) => GenericRequest.get('beneficiario/update-beneficiario', {id})
    },

    getUserAuth: () => GenericRequest.get('user/get-user-auth'),
    getUserInfo: () => GenericRequest.get('user/get-user-info'),

    usuario: {
        index: (params={}) => GenericRequest.get('user/index', params),
        create: (data) => GenericRequest.post('user/create', data),
        update: (data, id) => GenericRequest.put('user/update', data, {id}),
        delete: (id) => GenericRequest.delete('user/delete', {id}),
        toggleActivo: (id) => GenericRequest.get('user/toggle-activo', {id}),
        createFromBeneficiario: (beneficiarioId) => GenericRequest.post('user/create-from-beneficiario', {beneficiarioId}),
        verifyIfUserExist: (params) => GenericRequest.get('user/verify-if-user-exist', params),
        getRolesToAssign: (id) => GenericRequest.get('user/get-roles-to-assign', {id}),
        assignRoles: (data, id) => GenericRequest.post('user/assign-roles', data, {id}),
        changePassword: (data) => GenericRequest.post('user/change-password', data),
        enableSolicitante: () => GenericRequest.post('user/enable-solicitante'),
    },

    destino: {
        getByReglamento: (reglamentoId) => GenericRequest.get('destino/get-destinos', {reglamentoId}),
        updateBash: (data, reglamentoId) => GenericRequest.post('destino/update-destinos', data, {reglamentoId}),
    },

    cargo: {
        index: (params={}) => GenericRequest.get('cargo/index', params),
        create: (data) => GenericRequest.post('cargo/create', data),
        update: (data, id) => GenericRequest.put('cargo/update', data, {id}),
        delete: (id) => GenericRequest.delete('cargo/delete', {id}),
        getAll: () => GenericRequest.get('cargo/get-all'),
        getToAssign: (reglamentoId) => GenericRequest.get('cargo/get-to-assign', {reglamentoId}),
        getInvitados: () => GenericRequest.get('cargo/get-cargos-invitados')
    },

    rol: {
        index: (params={}) => GenericRequest.get('role/index', params),
        create: (data) => GenericRequest.post('role/create', data),
        update: (data, rol) => GenericRequest.put('role/update', data, {rol}),
        delete: (rol) => GenericRequest.delete('role/delete', {rol}),
        getPermissions: (rol) => GenericRequest.get('role/get-permissions-to-assign', {rol}),
        assignPermissions: (data, rol) => GenericRequest.post('role/assign-permissions', data, {rol}),
    },

    dirAdtvas: {
        index: (params={}) => GenericRequest.get('direccion-administrativa/index', params),
        getAll: () => GenericRequest.get('direccion-administrativa/get-all'),
        create: (data) => GenericRequest.post('direccion-administrativa/create', data),
        update: (data, id) => GenericRequest.put('direccion-administrativa/update', data, {id}),
        delete: (id) => GenericRequest.delete('direccion-administrativa/delete', {id}),
        search: (da) => SignalRequest.get('direccion-administrativa/search-dir-adtvas', {da}),
    },

    udEjecutora: {
        index: (params={}) => GenericRequest.get('unidad-ejecutora/index', params),
        getAll: (id) => GenericRequest.get('unidad-ejecutora/get-all', {id}),
        create: (data) => GenericRequest.post('unidad-ejecutora/create', data),
        update: (data, id) => GenericRequest.put('unidad-ejecutora/update', data, {id}),
        delete: (id) => GenericRequest.delete('unidad-ejecutora/delete', {id}),
    },

    unidad: {
        index: (params={}) => GenericRequest.get('unidad/index', params),
        create: (data) => GenericRequest.post('unidad/create', data),
        update: (data, id) => GenericRequest.put('unidad/update', data, {id}),
        delete: (id) => GenericRequest.delete('unidad/delete', {id}),
        search: (unidad) => SignalRequest.get('unidad/search-unidades', {unidad}),
        searchByImprimible: (params) => SignalRequest.get('unidad/search-by-imprimible', params),
    },
    getEstructuraProgramatica: () => GenericRequest.get('unidad/get-estructura'),
    
    cuenta: {
        index: (params={}) => GenericRequest.get('libreta-cuentas/index', params),
        create: (data) => GenericRequest.post('libreta-cuentas/create', data),
        update: (data, id) => GenericRequest.put('libreta-cuentas/update', data, {id}),
        delete: (id) => GenericRequest.delete('libreta-cuentas/delete', {id}),
        search: (cuenta) => SignalRequest.get('libreta-cuentas/search-cuentas', {cuenta}),
    },
    getLibretaCuentas: () => GenericRequest.get('libreta-cuentas/get-libreta'),
    
    reglamento: {
        index: (params={}) => GenericRequest.get('reglamento/index', params),
        getAll: () => GenericRequest.get('reglamento/get-all'),
        create: (data) => GenericRequest.post('reglamento/create', data),
        update: (data, id) => GenericRequest.put('reglamento/update', data, {id}),
        delete: (id) => GenericRequest.delete('reglamento/delete', {id}),
    },
    
    categoria: {
        index: (params={}) => GenericRequest.get('/categoria/index', params),
        create: (data) => GenericRequest.post('/categoria/create', data),
        update: (data, id) => GenericRequest.put('/categoria/update', data, {id}),
        delete: (id) => GenericRequest.delete('/categoria/delete', {id}),
        getByReglamento: (reglamentoId) => GenericRequest.get('categoria/get-categorias', {reglamentoId}),
    },

    getTableEscalas: () => GenericRequest.get('escala-viaticos/get-table-escalas'),
    
    fuenteFinanciamiento: {
        index: () => GenericRequest.get('fuente-financiamiento'),
        all: () => GenericRequest.get('fuente-financiamiento/all-active'),
        batchUpdate: (data) => GenericRequest.post('fuente-financiamiento/batch-update', data),
    }
}



export default ApiService