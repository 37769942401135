import { Collapse, Spinner } from 'react-bootstrap'
import SolicitudService from 'services/solicitud.service'
import { estados } from 'data/constSolicitud'
import { useEffect, useMemo, useState } from 'react'
import { formatDate } from 'utils/utils'
import { useSelector } from 'react-redux'
import Button from 'components/Button'
import { verifyAccess } from 'utils/rbac.util'

/** @module Pages/Solicitud/Components/ViewSolicitud */

const currentDate = formatDate(new Date(), 'Y-m-d')

/**
 * Componente, Información de la solicitud.
 * @param {object} props Propiedades del componente.
 * @param {object} props.id ID de solicitud.
 * @returns {JSX.Element} Retorna el componente ViewSolicitud.
 */
function ViewSolicitud({ id, handleAction }) {
    const [solicitud, setSolicitud] = useState(null)
    const { sectionKey } = useSelector((state) => state.system)
    const [show, setShow] = useState({ confirmAnular: false })
    const [loading, setLoading] = useState(true)
    const [failed, setFailed] = useState(false)
    const isPresupuestado = useMemo(
        () => ['presupuestos', 'daf'].includes(sectionKey) && solicitud?.presupuestado,
        [sectionKey, solicitud],
    )

    useEffect(() => {
        const loadSolicitud = async () => {
            const response = await SolicitudService.viewSolicitud(id)
            if (response.status === 200) {
                setSolicitud(response.data.solicitud)
            } else {
                setFailed(true)
            }
            setLoading(false)
        }
        id && loadSolicitud()
    }, [id])

    const getDestinos = () => {
        const groups = {}
        solicitud.beneficiarios.forEach((b) => {
            const _key = `${b.lugar_viaje}-_-${b.fecha_salida}-_-${b.fecha_llegada}`
            if (!groups[_key]) groups[_key] = []
            groups[_key].push(b)
        })
        const destinos = []
        for (const key in groups) {
            const destino = key.split('-_-')
            destinos.push({
                lugar_viaje: destino[0],
                fecha_salida: destino[1],
                fecha_llegada: destino[2],
                beneficiarios: groups[key],
            })
        }
        return destinos
    }

    if (loading) {
        return (
            <div
                className='d-flex justify-content-center align-items-center'
                style={{ width: '100%', height: '10rem' }}
            >
                <Spinner animation='border'>
                    <span className='visually-hidden'>Loading...</span>
                </Spinner>
            </div>
        )
    }
    if (failed) {
        return (
            <div className='dialog-message text-danger'>
                <div>
                    <div className='dialog-message-icon'>
                        <span className='bi-info-circle'></span>
                    </div>
                </div>
                <div>No se pudo cargar la Solicitud</div>
            </div>
        )
    }

    return (
        <div>
            <div className='row mb-3'>
                <div className='col-sm'>
                    <span className='fw-bold'>Fecha de registro: </span>
                    {formatDate(solicitud.fecha_creacion, 'd/m/Y H:i')}
                </div>
                <div className='col-sm-auto' style={{ minWidth: '14rem' }}>
                    <span className='fw-bold'>Estado: </span>
                    <span className={['D'].includes(solicitud.estado) ? 'text-danger' : undefined}>
                        {estados[solicitud.estado]}
                    </span>
                </div>
            </div>
            {solicitud.observaciones && ['D'].includes(solicitud.estado) && (
                <div className='mb-3'>
                    <span className='fw-bold'>Observaciones: </span>
                    {solicitud.observaciones.split(';').pop()}
                </div>
            )}
            <div className='text-center fs-3'>Solicitud N°{solicitud.numero_solicitud}</div>
            <div className='mb-2'>
                <span className='fw-bold'>Identificador:</span> {solicitud.identificador || '-'}
            </div>
            <div className='mb-2'>
                <span className='fw-bold'>Fuente de financiamiento:</span> {solicitud.fuente_financiamiento}
            </div>
            <div className='mb-2'>
                <div className='fw-bold mb-2'>Objetivo:</div>
                {solicitud.objetivo_viaje}
            </div>
            {solicitud.resolucion && (
                <div className='mb-2'>
                    <span className='fw-bold'>Resolución: </span>
                    {solicitud.resolucion}
                </div>
            )}
            {getDestinos().map((destino, index) => {
                const { lugar_viaje, fecha_salida, fecha_llegada, beneficiarios } = destino
                return (
                    <div className='mb-2 bs-border-title' key={index}>
                        <div className='fw-bold bs-title'>Destino</div>
                        <div className='mb-1'>
                            <span className='fw-bold'>Lugar de viaje: </span>
                            {lugar_viaje}
                        </div>
                        <div className='mb-1'>
                            <span className='fw-bold'>Fecha salida: </span>
                            {formatDate(fecha_salida, 'd/m/Y H:i')}
                        </div>
                        <div className='mb-1'>
                            <span className='fw-bold'>Fecha retorno: </span>
                            {formatDate(fecha_llegada, 'd/m/Y H:i')}
                        </div>
                        {beneficiarios.length > 0 && (
                            <div className='mb-2'>
                                <div className='fw-bold mb-2'>Beneficiarios:</div>
                                <table className='table-simple'>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Cargo</th>
                                            {!solicitud.restrict && (
                                                <>
                                                    <th>{solicitud.invitados ? 'Lugar Origen' : 'CI'}</th>
                                                    {isPresupuestado && <th>Actividad</th>}
                                                </>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {beneficiarios.map((beneficiario, __index) => (
                                            <tr key={__index}>
                                                <td>{beneficiario.nombre}</td>
                                                <td>{beneficiario.funcion || beneficiario.cargo}</td>
                                                {!solicitud.restrict && (
                                                    <>
                                                        <td>
                                                            {solicitud.invitados ? beneficiario.origen : beneficiario.documento}
                                                        </td>
                                                        {isPresupuestado && (
                                                            <td title={beneficiario.actividad}>
                                                                {beneficiario.codigo_actividad}
                                                            </td>
                                                        )}
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )
            })}
            <div className='mb-1'>
                <span className='fw-bold'>Certificación Presupuestaria?: </span>
                {solicitud.presupuestado ? 'Si' : 'No'}
            </div>
            {isPresupuestado ? (
                <div className='bs-border-title'>
                    <div className='fw-bold bs-title'>Certificación Presupuestaria</div>
                    <div>
                        <span className='fw-bold'>Fecha: </span>
                        {formatDate(solicitud.fecha_presupuestado, 'd/m/Y H:i', '')}
                    </div>
                    <div>
                        <span className='fw-bold'>Partida pasajes: </span>
                        {solicitud.partida_pasajes}
                    </div>
                    <div>
                        <span className='fw-bold'>Partida viáticos: </span>
                        {solicitud.partida_viaticos}
                    </div>
                </div>
            ) : null}
            {['beneficiario', 'solicitante'].includes(sectionKey) && solicitud.propietario && (
                <div className='d-flex align-items-center'>
                    <div className='fw-bold me-3'>Opciones:</div>
                    {(solicitud.estado === 'D' ||
                        (solicitud.estado === 'A' &&
                            currentDate <= `${formatDate(solicitud.fecha_creacion, 'Y-m-d')} 23:59:59`)) &&
                        verifyAccess('solicitudUpdate') && (
                            <Button
                                variant='primary'
                                startIcon='bi-pencil'
                                onClick={() => handleAction('update', { id })}
                                className='me-3'
                            >
                                Editar
                            </Button>
                        )}
                    {solicitud.estado === 'A' && !solicitud.presupuestado && verifyAccess('solicitudCancel') && (
                        <>
                            <Button
                                variant='danger'
                                startIcon='bi-x-lg'
                                onClick={() => setShow({ ...show, confirmAnular: true })}
                                className='me-2'
                            >
                                Anular
                            </Button>
                            <div style={{ width: '12rem' }}>
                                <Collapse in={show.confirmAnular} dimension='width'>
                                    <div className='text-nowrap'>
                                        ¿Esta seguro?
                                        <Button
                                            onClick={() => setShow({ ...show, confirmAnular: false })}
                                            variant='text-secondary'
                                        >
                                            No
                                        </Button>
                                        <Button variant='text-blue' onClick={() => handleAction('cancel', { id })}>
                                            Si
                                        </Button>
                                    </div>
                                </Collapse>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
export default ViewSolicitud
