import Avatar from 'components/Avatar'
import { ButtonIcon } from 'components/Button'
import List from 'components/List'
import { EditableSelectFormik, DropdownFormik } from 'components/formik/formikFormComponents'
import InputLayout from 'components/layouts/InputLayout'
import { FieldArray, Form, Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ApiService from 'services/api.service'
import SolicitudService from 'services/solicitud.service'

/** @module Pages/Solicitud/Components/FormPresupuesto */

// Opciones del número de partida de pasajes.
const partidaPasajes = ['22110', '22120', '22110 - 22120']
// Opciones del número de partida de viáticos.
const partidaViaticos = ['22210', '22220', '22210 - 22220']

/**
 * Componente, Formulario de registro de presupuesto.
 * @param {object} props Propiedades del componente.
 * @param {React.MutableRefObject<any>} props.formRef Referencia del formulario.
 * @param {Function} props.onSubmit Callback a ejecutar cuando se de submit del formulario.
 * @param {object} props.solicitud Solicitud seleccionada. 
 * @returns {JSX.Element} Retorna el componente FormPresupuesto.
 */
function FormPresupuesto({formRef, onSubmit, solicitud}) {
    const [actividades, setActividades] = useState([])
    const [showBeneficiarios, setShowBeneficiarios] = useState(true)
    // Valores iniciales del formulario.
    const initialValues = {
        partida_pasajes: solicitud?.partida_pasajes||'',
        partida_viaticos: solicitud?.partida_viaticos||'',
        actividad: '',
        beneficiarios: [],
    }

    useEffect(() => {
        const loadData = async () => {
            const {status, data} = await SolicitudService.getBeneficiarios(solicitud.id)
            if (status === 200) formRef?.current?.resetForm({
                values: {
                    ...initialValues, 
                    beneficiarios: data.beneficiarios.map(b => ({...b, actividad: b.actividad_id?{id:b.actividad_id, codigo_actividad: b.codigo_actividad}:''}))
                }
            })
        }
        if (solicitud?.id) loadData()
    }, [solicitud])

    const handleSubmit = (values) => {
        const {partida_pasajes, partida_viaticos, beneficiarios} = values
        const data = {
            partida_pasajes,
            partida_viaticos,
            solicitudBeneficiarios: beneficiarios.map(b => ({id: b.id, actividad_id: b.actividad?.id||''}))
        }
        onSubmit(data)
    }
    const validate = (values) => {
        const errors = {}
        if (!values.partida_pasajes && !values.partida_viaticos) {
            errors.partida_pasajes = 'Requerido'
            errors.partida_viaticos = 'Requerido'
        }
        const errorsBeneficiarios = validateBeneficiarios(values.beneficiarios)
        if (errorsBeneficiarios) errors.beneficiarios = errorsBeneficiarios
        return errors
    }
    const validateBeneficiarios = (beneficiarios) => {
        const errors = {}
        beneficiarios.forEach((b, index) => {
            const _errors = {}
            if (!b.actividad?.id) _errors.actividad = 'Requerido'
            if (Object.values(_errors).length > 0) errors[index] = _errors
        })
        return Object.values(errors).length > 0 ? errors : null
    }

    const handleSearch = (actividad) => {
        const loadData = async () => {
            const response = await ApiService.unidad.search(actividad)
            if (response.status === 200) {
                setActividades(response.data)
            }
        }
        if (actividad.length > 0) loadData()
        else setActividades([])
    }

    return (
        <Formik 
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
        >
            {() => (
                <Form>
                    <InputLayout label='Número de partida' className='mb-0'>
                        <div className='row g-3'>
                            <DropdownFormik options={partidaPasajes} name='partida_pasajes' label='Pasajes' containerClassName='col-6' />
                            <DropdownFormik options={partidaViaticos} name='partida_viaticos' label='Viáticos' containerClassName='col-6' />
                        </div>
                    </InputLayout>
                    <InputLayout label='Beneficiarios'>
                        <div 
                            className='d-flex align-items-center py-2 px-3'
                            style={{borderBottom: '3px solid #dee2e6'}}
                        >
                            <Avatar 
                                size='lg' 
                                shape='rounded' 
                                className='me-3'
                                iconProps={{className: 'bi-person', style: {fontSize: '1.5rem'}}} 
                            />
                            <div className='row align-items-center w-100'>
                                <div className='col-md-7 d-flex justify-content-between align-items-center'>
                                    Todos
                                    <ButtonIcon
                                        icon={showBeneficiarios?'bi-chevron-up':'bi-chevron-down'}
                                        variant='outline-secondary'
                                        rounded
                                        onClick={() => setShowBeneficiarios((show) => !show)}
                                    />
                                </div>
                                <EditableSelectFormik 
                                    name='actividad' 
                                    label='Actividad:'
                                    containerClassName='col-md-5' 
                                    valueAsOption inline lazy
                                    onSearch={handleSearch}
                                    options={actividades} 
                                    optionValue='codigo_actividad' 
                                    itemTemplate={(item) => <>{item.codigo_actividad + ' / ' + item.abreviacion_da + ' - ' + item.descripcion}</>}
                                />
                            </div>
                        </div>
                        <Collapse in={showBeneficiarios}>
                            <div>
                                <FieldArray name='beneficiarios'>
                                    {({form: {values}, ...arrayHelpers}) => (
                                        <Beneficiarios value={values.beneficiarios} {...arrayHelpers} />
                                    )}
                                </FieldArray>
                            </div>
                        </Collapse>
                    </InputLayout>
                    
                    <FormObserver/>
                </Form>
            )}
        </Formik>
    )
}

function Beneficiarios({name, value}) {

    const beneficiarioTemplate = (beneficiario, index) => (
        <RowBeneficiario key={index} name={name} value={beneficiario} index={index} />
    )
    return (
        <List 
            items={value} 
            itemTemplate={beneficiarioTemplate}
        />
    )
}

function RowBeneficiario({name, value, index}) {
    const [actividades, setActividades] = useState([])

    const handleSearch = (actividad) => {
        const loadData = async () => {
            const response = await ApiService.unidad.search(actividad)
            if (response.status === 200) {
                setActividades(response.data)
            }
        }
        if (actividad.length > 0) loadData()
        else setActividades([])
    }
 
    return (
        <div className='d-flex align-items-center'>
            <Avatar 
                size='lg' 
                shape='rounded' 
                className='me-3'
                iconProps={{className: 'bi-person', style: {fontSize: '1.5rem'}}} 
            />
            <div className='row align-items-center w-100'>
                <div className='col-md-7'>
                    <div>Nombre: {value.nombre}</div>
                    <div>Cargo: {value.funcion||value.cargo}</div>
                    <div>Pasaje Aéreo: Bs. {value.pasaje_aereo||'0.00'}</div>
                </div>
                <EditableSelectFormik 
                    containerClassName='col-md-5'
                    name={`${name}.${index}.actividad`} 
                    label='Actividad:' 
                    valueAsOption inline lazy
                    onSearch={handleSearch}
                    options={actividades} 
                    optionValue='codigo_actividad' 
                    itemTemplate={(item) => <>{item.codigo_actividad + ' / ' + item.abreviacion_da + ' - ' + item.descripcion}</>}
                />
            </div>
        </div>
    )
}

const FormObserver = () => {
    const { values, setValues } = useFormikContext()

    useEffect(() => {
        if (typeof values.actividad === 'object' && values.actividad.id) {
            setValues({...values, beneficiarios: values.beneficiarios.map(b => ({...b, actividad: values.actividad}))})
        }
    }, [values.actividad]) 

    return null
}

export default FormPresupuesto