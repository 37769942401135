import { useEffect, useState } from 'react'
import { classNames } from '../utils'
import ChevronDoubleLeft from '../icons/ChevronDoubleLeft'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'
import ChevronDoubleRight from '../icons/ChevronDoubleRight'
export function Pagination({
    currentPage = 1,
    totalItems = 0,
    pageSize = 10,
    pageSizeOptions,
    onPage,
    paginationDetail,
    responsive,
    className,
    style,
}) {
    const totalPages = pageSize > 0 ? Math.ceil(totalItems / pageSize) || 1 : 1
    const [pages, setPages] = useState([])
    useEffect(() => {
        getPaginationData()
    }, [totalItems, pageSize, currentPage])
    const handlePage = (page, size = pageSize) => {
        onPage && onPage(page, size)
    }
    const getPaginationData = () => {
        const data = []
        let ini = currentPage - 2 <= 1 ? 1 : currentPage - 2
        let end = currentPage + 2 >= totalPages ? totalPages : currentPage + 2
        if (end - ini < 4) {
            ini = currentPage > totalPages - 2 ? (totalPages - 4 > 1 ? totalPages - 4 : 1) : ini
            end = currentPage < 3 ? (totalPages < 5 ? totalPages : 5) : end
        }
        for (let i = ini; i <= end; i++) {
            data.push(i)
        }
        setPages(data)
    }
    const handleChange = ({ target }) => {
        const _pageSize = parseInt(target.value)
        const _totalPages = _pageSize > 0 ? Math.ceil(totalItems / _pageSize) || 1 : 1
        const _currentPage = currentPage <= _totalPages ? currentPage : _totalPages
        handlePage(_currentPage, _pageSize)
    }
    const pageSizeSelector = pageSizeOptions && (
        <div className='pps-options'>
            <span className='pps-label'>Items por página</span>
            <select className='pps-selector' value={pageSize} onChange={handleChange}>
                {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
    const getPaginationDetail = () => {
        let pDetailElement = ''
        if (paginationDetail) {
            const firstItem = (currentPage - 1) * pageSize + 1
            const _lastItem = currentPage * pageSize
            const lastItem = _lastItem > totalItems ? totalItems : _lastItem
            let pDetailContent = ''
            if (typeof paginationDetail === 'string') {
                const replacements = {
                    '{{firstItem}}': firstItem.toString(),
                    '{{lastItem}}': lastItem.toString(),
                    '{{totalItems}}': totalItems.toString(),
                    '{{totalPages}}': totalPages.toString(),
                    '{{pageSize}}': pageSize.toString(),
                    '{{currentPage}}': currentPage.toString(),
                }
                const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')
                pDetailContent = paginationDetail.replace(pattern, (matched) => replacements[matched])
            } else {
                pDetailContent = `${firstItem} - ${lastItem} de ${totalItems} items ⋅ Nro. páginas: ${totalPages}`
            }
            pDetailElement = <div className='p-detail'>{pDetailContent}</div>
        }
        return pDetailElement
    }
    return (
        <div
            className={classNames([
                'modular-pagination',
                responsive ? (typeof responsive === 'string' ? `responsive-${responsive}` : 'responsive') : undefined,
                className,
            ])}
            style={style}
        >
            {getPaginationDetail()}
            <div className='p-wrapper'>
                <div className='pp-options'>
                    <button
                        className='pp-button'
                        {...(currentPage > 1 ? { onClick: () => handlePage(1) } : { disabled: true })}
                    >
                        <ChevronDoubleLeft />
                    </button>
                    <button
                        className='pp-button'
                        {...(currentPage - 1 > 0 ? { onClick: () => handlePage(currentPage - 1) } : { disabled: true })}
                    >
                        <ChevronLeft />
                    </button>
                    {currentPage > 0 ? (
                        pages.map((page) => (
                            <button
                                key={page}
                                className={classNames(['pp-button', currentPage === page && 'active'])}
                                onClick={currentPage !== page ? () => handlePage(page) : undefined}
                            >
                                {page}
                            </button>
                        ))
                    ) : (
                        <button className='pp-button' disabled>
                            0
                        </button>
                    )}
                    <button
                        className='pp-button'
                        {...(currentPage + 1 <= totalPages
                            ? { onClick: () => handlePage(currentPage + 1) }
                            : { disabled: true })}
                    >
                        <ChevronRight />
                    </button>
                    <button
                        className='pp-button'
                        {...(currentPage < totalPages ? { onClick: () => handlePage(totalPages) } : { disabled: true })}
                    >
                        <ChevronDoubleRight />
                    </button>
                </div>
                {pageSizeSelector}
            </div>
        </div>
    )
}
